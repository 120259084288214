import React, { useState, useContext, useEffect } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "react-apollo";
import { Badge, Button, Icon, Input, notification, Spin } from "antd";
import { Titlebar } from "../../../components";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import GenerateBadge from "../../../helpers/generateBadge";
import PracticeViewWrapper from "./index.style";
import { AccountContext } from "../../../context/AccountContext";
import TitleBar from "../../../components/titlebar/titlebar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { encrypt } from "../../../utils/crypt";
import Qrcode from "./qrcode";

const PracticesView = (props) => {
  const [loading, setLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [companyEncode, setCompanyEncode] = useState(null);
  const [qrModal, setQrModal] = useState(false);

  const userContext = useContext(AccountContext);

  const { data, error } = useQuery(getPracticeQuery, {
    fetchPolicy: "network-only",
    variables: { id: Number(props.match.params.id) },
  });

  useEffect(() => {
    if (data && data.getPractice && !companyEncode) {
      let practice = null;
      if (data.getPractice.id == props.me.practiceid) practice = encrypt(`${props.me.portalslug}_${props.me.id}`);
      else practice = encrypt(`${props.me.portalslug}`) + "/" + encrypt(`${data.getPractice.practiceId}_${data.getPractice.ownerid}`);

      setCompanyEncode(practice);
    }
  }, [data, companyEncode, props.me]);

  const handleError = (errors) => {
    setLoading(false);
    errors.forEach((err) => {
      notification.error({
        message: "Error",
        description: err.message,
      });
    });
  };

  const qrCodeClose = () => {
    setQrModal(false);
  };

  const copyHandler = () => {
    notification.success({
      message: "Copied to clipboard",
    });
  };

  if (loading || !data) {
    return (
      <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper">
          <Titlebar currentPractice="" displayText="" />
        </div>
        <div className="loading-wrapper">
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper">
          <Titlebar currentPractice="" displayText="" />
        </div>
        <div className="loading-wrapper">
          <p>Whoopsie - practice didn&apos;t load.</p>
        </div>
      </div>
    );
  }

  const { getPractice } = data;
  const { account } = userContext;

  return (
    <PracticeViewWrapper>
      <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper">
          <div className="qr-header-wrapper">
            <h4 className="edit-iconbox">
              <TitleBar title={getPractice.name} subTitle={account && (account.accountType === 1 ? account.portalname : `${getPractice.name} | ${account.portalname}`)} />
              {userContext && userContext.account && userContext.account.permissions.includes("editpractice") && (
                <Button className="btnDelete" icon="edit" onClick={() => props.history.push(`/practices/edit/${props.currentPortalId || props.match.params.poid}/${props.match.params.id}`)} />
              )}
            </h4>
            <div className="copylink-block-wrapper">
              <div className="copylink-inputbox">
                <Input placeholder="Company Name" type="text" readOnly value={`${process.env.REACT_APP_URL}assessment/${companyEncode}`} />
                <CopyToClipboard text={`${process.env.REACT_APP_URL}assessment/${companyEncode}`} onCopy={() => console.log("cpu")}>
                  <Button className="" icon="copy" onClick={() => copyHandler()} />
                </CopyToClipboard>
              </div>
              <Button className="viewqr-btn" onClick={() => setQrModal(true)}>
                View QR
              </Button>
            </div>
          </div>
        </div>

        <LayoutContentWrapper>
          <LayoutContent>
            <div className="row-wrapper">
              <div className="row-label">Practice Owner/Admin</div>
              <div>{getPractice.ownername}</div>
            </div>
            <div className="row-wrapper">
              <div className="row-label">Email</div>
              <div>{getPractice.primaryContactEmail || null}</div>
            </div>
            <div className="row-wrapper">
              <div className="row-label">Address</div>
              <div>
                <div>{getPractice.primaryContactAddress || null}</div>
                <div>{getPractice.primaryContactCity && getPractice.primaryContactState && getPractice.primaryContactZipCode ? `${getPractice.primaryContactCity}, ${getPractice.primaryContactState} ${getPractice.primaryContactZipCode}` : null}</div>
              </div>
            </div>
            <div className="row-wrapper">
              <div className="row-label">Status</div>
              <div>
                <Badge status={GenerateBadge(getPractice.status)} text={getPractice.status} />
              </div>
            </div>
            <div className="row-wrapper">
              <div className="row-label">Receive Assessment Notification</div>
              <div>
                <Badge status={GenerateBadge(getPractice.assessmentNotification ? "ACTIVE" : "")} text={getPractice.assessmentNotification ? "Active" : "Inactive"} />
              </div>
            </div>
            <div className="row-wrapper">
              <div className="row-label">Last Login</div>
              <div>{getPractice.lastLogin}</div>
            </div>
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
      <Qrcode getPractice={getPractice} {...props} practiceEncoded={companyEncode} qrCodeClose={qrCodeClose} setQrModal={setQrModal} qrModal={qrModal} account={account} />
    </PracticeViewWrapper>
  );
};

const getPracticeQuery = gql`
  query($id: Int!) {
    getPractice(id: $id) {
      id
      name
      notes
      practiceId
      siteLogo
      siteLogoS3Token
      status
      email
      primaryContactAddress
      primaryContactZipCode
      primaryContactCity
      primaryContactCountry
      primaryContactState
      primaryContactSuite
      primaryContactEmail
      primaryContactFirstName
      primaryContactLastName
      assessmentNotification
      lastLogin
      ownername
      ownerid
      qrcode
      portalqrcode
      portallogo
    }
  }
`;

export default PracticesView;
