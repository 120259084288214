"use strict";
import React, { useEffect, useRef, useState } from "react";
import { Button, Row, Col, Badge, Form, Input } from "antd";
import { Modal } from "react-bootstrap";
import gql from "graphql-tag";
import { useMutation, useQuery } from "react-apollo";
import Editpen from "../../../assets/img/edit-iconv2.svg";
import Download from "../../../assets/img/download-conv2.svg";
import { QRCode } from "react-qrcode-logo";
import { SiteLogo } from "../../practices/components";
import { ChromePicker } from "react-color";
import CustomColorPicker from "../../../components/customColorPicker";
const FormItem = Form.Item;

const Qrcode = (props) => {
  const { currentPortalId, history, form, gblUserId, me, qrModal, getAdvisor, userEncoded, setQrModal, account } = props;
  const { getFieldProps, getFieldDecorator, getFieldValue, resetFields } = form;
  const [qrEditModal, setQrEditModel] = useState(false);
  const [isQrColor, setIsQrColor] = useState(false);
  const [isBorderColor, setIsBorderColor] = useState(false);

  const [state, setState] = useState({
    removeQrCodeBehindLogo: false,
    eyecolor_0_outer: "#000000",
    fgColor: "#000000",
    logoPadding: "0",
    enableCORS: false,
    ecLevel: "L",
    size: "320",
    logoWidth: 76,
    logoHeight: 76,
    siteImage: `${process.env.REACT_APP_MEDIA_URL}site-logos/unitifi-qr-default.png`,
  });
  const [borderColor, setBorderColor] = useState(null);
  const [qrColor, setQrColor] = useState(null);
  const [qrLogo, setQrLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  const qrColorRef = useRef(null);
  const borderColorRef = useRef(null);

  const [qrCodeUploadFile, { data: qrFileData, loading: loadingFileSubmit, error: errorFileSubmit }] = useMutation(gql`
    mutation uploadFile($file: Upload!, $folder: String!) {
      uploadFile(file: $file, folder: $folder) {
        ok
        response {
          s3Token
          url
        }
      }
    }
  `);

  const [qrCodeSave, { data: qrData, loading: loadingSubmit, error: errorSubmit }] = useMutation(gql`
    mutation($userId: Int, $qrcode: JSON) {
      qrCodeUser(userId: $userId, qrcode: $qrcode) {
        ok
        message
      }
    }
  `);

  useEffect(() => {
    if (!getAdvisor) return;
    if (!userEncoded) return;

    userHandler(userEncoded);
  }, [getAdvisor, userEncoded]);

  useEffect(() => {
    if (qrEditModal == false) return;
    let qrcode = state;

    if (qrcode) {
      form.setFields({
        eyecolor_0_outer: {
          value: qrcode.eyecolor_0_outer,
        },
      });
      setBorderColor(qrcode.eyecolor_0_outer);
      form.setFields({
        fgColor: {
          value: qrcode.fgColor,
        },
      });
      setQrColor(qrcode.fgColor);
    }
  }, [qrEditModal]);

  useEffect(() => {
    if (qrColor) {
      document.addEventListener("mousedown", handleClickOutsideQrColor);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideQrColor);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideQrColor);
    };
  }, [qrColor]);

  useEffect(() => {
    if (borderColor) {
      document.addEventListener("mousedown", handleClickOutsideBorderColor);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideBorderColor);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideBorderColor);
    };
  }, [borderColor]);

  const handleClickOutsideQrColor = (event) => {
    if (qrColorRef.current && !qrColorRef.current.contains(event.target)) {
      setIsQrColor(false);
    }
  };
  const handleClickOutsideBorderColor = (event) => {
    if (borderColorRef.current && !borderColorRef.current.contains(event.target)) {
      setIsBorderColor(false);
    }
  };

  const handleDownload = () => {
    ref.current.download("png", `Unitifi-qrcode`);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        if (values.eyecolor_0_outer.length < 7) values.eyecolor_0_outer = `#${values.eyecolor_0_outer}`;
        if (values.fgColor.length < 7) values.fgColor = `#${values.fgColor}`;
        let obj = {
          ...state,
          ...values,
        };
        // if (qrLogo) obj.logoImage = qrLogo.value;
        setLoading(true);
        let isFileUpload = false;

        if (qrLogo && qrLogo.file) {
          const fileRes = await qrCodeUploadFile({ variables: { file: qrLogo.file, folder: qrLogo.folder } });
          if (fileRes.data.uploadFile && fileRes.data.uploadFile.response && fileRes.data.uploadFile.response.url) {
            obj.siteImage = fileRes.data.uploadFile.response.url;
            isFileUpload = true;
          }
        } else {
          if (typeof qrLogo == "undefined") {
            obj.siteImage = null;
            obj.logoImage = null;
          }
        }
        let logoimg = obj.logoImage;
        delete obj.logoImage;
        const result = await qrCodeSave({ variables: { userId: parseInt(getAdvisor.id), qrcode: obj } });
        if (isFileUpload) {
          obj.logoImage = qrLogo.value;
        } else obj.logoImage = logoimg;

        setState(obj);
        setQrEditModel(false);
      }
      setLoading(false);
    });
  };

  const handleUploadParams = (uploadParams) => {
    const { file, folder } = uploadParams;
    const target = {};
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        target.name = "logoImage";
        target.value = reader.result;
        target.logoName = file.name;
        target.file = file;
        target.folder = folder;
        setQrLogo(target);
        // handleChange({ target });
      };
    } else setQrLogo(undefined);
  };

  const userHandler = async (company) => {
    if (getAdvisor) {
      let qrcode = getAdvisor.qrcode;
      let obj = {
        ...state,
        value: process.env.REACT_APP_URL + "assessment/" + company,
      };
      if (qrcode) {
        if (qrcode.siteImage) {
          const img = await convertImageToBase64(qrcode.siteImage);
          obj = { ...qrcode, logoImage: img ? img : qrcode.siteImage };
        } else obj = { ...qrcode };
      } else {
        let practice = getAdvisor.practices;
        if (practice.length > 0) practice = practice[0];
        const img = await convertImageToBase64(practice && practice.siteLogo ? practice.siteLogo : state.siteImage);
        obj = { ...obj, logoImage: img ? img : state.siteImage, siteImage: practice && practice.siteLogo ? practice.siteLogo : state.siteImage };
      }

      setState(obj);
      if (qrcode) {
        form.setFields({
          eyecolor_0_outer: {
            value: qrcode.eyecolor_0_outer,
          },
        });
        setBorderColor(qrcode.eyecolor_0_outer);
        form.setFields({
          fgColor: {
            value: qrcode.fgColor,
          },
        });
        setQrColor(qrcode.fgColor);
      }
    }
  };

  const convertImageToBase64 = async (url) => {
    try {
      const response = await fetch(url, { mode: "cors" });
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error fetching the image:", error);
      return null;
    }
  };

  const boroderColorHandler = (color) => {
    if (color && color.target) {
      setBorderColor("#" + color.target.value.toUpperCase());
      form.setFields({
        eyecolor_0_outer: {
          value: color.target.value.toUpperCase(),
        },
      });
    } else {
      setBorderColor(color.hex.toUpperCase());
      form.setFields({
        eyecolor_0_outer: {
          value: color.hex.toUpperCase().toUpperCase(),
        },
      });
    }
  };

  const fgColorHandler = (color) => {
    if (color && color.target) {
      setQrColor("#" + color.target.value.toUpperCase());
      form.setFields({
        fgColor: {
          value: color.target.value.toUpperCase(),
        },
      });
    } else {
      setQrColor(color.hex.toUpperCase());
      form.setFields({
        fgColor: {
          value: color.hex.toUpperCase(),
        },
      });
    }
  };

  const validateSixDigitHexColor = (_, value, callback) => {
    if (value) {
      const sixDigitHexColorRegex = /^([0-9A-Fa-f]{6})$/;
      if (value && !sixDigitHexColorRegex.test(value.replace("#", ""))) {
        callback("Please enter a valid six-digit hex color.");
      } else callback();
    } else callback();
  };

  return (
    <div>
      <Modal
        show={qrModal}
        onHide={() => {
          qrEditModal ? setQrEditModel(false) : setQrModal(false);
        }}
        centered
        className="unitifi-modal-wrapper unitifi-qrcode-default-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            <h4>{qrEditModal ? `Edit QR Code` : `View QR Code`}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {qrEditModal ? (
            <Form layout="vertical" onSubmit={handleSubmit}>
              <FormItem label="QR code color">
                <div className="input-color-box-wrapper">
                  <div className="input-color-code-display-wrapper">
                    {getFieldDecorator("fgColor", {
                      rules: [
                        {
                          message: "QR code color is required",
                          required: true,
                        },
                        { validator: validateSixDigitHexColor },
                      ],
                    })(
                      <div>
                        <Input type={"text"} placeholder="006FBA" value={qrColor && qrColor.replace("#", "")} onChange={fgColorHandler} />
                        <p>#</p>
                      </div>
                    )}
                  </div>
                  <div className="color-picker-input" ref={qrColorRef}>
                    <div className="colorinput" onClick={() => setIsQrColor(!isQrColor)}>
                      <div className="colorbox" style={{ background: qrColor }}></div>
                    </div>
                    {isQrColor && <CustomColorPicker hex={qrColor} onChange={fgColorHandler} />}
                  </div>
                </div>
              </FormItem>
              <FormItem label="Border color">
                <div className="input-color-box-wrapper">
                  <div className="input-color-code-display-wrapper">
                    {getFieldDecorator("eyecolor_0_outer", {
                      rules: [
                        {
                          message: "Border color is required",
                          required: true,
                        },
                        { validator: validateSixDigitHexColor },
                      ],
                    })(
                      <div>
                        <Input type={"text"} placeholder="006FBA" name="eyecolor_0_outer" value={borderColor && borderColor.replace("#", "")} onChange={boroderColorHandler} />
                        <p>#</p>
                      </div>
                    )}
                  </div>
                  <div className="color-picker-input" ref={borderColorRef}>
                    <div className="colorinput" onClick={() => setIsBorderColor(!isBorderColor)}>
                      <div className="colorbox" style={{ background: borderColor }}></div>
                    </div>
                    {isBorderColor && <CustomColorPicker hex={borderColor} onChange={boroderColorHandler} />}
                  </div>
                </div>
              </FormItem>
              <div className="upload-logo-wrapper">
                <SiteLogo handleUploadParams={handleUploadParams} practice={state && state.siteImage ? { siteLogo: state.siteImage } : null} caption="Optimal logo size is 216px by 64px." />
              </div>
              <div className="modal-footer-bnts-wrapper">
                <Button type="" size="large" htmlType="button" className="unitifi-login-btn" onClick={() => setQrEditModel(false)}>
                  Cancel
                </Button>
                <Button type="primary" size="large" htmlType="submit" className="unitifi-login-btn" loading={loading} disabled={loading}>
                  Save
                </Button>
              </div>
            </Form>
          ) : (
            <>
              <div className="qr-codebox">
                <QRCode
                  ref={ref}
                  crossOrigin="anonymous"
                  {...{
                    ...state,
                    eyeColor: [
                      {
                        outer: state && state.eyecolor_0_outer ? state.eyecolor_0_outer : state && state.fgColor ? state.fgColor : "#000000",
                        inner: state && state.eyecolor_0_inner !== undefined && state.eyecolor_0_inner !== null ? state.eyecolor_0_inner : state && state.fgColor !== undefined && state.fgColor !== null ? state.fgColor : "#000000",
                      },
                      {
                        outer: state && state.eyecolor_0_outer ? state.eyecolor_0_outer : state && state.fgColor ? state.fgColor : "#000000",
                        inner: state && state.eyecolor_0_inner !== undefined && state.eyecolor_0_inner !== null ? state.eyecolor_0_inner : state && state.fgColor !== undefined && state.fgColor !== null ? state.fgColor : "#000000",
                      },
                      {
                        outer: state && state.eyecolor_0_outer ? state.eyecolor_0_outer : state && state.fgColor ? state.fgColor : "#000000",
                        inner: state && state.eyecolor_0_inner !== undefined && state.eyecolor_0_inner !== null ? state.eyecolor_0_inner : state && state.fgColor !== undefined && state.fgColor !== null ? state.fgColor : "#000000",
                      },
                    ],
                  }}
                />
              </div>
              <div className="edit-download-footer-div">
                {account && (account.accountType == 1 || account.rolecode == "CMPYM") && (
                  <Button className="" type="button" onClick={() => setQrEditModel(true)}>
                    <i>
                      <img src={Editpen} />
                    </i>
                    Edit QR Code
                  </Button>
                )}
                <Button className="download-btn" onClick={() => handleDownload()}>
                  <i>
                    <img src={Download} />
                  </i>
                  Download QR Code
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={qrEditModal}
        onHide={() => {
          setQrEditModel(false);
        }}
        centered
        className="unitifi-modal-wrapper unitifi-qrcode-default-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            <h4>Edit QR Code</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form layout="vertical" onSubmit={handleSubmit}>
            <FormItem label="QR code color">
              {getFieldDecorator("fgColor", {
                rules: [
                  {
                    message: "QR code color is required",
                    required: true,
                  },
                ],
              })(
                <div className="input-color-box-wrapper">
                  <Input type={"text"} placeholder="#006FBA" value={qrColor} readOnly />
                  <div className="color-picker-input">
                    <Input type="color" placeholder="#006FBA" onChange={(e) => setQrColor(e.target.value)} />
                  </div>
                </div>
              )}
            </FormItem>
            <FormItem label="Border color">
              {getFieldDecorator("eyecolor_0_outer", {
                rules: [
                  {
                    message: "QR code color is required",
                    required: true,
                  },
                ],
              })(
                <div className="input-color-box-wrapper">
                  <Input type={"text"} placeholder="#006FBA" name="eyecolor_0_outer" value={borderColor} readOnly />
                  <div className="color-picker-input">
                    <Input type="color" placeholder="#006FBA" onChange={(e) => boroderColorHandler(e)} />
                  </div>
                </div>
              )}
            </FormItem>
            <div className="upload-logo-wrapper">
              <SiteLogo handleUploadParams={handleUploadParams} practice={state && state.siteImage ? { siteLogo: state.siteImage } : null} caption="Logo must be 216px wide by 64px height" />
            </div>
            <div className="modal-footer-bnts-wrapper">
              <Button type="primary" size="large" htmlType="submit" className="unitifi-login-btn" loading={loading} disabled={loading}>
                Save
              </Button>
              <Button type="" size="large" htmlType="button" className="unitifi-login-btn" onClick={() => setQrEditModel(false)}>
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default Form.create()(Qrcode);
