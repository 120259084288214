const crypto = require("crypto");

const algorithm = "aes-256-ctr";
const password = process.env.cryptokey || "secretPassword";

// console.log(password);

function encrypt(text) {
  const cipher = crypto.createCipher(algorithm, password);
  let crypted = cipher.update(text, "utf8", "hex");
  crypted += cipher.final("hex");
  return crypted;
}

function decrypt(text) {
  try {
    const decipher = crypto.createDecipher(algorithm, password);
    let dec = decipher.update(text, "hex", "utf8");
    dec += decipher.final("utf8");
    return dec;
  } catch (e) {
    let str = "";
    return str;
  }
}

exports.encrypt = encrypt;
exports.decrypt = decrypt;
