import { Icon, Result, Tooltip, message, notification } from "antd";
import gql from "graphql-tag";
import { Button, Row, Col, Badge, Form, Input } from "antd";
import { Modal } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { Titlebar } from "../../components";
import { EditButtons, FormWrapper } from "../../components/form";
import { useMutation, useQuery } from "react-apollo";
import LayoutContentWrapper from "../../layouts/app/layoutWrapper.style";
import LayoutContent from "../../layouts/app/layoutContent";
import Editpen from "../../assets/img/edit-iconv2.svg";
import Download from "../../assets/img/download-conv2.svg";
import logoicon from "../../assets/img/Unitifi-Primary-Color.png";
import _, { flowRight as compose } from "lodash";
import UserViewWrapper from "../users/view/index.style";
import Info from "../../assets/img/info-icon.svg";
import GenerateBadge from "../../helpers/generateBadge";
import moment from "moment";
import { QRCode } from "react-qrcode-logo";
import { SiteLogo } from "../practices/components";
import { encrypt } from "../../utils/crypt";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ChromePicker } from "react-color";
import CustomColorPicker from "../../components/customColorPicker";

const FormItem = Form.Item;
const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />;

const AccountForm = (props) => {
  const { portalData, me } = props;

  return (
    <FormWrapper className="unitifi-my-account-layout-wrapper">
      <UserViewWrapper>
        <div className="row-wrapper">
          <div className="row-label">Admin Name</div>
          <div className="text-grey">
            {portalData &&
              portalData.getPortal.user &&
              (portalData.getPortal.user ? portalData.getPortal.user.firstName + " " + portalData.getPortal.user.lastName : portalData.getPortal.primaryContactFirstName + " " + portalData.getPortal.primaryContactLastName)}
            {/* {portalData &&
              (portalData.getPortal.primaryContactFirstName ? portalData.getPortal.primaryContactFirstName + " " + portalData.getPortal.primaryContactLastName : portalData.getPortal.user.firstName + " " + portalData.getPortal.user.lastName)} */}
          </div>
        </div>

        <div className="row-wrapper">
          <div className="row-label">Email</div>
          <div className="text-grey">{portalData && portalData.getPortal.user && (portalData.getPortal.user ? portalData.getPortal.user.email : portalData.getPortal.primaryContactEmail)}</div>
        </div>
        <div className="row-wrapper">
          <div className="row-label">Address</div>
          <div className="text-grey">
            {portalData && portalData.getPortal.primaryContactAddress ? `${portalData.getPortal.primaryContactSuite}, ` : ""}
            {portalData && portalData.getPortal.primaryContactSuite ? `${portalData.getPortal.primaryContactSuite}, ` : ""}
            {portalData && portalData.getPortal.primaryContactCity ? `${portalData.getPortal.primaryContactCity}, ` : ""}
            {portalData && portalData.getPortal.primaryContactState ? `${portalData.getPortal.primaryContactState}, ` : ""}
            {portalData && portalData.getPortal.primaryContactZipCode ? `${portalData.getPortal.primaryContactZipCode}, ` : ""}
            {portalData && portalData.getPortal.primaryContactCountry ? `${portalData.getPortal.primaryContactCountry}` : ""}
          </div>
        </div>
        <div className="row-wrapper">
          <div className="row-label">Status</div>
          <div className="text-grey"> {portalData && <Badge status={GenerateBadge(portalData.getPortal.status)} text={portalData.getPortal.status} />}</div>
        </div>
        <div className="row-wrapper">
          <div className="row-label">Receive Assessment Notifications</div>
          <div className="text-grey">{portalData && <Badge status={GenerateBadge(portalData.getPortal.assessmentNotification == true ? "ACTIVE" : "error")} text={portalData.getPortal.assessmentNotification == true ? "Active" : "Inactive"} />}</div>
        </div>
        <div className="row-wrapper">
          <div className="row-label">Last Login</div>
          <div className="text-grey">{portalData && portalData.getPortal.user && portalData.getPortal.user.lastLogin ? moment(portalData.getPortal.user.lastLogin).format("MMM DD YYYY HH:mm A") : ""}</div>
        </div>
      </UserViewWrapper>
    </FormWrapper>
  );
};

const CompanyDetail = (props) => {
  const { currentPortalId, history, form, gblUserId, me } = props;
  const { getFieldProps, getFieldDecorator, getFieldValue, resetFields } = form;
  const [qrModal, setQrModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrEditModal, setQrEditModel] = useState(false);
  const [state, setState] = useState({
    removeQrCodeBehindLogo: false,
    eyecolor_0_outer: "#000000",
    fgColor: "#000000",
    logoPadding: "0",
    enableCORS: false,
    ecLevel: "L",
    size: "320",
    logoWidth: 76,
    logoHeight: 76,
    siteImage: `${process.env.REACT_APP_MEDIA_URL}site-logos/unitifi-qr-default.png`,
  });
  const [borderColor, setBorderColor] = useState(null);
  const [qrColor, setQrColor] = useState(null);
  const [qrLogo, setQrLogo] = useState(null);
  const [companyEncode, setCompanyEncode] = useState(null);
  const [isQrColor, setIsQrColor] = useState(false);
  const [isBorderColor, setIsBorderColor] = useState(false);

  const ref = useRef(null);
  const qrColorRef = useRef(null);
  const borderColorRef = useRef(null);

  const { loading: countryLoading, error: countryError, data: portalData } = useQuery(
    gql`
      query($id: Int) {
        getPortal(id: $id) {
          id
          name
          email
          slug
          description
          status
          siteLogo
          address
          siteLogoS3Token
          primaryContactAddress
          primaryContactZipCode
          primaryContactCity
          primaryContactCountry
          primaryContactState
          primaryContactSuite
          primaryContactEmail
          primaryContactFirstName
          primaryContactLastName
          total_clients
          total_practice
          team_size
          assessmentNotification
          qrcode
          user {
            id
            firstName
            lastName
            email
            lastLogin
          }
        }
      }
    `,
    { fetchPolicy: "network-only", variables: { id: currentPortalId } }
  );
  const [qrCodeUploadFile, { data: qrFileData, loading: loadingFileSubmit, error: errorFileSubmit }] = useMutation(gql`
    mutation uploadFile($file: Upload!, $folder: String!) {
      uploadFile(file: $file, folder: $folder) {
        ok
        response {
          s3Token
          url
        }
      }
    }
  `);

  const [qrCodeSave, { data: qrData, loading: loadingSubmit, error: errorSubmit }] = useMutation(gql`
    mutation($portalId: Int, $userId: Int, $qrcode: JSON) {
      qrCodeCompany(portalId: $portalId, userId: $userId, qrcode: $qrcode) {
        ok

        message
      }
    }
  `);

  useEffect(() => {
    if (!portalData) return;
    if (!me) return;
    let company = encrypt(`${portalData.getPortal.slug}_${me.id}`);
    setCompanyEncode(company);
    companyHandler(company);
  }, [portalData, me]);

  useEffect(() => {
    if (qrEditModal == false) return;
    if (Object.keys(state).length > 4) {
      form.setFields({
        eyecolor_0_outer: {
          value: state.eyecolor_0_outer,
        },
      });
      setBorderColor(state.eyecolor_0_outer);
      form.setFields({
        fgColor: {
          value: state.fgColor,
        },
      });
      setQrColor(state.fgColor);
    }
  }, [qrEditModal, state]);

  useEffect(() => {
    if (qrEditModal == false) return;
    let qrcode = state;

    if (qrcode) {
      form.setFields({
        eyecolor_0_outer: {
          value: qrcode.eyecolor_0_outer,
        },
      });
      setBorderColor(qrcode.eyecolor_0_outer);
      form.setFields({
        fgColor: {
          value: qrcode.fgColor,
        },
      });
      setQrColor(qrcode.fgColor);
    }
  }, [qrEditModal]);

  useEffect(() => {
    if (qrColor) {
      document.addEventListener("mousedown", handleClickOutsideQrColor);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideQrColor);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideQrColor);
    };
  }, [qrColor]);

  useEffect(() => {
    if (borderColor) {
      document.addEventListener("mousedown", handleClickOutsideBorderColor);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideBorderColor);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideBorderColor);
    };
  }, [borderColor]);

  const handleClickOutsideQrColor = (event) => {
    if (qrColorRef.current && !qrColorRef.current.contains(event.target)) {
      setIsQrColor(false);
    }
  };
  const handleClickOutsideBorderColor = (event) => {
    if (borderColorRef.current && !borderColorRef.current.contains(event.target)) {
      setIsBorderColor(false);
    }
  };

  const handleDownload = () => {
    ref.current.download("png", `Unitifi-qrcode`);
  };

  const companyHandler = async (company) => {
    if (portalData.getPortal) {
      let qrcode = portalData.getPortal.qrcode;
      let obj = {
        ...state,
        value: process.env.REACT_APP_URL + "assessment/" + company,
      };
      if (qrcode) {
        if (qrcode.siteImage) {
          const img = await convertImageToBase64(qrcode.siteImage);
          obj = { ...qrcode, logoImage: img ? img : qrcode.siteImage };
        } else obj = { ...qrcode };
      } else {
        let imag = portalData.getPortal.siteLogo;
        const img = await convertImageToBase64(imag ? imag : state.siteImage);
        obj = { ...obj, logoImage: img ? img : state.siteImage, siteImage: imag ? imag : state.siteImage };
      }
      setState(obj);
      if (qrcode) {
        form.setFields({
          eyecolor_0_outer: {
            value: qrcode.eyecolor_0_outer,
          },
        });
        setBorderColor(qrcode.eyecolor_0_outer);
        form.setFields({
          fgColor: {
            value: qrcode.fgColor,
          },
        });
        setQrColor(qrcode.fgColor);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        if (values.eyecolor_0_outer.length < 7) values.eyecolor_0_outer = `#${values.eyecolor_0_outer}`;
        if (values.fgColor.length < 7) values.fgColor = `#${values.fgColor}`;
        let obj = {
          ...state,
          ...values,
        };
        // if (qrLogo) obj.logoImage = qrLogo.value;
        setLoading(true);
        let isFileUpload = false;
        if (qrLogo && qrLogo.file) {
          const fileRes = await qrCodeUploadFile({ variables: { file: qrLogo.file, folder: qrLogo.folder } });
          if (fileRes.data.uploadFile && fileRes.data.uploadFile.response && fileRes.data.uploadFile.response.url) {
            obj.siteImage = fileRes.data.uploadFile.response.url;
            isFileUpload = true;
          }
        } else {
          if (typeof qrLogo == "undefined") {
            obj.siteImage = null;
            obj.logoImage = null;
          }
        }
        let logoimg = obj.logoImage;
        delete obj.logoImage;
        await qrCodeSave({ variables: { portalId: parseInt(currentPortalId), userId: gblUserId, qrcode: obj } });
        if (isFileUpload) {
          obj.logoImage = qrLogo.value;
        } else obj.logoImage = logoimg;
        setState(obj);
        setQrEditModel(false);
      }
      setLoading(false);
    });
  };

  const handleUploadParams = (uploadParams) => {
    const { file, folder } = uploadParams;
    const target = {};
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        target.name = "logoImage";
        target.value = reader.result;
        target.logoName = file.name;
        target.file = file;
        target.folder = folder;
        setQrLogo(target);
      };
    } else setQrLogo(undefined);
  };

  const convertImageToBase64 = async (url) => {
    try {
      const response = await fetch(url, { mode: "cors" });
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error fetching the image:", error);
      return null;
    }
  };

  const copyHandler = () => {
    notification.success({
      message: "Copied to clipboard",
    });
  };

  const boroderColorHandler = (color) => {
    if (color && color.target) {
      setBorderColor("#" + color.target.value.toUpperCase());
      form.setFields({
        eyecolor_0_outer: {
          value: color.target.value.toUpperCase(),
        },
      });
    } else {
      setBorderColor(color.hex.toUpperCase());
      form.setFields({
        eyecolor_0_outer: {
          value: color.hex.toUpperCase().toUpperCase(),
        },
      });
    }
  };

  const fgColorHandler = (color) => {
    if (color && color.target) {
      setQrColor("#" + color.target.value.toUpperCase());
      form.setFields({
        fgColor: {
          value: color.target.value.toUpperCase(),
        },
      });
    } else {
      setQrColor(color.hex.toUpperCase());
      form.setFields({
        fgColor: {
          value: color.hex.toUpperCase(),
        },
      });
    }
  };

  const validateSixDigitHexColor = (_, value, callback) => {
    if (value) {
      const sixDigitHexColorRegex = /^([0-9A-Fa-f]{6})$/;
      if (value && !sixDigitHexColorRegex.test(value.replace("#", ""))) {
        callback("Please enter a valid six-digit hex color.");
      } else callback();
    } else callback();
  };

  return (
    <div className="unirifi-costomer-layout-wrapper">
      <div className="unitifi-subheader-bar-wrapper">
        <div className="qr-header-wrapper">
          <h4>
            Company<Button className="btnDelete" icon="edit" onClick={() => history.push("/company/edit")}></Button>
          </h4>
          <div className="copylink-block-wrapper">
            <div className="copylink-inputbox">
              <Input placeholder="Company Name" type="text" readOnly value={`${process.env.REACT_APP_URL}assessment/${companyEncode}`} />
              <CopyToClipboard text={`${process.env.REACT_APP_URL}assessment/${companyEncode}`} onCopy={() => console.log("cpu")}>
                <Button className="" icon="copy" onClick={() => copyHandler()}></Button>
              </CopyToClipboard>
            </div>
            <Button className="viewqr-btn" onClick={() => setQrModal(true)}>
              View QR
            </Button>
          </div>
        </div>
      </div>
      <div className="unitifi-customer-content-wrapper">
        <Row className="unitifi-customer-info-box-wrapper mb-3 mb-lg-0">
          <Col className="gutter-row unitifi-customer-info-col" md={8}>
            <div className="unitifi-customer-info-block">
              <h6>Practices</h6>
              <p className="mb-0">{portalData && portalData.getPortal.total_practice}</p>
            </div>
          </Col>
          <Col className="gutter-row unitifi-customer-info-col" md={8}>
            <div className="unitifi-customer-info-block">
              <h6>Team Size</h6>
              <p className="mb-0">{portalData && portalData.getPortal.team_size}</p>
            </div>
          </Col>
          <Col className="gutter-row unitifi-customer-info-col" md={8}>
            <div className="unitifi-customer-info-block">
              <h6>Clients</h6>
              <p className="mb-0">{portalData && portalData.getPortal.total_clients}</p>
            </div>
          </Col>
        </Row>

        {/*<div className="d-flex flex-column ms-2">
          <Button className="btnDelete" icon="edit" onClick={() => history.push("/company/edit")}>
            Edit Account
          </Button>
        </div>*/}
      </div>

      <LayoutContentWrapper>
        <LayoutContent>
          <AccountForm {...props} portalData={portalData} />
        </LayoutContent>
      </LayoutContentWrapper>

      <Modal
        show={qrModal}
        onHide={() => {
          qrEditModal ? setQrEditModel(false) : setQrModal(false);
        }}
        centered
        className="unitifi-modal-wrapper unitifi-qrcode-default-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            <h4>{qrEditModal ? `Edit QR Code` : `View QR Code`}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {qrEditModal ? (
            <Form layout="vertical" onSubmit={handleSubmit}>
              <FormItem label="QR code color">
                <div className="input-color-box-wrapper">
                  <div className="input-color-code-display-wrapper">
                    {getFieldDecorator("fgColor", {
                      rules: [
                        {
                          message: "QR code color is required",
                          required: true,
                        },
                        { validator: validateSixDigitHexColor },
                      ],
                    })(
                      <div>
                        <Input type={"text"} placeholder="006FBA" value={qrColor && qrColor.replace("#", "")} onChange={fgColorHandler} />
                        <p>#</p>
                      </div>
                    )}
                  </div>
                  <div className="color-picker-input" ref={qrColorRef}>
                    <div className="colorinput" onClick={() => setIsQrColor(!isQrColor)}>
                      <div className="colorbox" style={{ background: qrColor }}></div>
                    </div>
                    {isQrColor && <CustomColorPicker hex={qrColor} onChange={fgColorHandler} />}
                  </div>
                </div>
              </FormItem>
              <FormItem label="Border color">
                <div className="input-color-box-wrapper">
                  <div className="input-color-code-display-wrapper">
                    {getFieldDecorator("eyecolor_0_outer", {
                      rules: [
                        {
                          message: "Border color is required",
                          required: true,
                        },
                        { validator: validateSixDigitHexColor },
                      ],
                    })(
                      <div>
                        <Input type={"text"} placeholder="006FBA" name="eyecolor_0_outer" value={borderColor && borderColor.replace("#", "")} onChange={boroderColorHandler} />
                        <p>#</p>
                      </div>
                    )}
                  </div>
                  <div className="color-picker-input" ref={borderColorRef}>
                    <div className="colorinput" onClick={() => setIsBorderColor(!isBorderColor)}>
                      <div className="colorbox" style={{ background: borderColor }}></div>
                    </div>
                    {isBorderColor && <CustomColorPicker hex={borderColor} onChange={boroderColorHandler} />}
                  </div>
                </div>
              </FormItem>
              <div className="upload-logo-wrapper">
                <SiteLogo handleUploadParams={handleUploadParams} practice={state && state.siteImage ? { siteLogo: state.siteImage } : null} caption="Optimal logo size is 216px by 64px." />
              </div>
              <div className="modal-footer-bnts-wrapper">
                <Button type="" size="large" htmlType="button" className="unitifi-login-btn" onClick={() => setQrEditModel(false)}>
                  Cancel
                </Button>
                <Button type="primary" size="large" htmlType="submit" className="unitifi-login-btn" loading={loading} disabled={loading}>
                  Save
                </Button>
              </div>
            </Form>
          ) : (
            <>
              <div className="qr-codebox">
                <QRCode
                  ref={ref}
                  {...{
                    ...state,
                    eyeColor: [
                      {
                        outer: state && state.eyecolor_0_outer ? state.eyecolor_0_outer : state && state.fgColor ? state.fgColor : "#000000",
                        inner: state && state.eyecolor_0_inner !== undefined && state.eyecolor_0_inner !== null ? state.eyecolor_0_inner : state && state.fgColor !== undefined && state.fgColor !== null ? state.fgColor : "#000000",
                      },
                      {
                        outer: state && state.eyecolor_0_outer ? state.eyecolor_0_outer : state && state.fgColor ? state.fgColor : "#000000",
                        inner: state && state.eyecolor_0_inner !== undefined && state.eyecolor_0_inner !== null ? state.eyecolor_0_inner : state && state.fgColor !== undefined && state.fgColor !== null ? state.fgColor : "#000000",
                      },
                      {
                        outer: state && state.eyecolor_0_outer ? state.eyecolor_0_outer : state && state.fgColor ? state.fgColor : "#000000",
                        inner: state && state.eyecolor_0_inner !== undefined && state.eyecolor_0_inner !== null ? state.eyecolor_0_inner : state && state.fgColor !== undefined && state.fgColor !== null ? state.fgColor : "#000000",
                      },
                    ],
                  }}
                />
              </div>
              <div className="edit-download-footer-div">
                <Button className="" type="button" onClick={() => setQrEditModel(true)}>
                  <i>
                    <img src={Editpen} />
                  </i>
                  Edit QR Code
                </Button>
                <Button className="download-btn" onClick={() => handleDownload()}>
                  <i>
                    <img src={Download} />
                  </i>
                  Download QR Code
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={qrEditModal}
        onHide={() => {
          setQrEditModel(false);
        }}
        centered
        className="unitifi-modal-wrapper unitifi-qrcode-default-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            <h4>Edit QR Code</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form layout="vertical" onSubmit={handleSubmit}>
            <FormItem label="QR code color">
              {getFieldDecorator("fgColor", {
                rules: [
                  {
                    message: "QR code color is required",
                    required: true,
                  },
                ],
              })(
                <div className="input-color-box-wrapper">
                  <Input type={"text"} placeholder="#006FBA" value={qrColor} readOnly />
                  <div className="color-picker-input">
                    <Input type="color" placeholder="#006FBA" onChange={(e) => setQrColor(e.target.value)} />
                  </div>
                </div>
              )}
            </FormItem>
            <FormItem label="Border color">
              {getFieldDecorator("eyecolor_0_outer", {
                rules: [
                  {
                    message: "QR code color is required",
                    required: true,
                  },
                ],
              })(
                <div className="input-color-box-wrapper">
                  <Input type={"text"} placeholder="#006FBA" name="eyecolor_0_outer" value={borderColor} readOnly />
                  <div className="color-picker-input">
                    <Input type="color" placeholder="#006FBA" onChange={(e) => boroderColorHandler(e)} />
                  </div>
                </div>
              )}
            </FormItem>
            <div className="upload-logo-wrapper">
              <SiteLogo handleUploadParams={handleUploadParams} practice={state && state.siteImage ? { siteLogo: state.siteImage } : null} caption="Logo must be 216px wide by 64px height" />
            </div>
            <div className="modal-footer-bnts-wrapper">
              <Button type="primary" size="large" htmlType="submit" className="unitifi-login-btn" loading={loading} disabled={loading}>
                Save
              </Button>
              <Button type="" size="large" htmlType="button" className="unitifi-login-btn" onClick={() => setQrEditModel(false)}>
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal> */}
    </div>
  );
  // return
};

export default Form.create()(CompanyDetail);
