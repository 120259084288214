import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CompanyDetail from "../../../containers/company/list";
import CompanyEdit from "../../../containers/company/edit";
import CompanyMailerEdit from "../../../containers/company/mailer/index";
import { PortalsAdd, PortalsEdit, PortalsList, PortalsView } from "../../../containers/portals";

class PortalRoutes extends React.Component {
  render() {
    const { gblUserId, gblUserIsPortalAdmin, gblUserIsGlobalAdmin, gblUserRole, currentPortalId, currentPractice, me } = this.props;
    if (!currentPortalId && !currentPractice) return <Redirect to="/settings/account" strict />;

    return (
      <Switch>
        <Redirect exact from="/companies/" to="/companies" strict />
        <Redirect exact from="/companies/add/" to="/companies/add" strict />
        <Redirect exact from="/companies/:slug/edit/" to="/companies/:slug/edit" strict />
        <Redirect exact from="/companies/:slug/" to="/companies/:slug" strict />
        <Redirect exact from="/companies/:slug/" to="/companies/:slug" strict />
        <Redirect exact from="/company/mailer/:id/" to="/company/mailer/:id" strict />

        <Redirect exact from="/company/" to="/company" strict />
        <Redirect exact from="/company/edit/" to="/company/edit" strict />

        <Route
          exact
          path="/company"
          render={(props) => <CompanyDetail {...props} me={me} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} gblUserIsPortalAdmin={gblUserIsPortalAdmin} gblUserRole={gblUserRole} currentPortalId={currentPortalId} />}
        />
        <Route
          exact
          path="/company/edit"
          render={(props) => <CompanyEdit {...props} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} gblUserIsPortalAdmin={gblUserIsPortalAdmin} gblUserRole={gblUserRole} currentPortalId={currentPortalId} />}
        />

        <Route
          exact
          path="/company/mailer/:id"
          render={(props) => <CompanyMailerEdit {...props} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} gblUserIsPortalAdmin={gblUserIsPortalAdmin} gblUserRole={gblUserRole} currentPortalId={currentPortalId} />}
        />

        <Route exact path="/companies" render={(props) => <PortalsList {...props} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} gblUserIsPortalAdmin={gblUserIsPortalAdmin} gblUserRole={gblUserRole} />} />
        <Route exact path="/companies/add" render={(props) => <PortalsAdd {...props} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} gblUserIsPortalAdmin={gblUserIsPortalAdmin} gblUserRole={gblUserRole} />} />
        <Route exact path="/companies/:slug/edit" render={(props) => <PortalsEdit {...props} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} gblUserIsPortalAdmin={gblUserIsPortalAdmin} gblUserRole={gblUserRole} />} />
        <Route exact path="/companies/:slug" render={(props) => <PortalsView {...props} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} gblUserIsPortalAdmin={gblUserIsPortalAdmin} gblUserRole={gblUserRole} />} />
      </Switch>
    );
  }
}

export default PortalRoutes;
