import React, { useState } from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import { Form, Icon, Input, Spin, Menu, Dropdown, Button } from "antd";
import { practiceConfig } from "../../../config";
import { Table } from "../../../elements";
import { ButtonBar, Titlebar } from "../../../components";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import { FormatPhoneNumber } from "../../../helpers/formatters";
import moment from "moment";
import _, { flowRight as compose } from "lodash";
import CustomButton from "../../../components/commons/CustomButton";
import { AccountContext } from "../../../context/AccountContext";
import TitleBar from "../../../components/titlebar/titlebar";
import FilterDelImg from "../../../assets/img/delete-filter.png";
import filterSortImg from "../../../assets/img/filter-icon.svg";
import { Modal } from "react-bootstrap";
import FilterClient from "../../../components/filterClient";
import CompareClient from "./compareClient";
import { formatNumber } from "../../../utils/formatter";

const FormItem = Form.Item;
const InputSearch = Input.Search;

class ClientList extends React.Component {
  static contextType = AccountContext;
  constructor(props) {
    super(props);

    this.state = {
      currentPractice: this.props.currentPractice !== "clients" ? this.props.currentPractice : null,
      limit: practiceConfig.rowsPerPage,
      loading: false,
      order: null,
      orderColumn: "firstName",
      page: 1,
      search: null,
      totalCount: null,
      isFilterModal: false,
      isAssessmentModal: false,
      filterType: [],
      clientId: new URLSearchParams(props.location.search).get("client_id"),
      //advisorQuery: null
    };
    this.assessmentRef = React.createRef();
    this.fetchData = this.fetchData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSearch(searchParams) {
    const { type } = searchParams;
    let search = searchParams;

    if (type === "clear") {
      search = null;

      this.props.form.resetFields();
    }

    this.fetchData(this.state.page, this.state.limit, this.state.order, this.state.orderColumn, search, this.state.filterType);

    this.setState({ search, clientId: null });
  }

  handleTableChange(pagination, filters, sorter) {
    const sorterOrder = sorter.order === "descend" ? "DESC" : "ASC";
    if (Object.keys(sorter).length > 0 && (sorter.field != this.state.orderColumn || sorterOrder != this.state.order)) pagination.current = 1;

    this.fetchData(
      !pagination.current ? this.state.page : pagination.current,
      !pagination.pageSize ? this.state.limit : pagination.pageSize,
      sorter ? sorterOrder : this.state.order,
      sorter.field ? sorter.field : this.state.orderColumn,
      this.state.search,
      this.state.filterType
    );

    this.setState({
      limit: !pagination.pageSize ? this.state.limit : pagination.pageSize,
      page: !pagination.current ? this.state.page : pagination.current,
      order: sorterOrder,
      orderColumn: sorter.field ? sorter.field : this.state.orderColumn,
      clientId: null,
    });
  }

  fetchData(page, limit, order, orderColumn, search, advisorType, clientId = null) {
    this.setState({ loading: true });

    const offset = (page - 1) * limit;
    this.props.data.fetchMore({
      variables: {
        limit,
        offset,
        order,
        orderColumn,
        search,
        advisorType,
        clientId,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        this.setState({ loading: false });
        // console.log('prev', previousResult)
        // console.log('new: ', fetchMoreResult)

        if (!fetchMoreResult) {
          // console.log('No fetch more result, show prev: ', previousResult)
          return previousResult;
        }
        this.setState({
          totalCount: fetchMoreResult.getAllClients.pagination.totalCount,
        });
        // console.log('show fetchmore: ', previousResult)
        return fetchMoreResult;
      },
    });
  }

  componentDidUpdate(prevProps) {
    //if (this.props.advisorQuery.length > 0) return;

    if (this.props.location.search != prevProps.location.search) {
      const search = new URLSearchParams(this.props.location.search).get("search");
      this.setState({ search, page: 1, clientId: null });
      this.fetchData(1, this.state.limit, this.state.order, this.state.orderColumn, search, this.state.filterType);
    }
  }

  fetchClientByAdvisor = (filter) => {
    // console.log("new method propes=>", this.props);
    this.props.data.refetch(filter);
  };

  setFilterModal = (value) => this.setState({ isFilterModal: value });

  setFilterType = (value) => {
    // console.log("value==>", value);
    this.setState({ filterType: value ? value.advisorType : [] }, (prevState) => {
      this.fetchData(1, this.state.limit, this.state.order, this.state.orderColumn, this.state.search, value ? value.advisorType : []);
    });
  };

  assessmentHandler = () => {
    this.setState({ isAssessmentModal: !this.state.isAssessmentModal });
  };

  render() {
    const { currentPractice, limit, page, me } = this.state;
    const {
      advisorQuery: { getAdvisorDashboard },
      data: { loading, error, getAllClients = null },
      form: { getFieldDecorator },
    } = this.props;

    if (loading) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <Titlebar currentPractice="" displayText="Clients" />
          </div>
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <Titlebar currentPractice="" displayText="Clients" />
          </div>
          <div className="loading-wrapper">
            <p>Whoopsie - clients didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    const columns = [
      {
        dataIndex: "lastName",
        render: (text, client) => (
          <Link className={client.status === "Inactive" ? "inactive" : null} to={`/${client.practiceName}/clients/view/${client.id}`}>
            {client.firstName} {client.lastName}
          </Link>
        ),
        sorter: true,
        title: "Client",
      },
      {
        dataIndex: "adv_name",
        title: "Advisor",
        sorter: true,
      },
      {
        dataIndex: "dateCollected",
        render: (text, row) => {
          if (!row.dateCollected) return "n/a";
          return row.dateCollected;
        },
        sorter: true,
        title: "Last Assessment Date",
      },
      {
        dataIndex: "email",
        sorter: true,
        title: "Email",
        className: "email-column",
      },
      {
        dataIndex: "phoneNumber",
        render: (text, row) => (row.phone_code && text && text != "null" ? formatNumber(row.phone_code, text) : ""),
        sorter: true,
        title: "Phone",
      },
      {
        dataindex: "allInsights",
        render: (text, row) => {
          const allInsights = row.allInsights;
          const dropdownMenuItems = allInsights.map((insight) => {
            return (
              <Menu.Item key={`'${insight.id}'`}>
                <a rel="noreferrer" href={`/${row.practiceName}/clients/view/${row.id}/${insight.id}`}>
                  {moment
                    .utc(insight.createdAt)
                    .local()
                    .format("MM-DD-YYYY")}
                </a>
              </Menu.Item>
            );
          });
          const dropdownMenu = <Menu>{[...dropdownMenuItems]}</Menu>;

          return (
            <Dropdown overlay={dropdownMenu} disabled={allInsights.length < 1}>
              <Button type="primary" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                View Assessments ({allInsights.length})<Icon type="down" />
              </Button>
            </Dropdown>
          );
        },
        title: "Assessment Archive",
        className: "archiveColumn",
      },
    ];

    if (currentPractice) {
      columns.push({
        className: "action-column",
        title: "Edit",
        //fixed: "right",
        render: (client) => (
          <CustomButton
            rights={["superAdmin", "editclients"]}
            onClick={() => {
              this.props.history.push(`/${currentPractice}/clients/edit/${client.id}`);
            }}
            icon={"edit"}
            type={"link"}
          />
        ),
      });
    }

    const {
      clients,
      pagination: { totalCount = 0, offset = 0 },
    } = getAllClients;
    const tableCount = this.state.totalCount > 0 ? this.state.totalCount : totalCount;
    let tablePagination = false;
    var currentpage = offset / limit + 1;
    if (tableCount > practiceConfig.rowsPerPage) {
      tablePagination = {
        current: currentpage,
        defaultPageSize: practiceConfig.rowsPerPage,
        pageSize: limit,
        pageSizeOptions: ["10", "20", "50", "100"],
        showQuickJumper: true,
        showSizeChanger: true,
        total: tableCount,
      };
    }
    const countStart = (currentpage - 1) * limit + 1;
    const countEnd = currentpage * limit > tableCount ? tableCount : currentpage * limit;
    const display = tableCount ? `Displaying ${countStart}-${countEnd} of ${tableCount}` : "No Records";

    const userContext = this.context;
    const { account } = userContext;

    const advisors = getAdvisorDashboard ? getAdvisorDashboard.result : [];

    return (
      <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper">
          <TitleBar title="Clients" subTitle={account && (account.accountType == 1 ? account.portalname : `${account.practicename} | ${account.portalname}`)} />
          {/* <Titlebar currentPractice={this.props.currentPractice} displayText="Clients" /> */}
          {currentPractice ? (
            <div className="button-bar-wrapper">
              <Button className="btnCompare" onClick={() => this.setState({ isAssessmentModal: true })}>
                Compare Client
              </Button>
              <CustomButton rights={["superAdmin", "createclients"]} icon="plus" onClick={() => this.props.history.push(`/${currentPractice}/clients/add`)} text="Add New" type={"primary"} className="addnew-link-btn-wrapper">
                Add New
              </CustomButton>{" "}
            </div>
          ) : null}
        </div>
        <LayoutContentWrapper>
          <Modal show={this.state.isFilterModal} onHide={() => this.setFilterModal(!this.state.isFilterModal)} centered className="unitifi-modal-wrapper filterData-modal" size="lg">
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">Filter By</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FilterClient isFilterModal={this.state.isFilterModal} onAdvisorChange={this.fetchClientByAdvisor} setFilterModal={this.setFilterModal} advisors={advisors} setFilterType={this.setFilterType} filterType={this.state.filterType} me={me} />
            </Modal.Body>
          </Modal>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="display-bar with-search p-0">{display}</div>
            <div className="d-flex flex-column align-items-end">
              {/* <span class="me-2">Filters</span> */}
              <a href="#" onClick={() => this.setFilterModal(true)}>
                <img src={filterSortImg} />
              </a>
              {this.state.filterType.length > 0 && (
                <div class="d-flex justify-content-end">
                  <span className="filteText">
                    <a href="#" onClick={() => this.setFilterType(null)}>
                      Clear Filters
                      <img src={FilterDelImg} />
                    </a>
                  </span>
                </div>
              )}
            </div>
          </div>
          {/*<div className="search-bar">
            <Form>
              <FormItem className="oka-list-search">
                {getFieldDecorator('search')(
                  <InputSearch
                    autoComplete="off"
                    className={this.state.search ? 'has-search' : null}
                    enterButton
                    onSearch={this.handleSearch}
                    placeholder="Search..."
                    suffix={this.state.search
                      ? <Icon key="icon" onClick={() => this.handleSearch({ type: 'clear' })} type="close-circle-o" />
                      : <span />}
                  />)}
              </FormItem>
            </Form>
          </div>*/}
          <LayoutContent className="clientlist-table-wrapper">
            <Table
              columns={columns}
              dataSource={clients}
              loading={
                this.state.loading && {
                  indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />,
                }
              }
              rowClassName={(record) => (record.id == this.state.clientId ? "data-row active-row" : "data-row")}
              locale={{ emptyText: "No clients found." }}
              onChange={this.handleTableChange}
              pagination={tablePagination ? { ...tablePagination } : false}
              rowKey={(c) => c.id + "-" + c.practiceName}
              scroll={{ x: columns.length * 150 }}
            />
          </LayoutContent>
        </LayoutContentWrapper>

        <Modal
          show={this.state.isAssessmentModal}
          onHide={() => {
            this.assessmentHandler();
          }}
          size="lg"
          centered
          className="unitifi-modal-wrapper modalAssessment">
          <Modal.Header className="p-0">
            {/* <Modal.Title id="example-modal-sizes-title-sm"> Assessment Reminder</Modal.Title> */}
            {this.state.totalAssessment && (
              <p className="selectAssessment mb-0" onClick={() => this.assessmentRef.current.selectAll({ value: 0, label: `Send assessment to all selected (${this.state.totalAssessment})` })}>
                Select All({this.state.totalAssessment})
              </p>
            )}
          </Modal.Header>
          {/* <Modal.Body> */}
          <CompareClient ref={this.assessmentRef} currentPortalId={this.props.currentPortalId} gblUserId={this.props.gblUserId} {...this.props} me={this.props.me} assessmentHandler={this.assessmentHandler} />
          {/* </Modal.Body> */}
        </Modal>
      </div>
    );
  }
}

const allClientsQuery = gql`
  query($limit: Int, $offset: Int, $order: String, $orderColumn: String, $practiceId: Int, $search: String, $portalId: Int, $roleCode: String, $accountType: Int, $advisorId: String, $practiceAssignId: String, $advisorType: [Int], $clientId: String) {
    getAllClients(
      limit: $limit
      offset: $offset
      order: $order
      orderColumn: $orderColumn
      practiceId: $practiceId
      search: $search
      portalId: $portalId
      roleCode: $roleCode
      accountType: $accountType
      advisorId: $advisorId
      advisorType: $advisorType
      practiceAssignId: $practiceAssignId
      clientId: $clientId
    ) {
      clients {
        id
        city
        email
        firstName
        lastName
        phoneNumber
        phone_code
        practiceName
        dateCollected
        allInsights
        state
        adv_name
      }
      pagination {
        offset
        totalCount
      }
    }
  }
`;

const advisorQuery = gql`
  query($filters: JSON) {
    getAdvisorDashboard(filters: $filters) {
      result
    }
  }
`;

export default compose(
  graphql(allClientsQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        limit: practiceConfig.rowsPerPage,
        practiceId: props.me.practiceid,
        accountType: props.me.accountType,
        roleCode: props.me.rolecode,
        portalId: props.currentPortalId,
        clientId: new URLSearchParams(props.location.search).get("client_id"),
        advisorType: [],
        advisorId: new URLSearchParams(props.location.search).get("assign_id"),
        practiceAssignId: new URLSearchParams(props.location.search).get("practice_id"),
        search: new URLSearchParams(props.location.search).get("search"),
      },
    }),
  }),
  graphql(advisorQuery, {
    name: "advisorQuery",
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        filters: {
          practiceId: props.me.practiceid ? props.me.practiceid : null,
          accountType: props.me.accountType,
          roleCode: props.me.rolecode,
          portalId: props.me.portalid.length > 0 ? props.me.portalid[0] : 0,
        },
      },
    }),
  })
)(Form.create()(ClientList));
