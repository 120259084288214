import { Card, Col, Form, Input, Layout, Row, Typography, Checkbox, Button, Modal, message, Dropdown, Menu, Icon, Popover, notification } from "antd";
import gql from "graphql-tag";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { graphql, useLazyQuery, useMutation, useQuery } from "react-apollo";
import ReCAPTCHA from "react-google-recaptcha";
import logo from "../../../assets/img/unitifi.svg";
import PublicContent from "../../../layouts/public";
import { FormWrapper } from "../../../components/form";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { flowRight as compose } from "lodash";
import decode from "jwt-decode";
import { AccountContext } from "../../../context/AccountContext";
import Info from "../../../assets/img/info-blue.svg";
import eyeIcon from "../../../assets/img/eye-icon.svg";
import IntlMessages from "../../../helpers/intlMessages";
import { LowerCasePassCheck, NumberPassCheck, SpacialCharPassCheck, UpperCasePassCheck, phoneMsg, phoneNumberCheck } from "../../../helpers/formatters";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import axios from "axios";
import { apiUrl } from "../../../config";
import { SelectMainWrapper, SelectWrapper } from "./select.style";
import { isValidPhoneNumber } from "libphonenumber-js";
import { countriesSort, extractPhone, PhoneInput } from "../../../utils/formatter";

const { Title, Text, Paragraph } = Typography;
const { Header, Content } = Layout;
const InputGroup = Input.Group;
const FormItem = Form.Item;

const slugGenerator = (value) =>
  value
    .trim()
    .replace(/[&/\\#,+()$~%.'":;@*?<>[\]{}!^`|=]/g, "")
    .replace(/\s{2,}/g, " ")
    .replace(/[^a-zA-Z0-9]/g, "-")
    .replace(/_+$/, "")
    .toLowerCase();

const Signup = (props) => {
  const { form, mutate, history, location, data } = props;
  const userContext = useContext(AccountContext);
  const { getRoles } = data;
  const { getFieldProps, getFieldDecorator, getFieldValue } = form;
  const [Countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("+1");
  const [countryIso, setCountryIso] = useState("US");
  const [queryParam, setQueryParam] = useState({ account_type: null, role_id: null, practice_id: null, company_id: null, advisor_id: null });
  const [isShowPass, setShowPass] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState(null);
  const [selectedPractice, setSelectedPractice] = useState(null);
  const [readOnlyState, setReadOnlyState] = useState({ isEmailReadOnly: false, isCompanyReadOnly: false });
  const selectInputRef = useRef();

  // const recaptchaRef = React.createRef();
  const accountType = new URLSearchParams(location.search).get("account_type");
  const emailFromUrl = new URLSearchParams(location.search).get("email");
  const CompanyTokenFromUrl = new URLSearchParams(location.search).get("company_token");

  const { loading: countryLoading, error: countryError, data: countryData } = useQuery(
    gql`
      query {
        getCountry {
          countries {
            id
            name
            dial_code
            code
          }
        }
      }
    `
  );
  const [tokenCompanyHandler, { loading: tokenLoading, error: tokenError, data: tokenCompanyData }] = useLazyQuery(
    gql`
      query($companyToken: String!) {
        getCompanyDetailsbyToken(companyToken: $companyToken) {
          company
        }
      }
    `
  );

  const [organizationSave, { data: orgData, loading: loadingSubmit, error: errorSubmit }] = useMutation(gql`
    mutation($id: Int, $utype: String) {
      organizationJoinRequestByUser(id: $id, utype: $utype) {
        ok
        error
        message
      }
    }
  `);

  const [state, setState] = useState({
    visible: false,
    accept: false,
    verify: false,
    loading: false,
  });
  const [error, setError] = useState({
    email: false,
  });
  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    if (!location) return;

    let accountType = new URLSearchParams(location.search).get("account_type");
    if (!accountType || (accountType != 1 && accountType != 2)) {
      history.push("/login");
      return;
    }
    if (roleList.length == 0) return;

    let obj = queryParam;
    let roleId = new URLSearchParams(location.search).get("role_id");
    let practiceId = new URLSearchParams(location.search).get("practice_id");
    let companyId = new URLSearchParams(location.search).get("company_id");
    let advisorId = new URLSearchParams(location.search).get("advisor_id");
    if (roleId) obj.role_id = roleId;
    if (roleId) obj.practice_id = practiceId;
    if (companyId) obj.company_id = companyId;
    if (advisorId) obj.advisor_id = advisorId;
    obj.account_type = accountType;
    setQueryParam(obj);

    if (practiceId && companyId) {
      practiceDetailHandler(practiceId);
    }

    if (roleId) {
      let role = roleList.find((data) => data.value == roleId);

      form.setFields({
        role_id: {
          value: role,
        },
      });
    }
  }, [location.search, roleList]);

  useEffect(() => {
    if (!CompanyTokenFromUrl) return;
    tokenCompanyHandler({ variables: { companyToken: CompanyTokenFromUrl } });
  }, [CompanyTokenFromUrl]);

  useEffect(() => {
    if (!tokenCompanyData) return;

    if (tokenCompanyData.getCompanyDetailsbyToken.company.length > 0) {
      let company = tokenCompanyData.getCompanyDetailsbyToken.company[0];
      form.setFields({
        companyName: {
          value: company.name,
        },
      });
      form.setFields({
        email: {
          value: company.primaryContactEmail,
          readOnly: true,
        },
      });
      setReadOnlyState({ isEmailReadOnly: true, isCompanyReadOnly: true });
    }
  }, [tokenCompanyData]);

  useEffect(() => {
    if (!countryData) return;
    let result = countryData.getCountry.countries;

    let countArr = [];
    for (let res of result) {
      if (res.name != "Canada") {
        let obj = {
          label: res.dial_code,
          id: res.dial_code,
          code: res.code,
        };
        countArr.push(obj);
      }
    }
    const sortedOptions = countriesSort(Countries);
    setCountries(sortedOptions);
  }, [countryData]);

  useEffect(() => {
    if (!getRoles) return;
    let roleArr = [];
    for (let role of getRoles.roles) {
      roleArr.push({ label: role.name, value: role.id });
    }
    setRoleList(roleArr);
  }, [getRoles]);

  useEffect(() => {
    if (!orgData) return;

    let data = orgData.organizationJoinRequestByUser;
    if (data.ok) {
      notification.success({ message: data.message });
      history.push("/settings/account");
    } else {
      notification.error({ message: data.error });
    }
  }, [orgData]);

  const handleOk = () => {
    setState({ ...state, visible: false, accept: true });
    form.setFields({
      term: {
        value: true,
        checked: true,
        errors: "",
      },
    });
  };

  const handleCancel = () => {
    setState({ ...state, visible: false, accept: false });
    form.setFields({
      term: {
        checked: undefined,
        errors: [new Error("Agreeing to terms & conditions is required")],
      },
    });
  };

  const onVerify = (recaptchaResponse) => {
    if (recaptchaResponse) {
      setState({ ...state, verify: true, visible: true });
    } else {
      setState({ ...state, verify: false });
    }
  };

  const onChecked = () => {
    setState({ ...state, visible: true });
  };

  const pass = getFieldValue("password");

  const handleSubmit = async (e) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        setState({ ...state, loading: true });

        let memberCompanyName = null;
        let accountType = queryParam.account_type;
        //  let phoneNumber = values.p1;
        delete values.phoneNumber;
        if (values.p1) values.phoneNumber = extractPhone(values.p1);
        delete values.p1;
        delete values.p2;
        delete values.p3;
        let variables = { ...values, accountType: accountType, roleId: values.role_id.value, phoneCode: countryCode };
        if (variables.email) variables.email = variables.email.trim();
        if (values.companyName && accountType == 1) {
          const slug = slugGenerator(values.companyName);
          variables.slug = slug;
        }
        if (queryParam.practice_id) variables.practiceId = parseInt(queryParam.practice_id);
        if (queryParam.company_id) variables.companyId = parseInt(queryParam.company_id);

        delete variables.role_id;

        if (accountType == 2) {
          memberCompanyName = variables.companyName;
          delete variables.companyName;
        }

        if (CompanyTokenFromUrl) variables.companyToken = CompanyTokenFromUrl;

        await mutate({
          variables: variables,
        }).then(function(result) {
          if (result && result.data && result.data.createNewAccount && result.data.createNewAccount.errors) {
            result.data.createNewAccount.errors.forEach((element) => {
              message.error(element.message);
            });
            setState({ ...state, loading: false });
            return;
          }

          const {
            createNewAccount: { id, userExisted, token, email, refreshToken, userPermissions, portalExisted },
          } = result.data || {
            createNewAccount: {
              id: null,
              userExisted: false,
              token: "",
              refreshToken: "",
              portalExisted: false,
            },
          };

          if (id) {
            let queryUrl = `/signup-2fa${location.search}&email=${encodeURIComponent(email)}`;
            if (queryParam.account_type == "2" && memberCompanyName) queryUrl = queryUrl + "&memberCompanyName=" + memberCompanyName.value + "&utype=" + memberCompanyName.utype;

            history.push(`${queryUrl}`);
            return;
            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", refreshToken);
            userContext.setUserPermission(userPermissions);
            const { user } = decode(token);

            // userContext.setAccount(user);
            if (user.portals) {
              localStorage.setItem("current_company_id", user.portals.id);
              localStorage.setItem("current_company", user.portals.slug);
            }
            if (queryParam.account_type == "1") history.push(`/collect-payment`);
            // else if (queryParam.account_type == "2" && (queryParam.practice_id || queryParam.company_id)) history.push("/");
            else if (queryParam.account_type == "2") {
              const roles = roleList.find((data) => data.value == variables.roleId);
              // let roleType = "both";
              // if (roles.label.includes("Practice")) roleType = "practice";
              // if (roles.label.includes("Company")) roleType = "company";

              if (queryParam.practice_id || queryParam.company_id) {
                history.push("/news-center");
                return;
              }
              setTimeout(() => {
                if (memberCompanyName) organizationSave({ variables: { id: parseInt(memberCompanyName.value), utype: memberCompanyName.utype } });
              }, 200);
              // history.push("/search-organization?roleType=" + roleType);
            } else history.push("/login");
            return;
          }

          if (userExisted) {
            setState({ ...state, loading: false });
            message.error("Email already exists.");
            // .then(() => message.loading("Redirected to Login page."))
            // .then(() => history.push(`/login`));
            // setError({ ...error, email: true })
          }
          if (portalExisted) {
            setState({ ...state, loading: false });
            message.error("Organization already exists.");
          }
        });
      }
    });
  };

  const cancelHandler = () => {
    history.replace("/signup_new");
  };

  const phondeCodeHandler = (val) => {
    setCountryCode(val.id);
    setCountryIso(val.code);
    form.setFields({
      p1: {
        value: "",
      },
    });
  };

  const organizationHandler = useCallback(
    debounce((inputText, callback) => {
      organizationApiHandler(inputText).then((options) => callback(options));
    }, 1000),

    []
  );

  const organizationApiHandler = async (input) => {
    if (input.trim().length <= 1) return;
    let result = await axios.get(`${apiUrl}/search-organization/${encodeURIComponent(input)}`);
    result = result.data;

    if (result.length == 0) return;

    return result.map((data) => ({
      label: data.displayname,
      value: data.id,
      utype: data.utype,
    }));
  };

  const practiceDetailHandler = async (id) => {
    let result = await axios.get(`${apiUrl}/search-organization/test/${id}`);
    result = result.data;
    if (result.length == 0) return;

    let res = result.map((data) => ({
      label: data.displayname,
      value: data.id,
      utype: data.utype,
    }));
    setDefaultOptions(res);
    setSelectedPractice(res[0].label);
  };

  const validatePhone = (_, value, callback) => {
    if (value && typeof value == "string") {
      const phoneNumber = isValidPhoneNumber(value, countryIso);
      if (!phoneNumber) {
        callback("Please enter valid phone number.");
      } else callback();
    } else callback();
  };

  const handleCountrySelectChange = (inputValue) => {
    const sortedOptions = countriesSort(Countries, inputValue);
    setCountries(sortedOptions);
  };

  const passwordValidatorPop = (
    <>
      <div className="tooltip-title mb-2 text-center">Password Requirements</div>
      <div className={pass ? `input-password-feedback-signup ${pass.length >= 8 ? "success" : "error"}` : "input-password-feedback-signup"}>
        <Icon type={pass ? `${pass.length >= 8 ? "check" : "close"}-circle` : "exclamation-circle"} />
        <IntlMessages id="passwordFeedback.length" />
      </div>
      <div className={pass ? `input-password-feedback-signup ${UpperCasePassCheck.test(pass) ? "success" : "error"}` : "input-password-feedback-signup"}>
        <Icon type={pass ? `${UpperCasePassCheck.test(pass) ? "check" : "close"}-circle` : "exclamation-circle"} />
        <IntlMessages id="passwordFeedback.uppercase" />
      </div>
      <div className={pass ? `input-password-feedback-signup ${LowerCasePassCheck.test(pass) ? "success" : "error"}` : "input-password-feedback-signup"}>
        <Icon type={pass ? `${LowerCasePassCheck.test(pass) ? "check" : "close"}-circle` : "exclamation-circle"} />
        <IntlMessages id="passwordFeedback.lowercase" />
      </div>
      <div className={pass ? `input-password-feedback-signup ${SpacialCharPassCheck.test(pass) ? "success" : "error"}` : "input-password-feedback-signup"}>
        <Icon type={pass ? `${SpacialCharPassCheck.test(pass) ? "check" : "close"}-circle` : "exclamation-circle"} />
        <IntlMessages id="passwordFeedback.specialCharacter" />
      </div>
      <div className={pass ? `input-password-feedback-signup ${NumberPassCheck.test(pass) ? "success" : "error"}` : "input-password-feedback-signup"}>
        <Icon type={pass ? `${NumberPassCheck.test(pass) ? "check" : "close"}-circle` : "exclamation-circle"} />
        <IntlMessages id="passwordFeedback.number" />
      </div>
    </>
  );

  const orgPopOver = (
    <>
      <h5>Organization Name</h5>
      <p>If you are unable to find your company or practice, this means they have yet to create an account. Please request your company or practice to create an account.</p>
    </>
  );
  const autoFocusPhone = (e, p, c, n) => {
    let length = document.getElementById(c).value.length;
    let maxLength = document.getElementById(c).getAttribute("maxlength");

    if (e.key == "Shift" || e.key == "Tab") {
      document.getElementById(c).focus();
      return;
    }
    if (length == maxLength) {
      if (n != "") {
        document.getElementById(n).focus();
      }
    }
    // if (e.key == "Backspace") {
    //   console.log("n==>", n);
    //   if (p != "") {
    //     document.getElementById(n).focus();
    //   }
    // }
  };

  // const menuKey = ["advisors", "assessments", "clients", "practices", "users", "portals"];
  const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";

  const Control = ({ children, ...props }) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <SelectMainWrapper>
          {children}
          <SelectWrapper>{capitalize(data.utype)}</SelectWrapper>
        </SelectMainWrapper>
      </components.Option>
    );
  };

  return (
    <PublicContent className="stretch-content unitifi-login-wrapper unitifi-signup-wrapper">
      <div className="public-form-wrapper">
        <FormWrapper className="public-form">
          <div className="unitifi-login-header-bar">
            <h5>{accountType == 2 ? "Create Member Account " : "Create Account Owner"}</h5>
          </div>
          <Form layout="vertical" onSubmit={handleSubmit} requiredMark={false}>
            {accountType == "1" && (
              <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                  <Form.Item hasFeedback label={"Organization Name"} style={{ color: "rgba(0, 0, 0, 0.85)", marginBottom: "10px" }}>
                    {getFieldDecorator("companyName", {
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          message: "Organization name is required",
                        },
                      ],
                    })(<Input placeholder="Company, Practice, Solo Advisory" type={"text"} readOnly={readOnlyState.isCompanyReadOnly} />)}
                  </Form.Item>
                </Col>

                <Col className="gutter-row  orgType" span={12}>
                  <Form.Item hasFeedback label={accountType == 2 ? "Select Member Type " : "Select Organization Type"}>
                    {getFieldDecorator("role_id", {
                      initialValue: "",

                      rules: [
                        {
                          message: "Role is required",
                          required: true,
                        },
                      ],
                    })(<Select options={roleList} className="unitifi-select-container" isDisabled={queryParam.role_id ? true : false} classNamePrefix="unitifi-react-select" />)}
                  </Form.Item>
                </Col>
              </Row>
            )}
            {accountType == 2 && (
              <Row gutter={16}>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    className="orgName remove-icon"
                    hasFeedback
                    label={
                      <p className="mb-0">
                        Organization Name <span>*</span>
                        <Popover overlayClassName="orgInfo" content={orgPopOver} trigger="hover">
                          <i className="ms-2">
                            <img src={Info} />
                          </i>
                        </Popover>
                      </p>
                    }
                    style={{ color: "rgba(0, 0, 0, 0.85)", marginBottom: "10px" }}>
                    {getFieldDecorator("companyName", {
                      initialValue: queryParam.practice_id ? defaultOptions && defaultOptions[0] : "",
                      rules: [
                        {
                          required: true,
                          message: "Organization name is required",
                        },
                      ],
                    })(
                      <AsyncSelect
                        components={{ Option: Control }}
                        placeholder="Company Name - Practice Name"
                        ref={selectInputRef}
                        loadOptions={organizationHandler}
                        className="unitifi-select-container"
                        classNamePrefix="unitifi-react-select"
                        isDisabled={queryParam.practice_id ? true : false}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col className="gutter-row  orgType" span={12}>
                  <Form.Item hasFeedback label={accountType == 2 ? "Select Member Type " : "Select Organization Type"}>
                    {getFieldDecorator("role_id", {
                      initialValue: "",

                      rules: [
                        {
                          message: "Role is required",
                          required: true,
                        },
                      ],
                    })(<Select options={roleList} className="unitifi-select-container" isDisabled={queryParam.role_id ? true : false} classNamePrefix="unitifi-react-select" />)}
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  hasFeedback
                  // validateStatus={error.email ? "error" : ""}
                  label={"Email"}
                  style={{
                    color: "rgba(0, 0, 0, 0.85)",
                    marginBottom: "10px",
                  }}>
                  {getFieldDecorator("email", {
                    initialValue: emailFromUrl ? emailFromUrl : "",
                    rules: [
                      {
                        required: true,
                        message: "Email is required",
                      },
                      {
                        type: "email",
                        message: "Email is not valid",
                      },
                      // {
                      //   validator: emailCheckValidator,
                      // },
                    ],
                  })(<Input placeholder="Email" type={"email"} autocomplete="new-email" readOnly={readOnlyState.isEmailReadOnly} />)}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <FormItem className="input-phone__wrapper" label="Phone Number">
                  <InputGroup compact className="unitifi-number-inputbox-wrapper UnitifiCustomerNumberInputbox">
                    <Select
                      options={Countries}
                      onInputChange={handleCountrySelectChange}
                      defaultValue={{ label: "+1", value: "+1" }}
                      onKeyDown={(event) => {
                        if (
                          event.key == "Backspace" ||
                          event.key == "Delete" ||
                          event.key == "ArrowLeft" ||
                          event.key == "ArrowRight" ||
                          event.key == "Meta" ||
                          event.key == "ArrowUp" ||
                          event.key == "ArrowDown" ||
                          event.key == "Enter" ||
                          event.key == "Tab"
                        ) {
                        } else if (!/[0-9+]/.test(event.key)) event.preventDefault();
                      }}
                      onChange={(val) => phondeCodeHandler(val)}
                      className="unitifi-select-container phoneInputBox"
                      classNamePrefix="unitifi-react-select"
                    />
                    {getFieldDecorator("phoneNumber", {
                      rules: [
                        {
                          required: false,
                          message: "Phone is required",
                        },
                        {
                          validator: validatePhone,
                        },
                      ],
                    })(
                      <div className="number-inputbox phoneInputData m-0 w-100">
                        {getFieldDecorator("p1", {
                          rules: [
                            {
                              required: true,
                              message: "Phone is required",
                            },
                          ],
                        })(<PhoneInput countryCode={countryIso} />)}
                      </div>
                    )}
                  </InputGroup>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  hasFeedback
                  label={"First Name"}
                  style={{
                    color: "rgba(0, 0, 0, 0.85)",
                    marginBottom: "10px",
                  }}>
                  {getFieldDecorator("firstName", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "First Name is required",
                      },
                    ],
                  })(<Input placeholder="First Name" type={"text"} />)}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item hasFeedback label={"Last Name"} style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                  {getFieldDecorator("lastName", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Last Name is required",
                      },
                    ],
                  })(<Input placeholder="Last Name" type={"text"} />)}
                </Form.Item>
              </Col>
            </Row>

            <div className="unitifi-footer">
              <div className="unitifi-copy-link-text">
                <Form.Item hasFeedback>
                  {getFieldDecorator("term", {
                    rules: [
                      {
                        required: true,
                        message: "Agreeing to terms & conditions is required",
                        validator: async (_, checked) => {
                          if (!checked) {
                            return Promise.reject(new Error("Agreeing to terms & conditions is required"));
                          }
                        },
                      },
                    ],
                  })(
                    <Checkbox onChange={() => onChecked()} checked={state.accept}>
                      {" "}
                      I agree to <Text underline={true}> terms and conditions </Text>.
                    </Checkbox>
                  )}
                </Form.Item>
              </div>
              <div className="unitifi-footer-wrapper unitifi-signupp-footer-wrapper">
                <Button
                  block
                  type="primary"
                  htmlType="button"
                  size="large"
                  disabled={state.loading}
                  // disabled={!(state.accept && state.verify)}
                  onClick={() => cancelHandler()}
                  className="unitifi-login-btn-border">
                  Cancel
                </Button>
                <Button block type="primary" size="large" htmlType="submit" loading={state.loading} disabled={state.loading} className="unitifi-login-btn">
                  Continue
                </Button>
              </div>
            </div>
            {/* <Form.Item>
              <ReCAPTCHA
                size='compact'
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECHAPTCHA_SITEKEY}
                onChange={onVerify}
              />
            </Form.Item> */}
          </Form>
          <div className="unitifi-copy-link-text">
            <p>Already have an account? </p>
            <Link to="/" className="link-text">
              Log in
            </Link>
          </div>
        </FormWrapper>
      </div>
      <Modal title="Terms and Conditions" visible={state.visible} onOk={() => handleOk()} onCancel={() => handleCancel()} closable={false} maskClosable={false} okText={"Accept"} cancelText={"Decline"}>
        <Paragraph style={{ textAlign: "center" }}>
          <b>
            FINANCIAL INDUSTRY INFORMATION AND RATINGS ORGANIZATION, INC. <br /> TERMS OF SERVICE - FINANCIAL ADVISORS
          </b>
        </Paragraph>
        <Paragraph>
          These terms of service (these "Terms") govern the relationship between FINANCIAL INDUSTRY INFORMATION AND RATING ORGANIZATION, INC. dba UNITIFI ("UNITIFI", “us”, or “we”) and companies and individuals that wish to engage with UNITIFI to
          obtain information pertaining to their clients, prospective clients who utilize UNITIFI’s electronic assessments and other materials utilized to obtain data and other information necessary for UNITIFI to provide the services requested by
          you. All of the products and/or services described in these Terms, as well as any other products or services offered by UNITIFI to such companies, at any time, shall be referred to herein as a “Service” or “Services”. To use the Service,
          you, the company (“You” or “Your”), must read and accept all of the terms and conditions in these Terms. BY SIGNING THE LAST PAGE OF THIS DOCUMENT, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREED TO BE BOUND BY ALL OF THE TERMS,
          CONDITIONS, AND NOTICES CONTAINED IN THESE TERMS, JUST AS IF YOU HAD SIGNED THESE TERMS.
        </Paragraph>
        <Paragraph>
          <b>I. SERVICES</b>
        </Paragraph>
        <Paragraph>
          <b>Services</b>. Utilizing UNITIFI’s electronic assessments, e-mail requests or other medium provided to you, U ITIFI will provide Services to assist You by collecting, aggregating, summarizing consumer behavior intelligence information
          submitted by your clients or prospective clients in response to UNITIFI’s assessments and providing You with summary reports for each such client or prospective client. Your clients and prospective clients must submit information responsive
          to UNITIFI’s assessments and questions designed to obtain a better understanding of each client's true financial behavior intelligence.{" "}
        </Paragraph>
        <Paragraph>
          The information pertaining to your clients will be aggregated and electronically available to You via the UNITIFI app. Such reports may assist You in assessing your clients reported financial consumer behavior when it comes to investing,
          planning and communicating for their financial futures. The reports will be provided to You on a client-by-client basis, as well as a comprehensive book of business view. You agree that you will not to circumvent, reverse-engineer, or
          otherwise alter the materials used by UNITIFI to obtain related information from Your clients.{" "}
        </Paragraph>
        <Paragraph>
          As part of the Service, You may have the opportunity to enter information specific to Your company or your clients who utilize UNITIFI’s electronic assessments or other tools designed to obtain financial behavior intelligence information
          from Your clients. Your submission of such company information is strictly voluntary. However, you may be unable to use some of the Services without inputting the requested information. When submitting any information, you must provide
          UNITIFI with complete and accurate information as prompted by e-mails, UNITIFI’s app, or other medium.
        </Paragraph>
        <Paragraph>
          <b>II. AGREEMENT AND PAYMENT</b>
        </Paragraph>
        <Paragraph>
          <b>Agreement</b>. In order to utilize the Services offered by UNITIFI through its electronic assessments, website or other medium, You agree to the fees to be charged by completing the appropriate order form(s).{" "}
        </Paragraph>
        <Paragraph>
          <b>Payment</b>. The payment terms and amount due by You to UNITIFI in exchange for the Services will be communicated to You and agreed upon in the order form. You will receive a monthly invoice detailing the fees charged to You for Services
          rendered during the preceding month. You will make payment in full on each invoice by the fifteenth (15th) of the month following receipt of services from UNITIFI. UNITIFI will charge and You will pay interest on all late payments thirty
          (30) days beyond due date. Such interest shall accrue on any outstanding balance at the lower of (a) 1.5% per month, or (2) the highest rate allowed under applicable law. All charges for the Services will be net of applicable taxes.
        </Paragraph>
        <Paragraph>
          In addition to the Service charges and fees, You shall also be responsible for all taxes assessed in connection with the Services, including any foreign, federal, state, or local taxes and charges assessed in connection with a Service,
          including, without limitation, all governmental excise, use, sales, value-added, and occupational taxes and other fees, or other similar surcharges and levies (including, without limitation, universal service fund charges or equivalents),
          but excluding any taxes based on a party's net income ("Taxes"). You agree that You are solely responsible for collection and payment of any and all Taxes that You are required to pay to any taxing authority in connection with Your sale or
          use of the Services, and You shall indemnify us for any losses, liabilities, costs, and expenses incurred as a result of a breach by You of this section.
        </Paragraph>
        <Paragraph>
          <b>III. PROPRIETARY RIGHTS</b>
        </Paragraph>
        <Paragraph>
          Except for the limited license expressly granted herein, UNITIFI expressly reserves all right, title and interest in and to the Services, the data content provided in response to UNITIFI’s assessments, the aggregated data provided from all
          sources, and all processing, analytics, data and other software and technology used by UNITIFI in the provision of the Services ("Technology"), including, without limitation, any derivatives, improvements, enhancements or extensions of the
          Technology conceived, reduced to practice or otherwise developed by or on behalf of UNITIFI, all of which are valuable assets of UNITIFI, together with any copyright, patent or trademark or other intellectual property right, or federal or
          state right, pertaining thereto.
        </Paragraph>
        <Paragraph>
          You shall not: (i) use, or allow the use of, the Services or any Technology, except pursuant to the limited rights expressly granted in these Terms; (ii) use the Services in any manner that is inconsistent with user documentation, if any,
          supplied to You by UNITIFI; (iii) attempt to reverse-engineer, or compromise any aspect of the Services or Technology, or attempt to access data of any other customer of UNITIFI; (iv) remove, obscure or alter any legal notices, including
          notices of intellectual property rights appearing in or on any materials delivered to You by UNITIFI; or (v) use, post, transmit or introduce any device, software or routine which interferes or attempts to interfere with the operation of
          UNITIFI’s Services.
        </Paragraph>
        <Paragraph>
          You shall not use, directly or indirectly, in whole or in part, any signature, monogram, logo, trademark, service mark, or trade name that is now or may hereafter be owned, licensed or used by UNITIFI (a " Mark") except in the manner and to
          the extent approved in writing by UNITIFI prior to any such use, which approval may be withheld in UNITIFI’s sole discretion. Any permitted use of a Mark shall be immediately discontinued upon the termination or expiration of these Terms.
          You hereby expressly disclaim any and all right, title, and interest in and to any Mark whether or not used by You. Your covenants and disclaimers in this section shall survive the termination or expiration of these Terms. You recognize and
          acknowledge the validity and ownership of the Marks in the United States and other countries. You agree not to contest the ownership of such marks. Except for the limited rights granted above, you gain no license or proprietary rights in
          the Marks and will not infringe upon, dilute, or harm FIIRO's rights with respect to the Marks.
        </Paragraph>
        <Paragraph>
          <b>IV. USER-GENERATED/THIRD-PARTY CONTENT</b>
        </Paragraph>
        <Paragraph>
          By utilizing the Service, You automatically grant, and warrant and represent that You have the right to grant, UNITIFI a perpetual, irrevocable, royalty-free, worldwide non-exclusive, assignable, sublicensable, transferable license to use,
          copy, perform, display, and distribute the information needed by UNITIFI to perform its Services for You, and to prepare derivative works of, or incorporate into other works for any purpose. Though FIIRO retains this license interest in the
          information provided to UNITIFI to perform the Services called for herein, You and your clients will retain all ownership rights to the summary reports prepared for You on a client-by-client basis. UNITIFI will not use the summary reports
          prepared for You for any purpose. UNITIFI will retain a perpetual, irrevocable, royalty-free, worldwide non-exclusive, assignable, sublicensable, transferable license to use only the aggregate information provided to UNITIFI in the
          operation of UNITIFI’s business.
        </Paragraph>
        <Paragraph>
          <b>Rules of Conduct</b>. In addition to all other obligations and terms stated in these Terms, you agree to abide by any rules of conduct or community guidelines posted on UNITIFI’s website. UNITIFI reserves the right to exclude or suspend
          any user who violates these rules of conduct, and reserves the right, but does not undertake an affirmative obligation, to remove, screen or edit any content which is contrary to these rules without prior notice. Posted Content does not
          necessarily reflect the opinion of UNITIFI.
        </Paragraph>
        <Paragraph>
          <b>Information Storage and Access.</b> UNITIFI does not guarantee that it will store any information You or your clients submit for any certain period of time. UNITIFI reserves the right, in its sole discretion, to determine whether and how
          long to store user data. UNITIFI also reserves the right, in its sole discretion, with or without notice, to remove or delete user data, reviews, content, or inactive accounts.{" "}
        </Paragraph>
        <Paragraph>
          <b>V. REPRESENTATIONS, WARRANTIES, OR DISCLAIMERS</b>
        </Paragraph>
        <Paragraph>
          THE SERVICES ARE PROVIDED “AS IS” AND THERE ARE NO WARRANTIES, CLAIMS OR REPRESENTATIONS MADE BY FIIRO EITHER EXPRESS, IMPLIED, OR STATUTORY, WITH RESPECT TO THE SERVICE, INCLUDING WARRANTIES OF QUALITY, PERFORMANCE, NON-INFRINGEMENT,
          MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE, NOR ARE THERE ANY WARRANTIES CREATED BY COURSE OF DEALING, COURSE OF PERFORMANCE, OR TRADE USAGE. UNITIFI DOES NOT WARRANT THAT THE SERVICE WILL MEET YOUR NEEDS OR BE FREE FROM ERRORS.
          THE FOREGOING EXCLUSIONS AND DISCLAIMERS ARE AN ESSENTIAL PART OF THESE TERMS AND FORMED THE BASIS FOR DETERMINING THE PRICE CHARGED FOR THE SERVICES. SOME STATES DO NOT ALLOW EXCLUSION OF AN IMPLIED WARRANTY, SO THIS DISCLAIMER MAY NOT
          APPLY TO YOU.
        </Paragraph>
        <Paragraph>
          <b>VI. USE / COMPLIANCE</b>
        </Paragraph>
        <Paragraph>
          You will not use the Services in any way or for any purpose that would violate, or would have the effect of violating, any applicable laws, rules or regulations or any rights of any third-parties, including without limitation, any law or
          right regarding any copyright, patent, trademark, trade secret, music, image, or other proprietary or property right, false advertising, unfair competition, defamation, invasion of privacy or rights of celebrity.{" "}
        </Paragraph>
        <Paragraph>
          <b>VII. LIMITATIONS OF LIABILITY</b>
        </Paragraph>
        <Paragraph>
          UNITIFI WILL NOT BE LIABLE TO YOU OR ANY THIRD-PARTY FOR ANY (A) INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL, OR INCIDENTAL DAMAGES, (B) LOSS OF PROFITS, (C) LOSS OF DATA OR INFORMATION, OR (D) REPUTATIONAL HARM, WHETHER BASED ON A CLAIM OR
          ACTION OF CONTRACT, WARRANTY, NEGLIGENCE, STRICT LIABILITY, OR OTHER TORT, BREACH OF ANY STATUTORY DUTY, INDEMNITY OR CONTRIBUTION, OR OTHERWISE, EVEN IF FIIRO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME STATES DO NOT ALLOW
          THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE LIMITATIONS OR EXCLUSIONS IN THIS PARAGRAPH MAY NOT APPLY TO YOU.
        </Paragraph>
        <Paragraph>
          NOTWITHSTANDING ANYTHING SET FORTH IN THESE TERMS TO THE CONTRARY, UNITIFI’S CUMULATIVE LIABILITY TO YOU OR ANY OTHER PARTY FOR ANY LOSS OR DAMAGES RESULTING FROM ANY CLAIMS, DEMANDS, OR ACTIONS ARISING OUT OF OR RELATING TO THESE TERMS OR
          USE OF THE SERVICE BY YOU OR YOUR PERSONNEL SHALL NOT EXCEED THE AMOUNT PAID BY YOU FOR THE SERVICES DURING THE PRECEDING SIX (6) MONTHS. YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE SERVICE, RELATED
          SERVICES, OR ANY OTHER GRIEVANCE SHALL BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO, OR USE OF THE SERVICE.
        </Paragraph>
        <Paragraph>
          <b>VIII. TERM AND TERMINATION</b>
        </Paragraph>
        <Paragraph>
          These Terms shall be in place for a term of one (1) year (the “Initial Term”) unless terminated by either party upon thirty (30) days’ written notice. At the conclusion of the Initial Term, these Terms will automatically renew for
          successive one (1) year terms unless either party terminates these Terms by providing thirty (30) days’ notice to the other party.{" "}
        </Paragraph>
        <Paragraph>
          <b>IX. APPLICABLE LAW AND VENUE</b>
        </Paragraph>
        <Paragraph>
          These Terms shall be governed by and construed under the laws of the state of Nebraska without reference to its conflicts of law principles. In the event of any conflicts between foreign law, rules, and regulations, and Nebraska law, rules,
          and regulations, Nebraska law, rules and regulations shall prevail and govern. Each party agrees to submit to the exclusive and personal jurisdiction of the courts located in the State of Nebraska, Douglas County. Any notices to UNITIFI
          must be sent to:
        </Paragraph>
        <Paragraph style={{ marginLeft: 40 }}>
          {" "}
          Fraser Stryker PC LLO <br />
          500 Energy Plaza <br />
          409 South 17th Street <br />
          Omaha, NE 68102 <br />
          Attn: Paul Shotkoski
        </Paragraph>
        <Paragraph>
          via first class or air mail or overnight courier and are deemed given upon receipt. A waiver of any default is not a waiver of any subsequent default. The relationship between UNITIFI and You is not one of a legal partnership relationship
          but is one of independent contractors. These Terms shall be binding upon and inure to the benefit of the respective successors and assigns of the parties hereto.
        </Paragraph>
        <Paragraph>
          <b>X. MISCELLANEOUS</b>
        </Paragraph>
        <Paragraph>
          These Terms (including any amendment agreed upon by the parties in writing) represent the complete agreement between us concerning its subject matter and supersedes all prior agreements and representations between the parties. If any
          provision of these Terms is held to be unenforceable for any reason, such provision shall be reformed to the extent necessary to make it enforceable to the maximum extent permissible so as to affect the intent of the parties, and the
          remainder of these Terms shall continue in full force and effect. A waiver of any default is not a waiver of any subsequent default. These Terms shall be binding upon and inure to the benefit of the respective successors and assigns of the
          parties hereto.
        </Paragraph>
      </Modal>
      {/* </Content> */}
      {/* </Layout> */}
    </PublicContent>
  );
};

// Signup
// const loginUserMutation = gql`
//   mutation($address: String, $city: String, $email: String!, $firstName: String, $lastName: String, $password: String, $phoneNumber: String, $state: String, $zipCode: String, $companyName: String, $slug: String) {
//     createUserSignup(address: $address, city: $city, email: $email, firstName: $firstName, lastName: $lastName, password: $password, phoneNumber: $phoneNumber, state: $state, zipCode: $zipCode, companyName: $companyName, slug: $slug) {
//       ok
//       id
//       token
//       refreshToken
//       userExisted
//       errors {
//         path
//         message
//       }
//     }
//   }
// `;
const loginUserMutation = gql`
  mutation(
    $accountType: String
    $email: String!
    $firstName: String
    $lastName: String
    $password: String
    $phoneNumber: String
    $phoneCode: String
    $companyName: String
    $slug: String
    $roleId: Int
    $practiceId: Int
    $companyId: Int
    $advisorId: Int
    $companyToken: String
  ) {
    createNewAccount(
      accountType: $accountType
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      phoneNumber: $phoneNumber
      phoneCode: $phoneCode
      companyName: $companyName
      slug: $slug
      roleId: $roleId
      practiceId: $practiceId
      companyId: $companyId
      advisorId: $advisorId
      companyToken: $companyToken
    ) {
      ok
      id
      token
      email
      refreshToken
      userExisted
      portalExisted
      userPermissions {
        id
        slug
      }
      errors {
        path
        message
      }
    }
  }
`;
// Roles
const getRoles = gql`
  query($accountType: String!) {
    getRoles(accountType: $accountType) {
      roles {
        id
        name
        accountType
        code
      }
    }
  }
`;

//  Country

export default compose(
  graphql(loginUserMutation),
  graphql(getRoles, {
    options: (props) => ({
      variables: {
        accountType: new URLSearchParams(props.location.search).get("account_type"),
      },
    }),
  })
)(Form.create()(Signup));
