import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { notification } from "antd";
import { Redirect } from "react-router";
import { Titlebar } from "../../../components";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import ClientForm from "../form/clientForm";
import { AccountContext } from "../../../context/AccountContext";
import TitleBar from "../../../components/titlebar/titlebar";

class ClientAdd extends React.Component {
  static contextType = AccountContext;
  constructor(props) {
    super(props);
    this.state = {
      cancelUrl: this.props.location.state && this.props.location.state.redirect ? this.props.location.state.redirect : `/${this.props.currentPractice}/clients`,
      key: 1,
      loading: false,
      redirectTo: null,
      reset: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleSubmit(values) {
    values.city = values.city ? values.city : "";
    values.state = values.state ? values.state : "";
    values.country = values.country ? values.country : "";
    this.setState({
      loading: true,
    });
    const variables = {
      ...values,
      phoneNumber: values.phoneNumber ? `${values.phoneNumber}` : "",
      practiceId: this.props.currentPractice,
      portalId: this.props.currentPortalId,
      phone_code: values.countryCode ? values.countryCode : "",
      city: values.city.label ? values.city.label : "",
      state: values.state.label ? values.state.label : "",
      country: values.country.label ? values.country.label : "",
    };
    this.props
      .mutate({ variables })
      .then(({ data }) => {
        if (data.createClient.ok && !data.createClient.errors) {
          this.handleSuccess(`${data.createClient.client.firstName} ${data.createClient.client.lastName}`, data.createClient.client.id);
          return;
        }

        this.handleError(data.createClient.errors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleReset() {
    this.setState({ reset: !this.state.reset });
  }

  handleSuccess(clientName, clientId) {
    notification.success({
      message: "Client Created Successfully",
      //description: `${clientName} has been successfully created.`,
    });

    if (this.state.reset === false) {
      this.setState({ redirectTo: `/${this.props.currentPractice}/clients/view/${clientId}` });
    } else {
      this.setState({
        key: this.state.key + 1,
        loading: false,
      });
    }
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: "Error",
        description: err.message,
      });
    });
  }

  render() {
    const userContext = this.context;
    const { account } = userContext;

    return (
      <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper">
          <TitleBar title="Add Client" subTitle={account && (account.accountType == 1 ? account.portalname : `${account.practicename} | ${account.portalname}`)} />
          {/* <Titlebar currentPractice={this.props.currentPractice} displayText="Add Client" /> */}
        </div>
        <LayoutContentWrapper>
          <LayoutContent>
            <ClientForm
              cancelUrl={this.state.cancelUrl}
              createAnother={this.state.reset}
              currentPractice={this.props.currentPractice}
              gblUserRole={this.props.gblUserRole}
              gblUserIsGlobalAdmin={this.props.gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={this.props.gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={this.props.gblUserIsPortalAdmin}
              key={this.state.key}
              loading={this.state.loading}
              onCreateAnother={this.handleReset}
              onSubmit={this.handleSubmit}
              currentPortalId={this.props.currentPortalId}
              me={this.props.me}
              {...this.props}
            />
            {this.state.redirectTo !== null && (
              <Redirect
                to={{
                  pathname: this.state.redirectTo,
                  state: { newClient: true },
                }}
              />
            )}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const addClientMutation = gql`
  mutation createClient(
    $address: String
    $advisorId: Int
    $city: String
    $email: String!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $practiceId: String
    $portalId: Int
    $state: String
    $zipCode: String
    $addClientToTeam: Boolean
    $country: String
    $phone_code: String
  ) {
    createClient(
      address: $address
      advisorId: $advisorId
      city: $city
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      practiceId: $practiceId
      portalId: $portalId
      state: $state
      zipCode: $zipCode
      addClientToTeam: $addClientToTeam
      country: $country
      phone_code: $phone_code
    ) {
      ok
      client {
        id
        firstName
        lastName
      }
      errors {
        message
        path
      }
    }
  }
`;

export default graphql(addClientMutation)(ClientAdd);
