import theme from "./defaultTheme";
import { palette } from "styled-theme";
const globalstyle = String.raw`
#root, body {
  // background: rgba(240,242,245,1)!important
}

.ant-notification,.ant-message{
  z-index:999999999 !important
}
.ant-dropdown{
  z-index: 1111 !important;
}
.ant-badge-count {
  background: rgba(253,82,82,1);
  font-weight: 600;
  top: -3px;
}
.ant-message{
  .ant-message-notice{
    .ant-message-notice-content{
      .ant-message-custom-content{
        display: flex;
        align-items: center;
        .anticon{
          top: -1px;
        }
      }
    }
  }
}  
    .notify-menu-main-wrapper{
      .user-notification-menu {
        padding:0 !important;
        max-height:500px;
        overflow-y:auto;
         .notify-dropdown{
           padding: 20px 16px;
         
         &:not(:last-child){
           border-bottom: 1px solid rgba(238,242,244,1);
         }
         &:last-child {
           font-size: 12px;
           //padding: 8px 0;
           text-align: center;
         }
         &:hover {
           background: none;
         }
         .menu-item-wrapper {
  
           display: flex;
           justify-content: space-between;
           width: 200px;
           a {
             color: ${theme.palette.text[1]};
             &:focus {
               text-decoration: none;
             }
           }
           .note {
             font-size: 12px;
           }
         }
         .menu-item-wrapper{
           flex-direction: column;
           justify-content: unset;
           .user-detail-header-bar{
             display: flex;
             justify-content: space-between;
             align-items: center;
             margin-bottom: 4px;
             a{
               text-transform:capitalize;
               h6{
                 font-family:${theme.fonts.primary};
                 font-size: 14px;
                 font-weight: 400;
                 color: #181818;
                 margin-bottom:0.6rem;
                 &:hover{
                   color: #006FBA;
                 }
               }
             }
             h6{
               text-decoration:underline;
               margin-bottom:0.6rem;
               font-size:14px;
               &:hover{
                 color:#006FBA;
               }
             }
             .notification-btns-wrapper{
               display: flex;
               align-items: center;
               flex: 1;
               justify-content: flex-end;
               margin-top: -10px;
               .ant-btn{
                 width: 15px;
                 height: 15px;
                 padding: 0;
                 min-width: auto;
                 border-radius: 100px;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 &.btnCancel{
                   background:#EA3E3E;
                   border-color:transparent;
                 }
                 &.btnSuccess{
                   background:#33923C;
                   border-color:transparent;
                 }
                   svg{
                     width: 9px;
                     height: 9px;
                     color:#fff;
                   }
               }
             }
           }
           .date-note-text{
             display: flex;
             align-items: center;
             h5{
               font-size: 12px;
               font-weight: 400;
               margin: 0;
               color: #006FBA;
               &.green-text{
                 color: #33923C;
               }
               &.bounce-mail {
                color: #ea3e3e;
              }
               span{
                 color: #181818;
               }
             }
           }
        }
       }
     }
     .notification-text{
       padding:15px;
       font-size:12px;
       line-height:15px;
       color:#181818;
       border-bottom: 1px solid rgba(238,242,244,1);
       cursor:pointer;
     }
     .view-text{
       padding:15px;
       background:${theme.palette.primary[12]};
       color:${theme.palette.base[0]};
       border-radius: 0 0 5px 5px;
       overflow:hidden;
       cursor:pointer;
     }
    }
  

  .emptyData{
    width:200px;
  }

.notify{
  padding:15px;
  height:unset;
}

.dropdown-download {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item-disabled {
      color: rgba(0,111,186,1);
      cursor: default;
      font-weight: 600;
      &:hover {
        background: none;
      }
    }
  }
}

.reminderBox{
  .ant-dropdown-menu{
    background:#f3f3f3;
    .ant-dropdown-menu-item{
      .ant-checkbox-wrapper{
        .ant-checkbox{
          &.ant-checkbox-checked{
            .ant-checkbox-inner{
              width:17px;
              height:17px;
              background:#006FBA;
              border:1px solid #006FBA;
            }
          }
          .ant-checkbox-inner{
            width:17px;
            height:17px;
            background:#ECECEC;
            border:2px solid #ECECEC;
          }
        }
        span{
          font-size:16px;
          font-weight:600;
          color:#181818;
        }
      }
    }
  }
}

.popoverModal{
  .popover-body{
    .tooltip-title{
      font-weight:600;
      color:#6C6C6C;
    }
    .input-password-feedback-signup{
      display:flex;
      align-items:center;
      &.error{
        i,span{
          color:#f5222d;
        }
      }
      &.success{
        i,span{
          color:#33923C;
        }
      }
      }
      span{
        margin-left:5px;
        &.errorTitle{
          color:#f5222d;
        }
      }
    }
  }
}

.ant-popover {
  width: 250px;
  .ant-popover-inner{
    .ant-popover-inner-content{
      .title-info{
        font-size:16px;
        font-weight:500;
        color:#181818;
        margin-bottom:10px;
      }
      .tooltip-title{
        font-weight:600;
        color:#6C6C6C;
      }
      .input-password-feedback-signup{
        display:flex;
        align-items:center;
        &.error{
          i,span{
            color:#f5222d;
          }
        }
        &.success{
          i,span{
            color:#33923C;
          }
        }
        }
        span{
          margin-left:5px;
          &.ant-avatar-string{
            margin-left:0;
          }
          &.errorTitle{
            color:#f5222d;
          }
        }
      }
    }
  }
  &.practiceAdminInfo{
    width:330px;
    .ant-popover-inner{
        padding:10px;
      .ant-popover-inner-content{
        p{
          max-width:250px;
          width:100%;
          margin:auto;
          text-align:center;
          font-size:14px;
          line-height:21px;
          color:#181818;
          margin-bottom:1.25rem;
        }
        .ant-btn{
          &.inviteBtn{
            max-width:150px;
            background:#006FBA;
            border-color:#006FBA;
            color:#fff;
            border-radius:4px;
            font-size:12px;
            font-weight:600;
            text-align:center;
            color:#fff;
            &.advisorBtn{
              background:transparent;
              color:#006FBA;
            }
            span{
              margin-left:0;
            }
          }
        }
      }
    }
  }

  &.clientOverview{
    width:264px;
    .ant-popover-inner{
      padding:10px;
      .ant-popover-inner-content{
        .popupTitle{
          text-align:center;
          font-size:16px;
          font-weight:600;
          line-height:24px;
          color:#6c6c6c;
        }
        .popup-blue{
          font-size:14px;
          font-weight:600;
          line-height:24px;
          color:#006fba;
          text-align:center;
      }
    }
  }


  &.practiceaddInfo{
    width:220px;
    .ant-popover-inner{
        padding:10px;
      .ant-popover-inner-content{
        p{
          max-width:200px;
          width:100%;
          margin:auto;
          text-align:center;
          font-size:14px;
          line-height:18px;
          color:#181818;
        }
      }
    }
  }

  &.orgInfo{
    width:264px;
    .ant-popover-inner{
        padding:14px;
      .ant-popover-inner-content{
        h5{
          font-size:14px;
          font-weight:600;
          line-height:24px;
          text-align:center;
          color:#6C6C6C;
        }
        p{
          max-width:220px;
          width:100%;
          margin:auto;
          font-size:14px;
          line-height:20px;
          color:#657A85;
        }
      }
    }
  }
}

.ant-back-top {
  right: 20px !important;
  bottom: 20px !important;
}

.menu-drawer {
  .ant-drawer-header {
    align-items: center;
    border-bottom: none;
    display: flex;
    height: 116px;
    padding: 12px 12px 12px 24px;
    .ant-drawer-title {
      color: ${theme.palette.text[1]};
      font-size: 32px;
      font-weight: 700;
      line-height: 28px;
    }
  }
  .ant-drawer-close-x {
    color: ${theme.palette.text[1]};
    font-size: 24px;
    font-weight: 700;
    height: 116px;
    line-height: 52px;
    width: 64px;
  }
}

.ant-drawer-body {
  .menu-trigger-wrapper {
    &.expanded {
      background: rgba(255,255,255,1);
      border-bottom-right-radius: 50%;
      border-top-right-radius: 50%;
      box-shadow: 5px 0px 8px rgba(0, 0, 0, 0.15);
      left: 256px;
      margin-left: -1px;
      position: absolute;
      top: 136px;
      width: 40px;
      z-index: 1001;
      i {
        color: rgba(101,122,133,1);
        cursor: pointer;
        padding: 13px 9px;
      }
    }
  }
}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  ol,
  ul,
  dl,
  li,
  input,
  input[type="number"],
  input[type="password"],
  input[type="text"],
  textarea,
  span,
  div,
  label,
  button,
  table,
  html,
  body {
    font-family: ${theme.fonts.primary};
    margin-bottom: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius:20px;
      border:3px solid #f1f1f1;
    }
  }
  .ant-form,
  .ant-form-item,
  .ant-badge-status-text,
  .ant-checkbox-wrapper {
    font-family: ${theme.fonts.primary};
  }

  a {
    color: rgba(0,111,186,1);
    &:hover,
    &:focus {
      color: rgba(0,111,186,1);
    }
  }

  ::selection {
    background: rgba(0,111,186,1);
  }

  ::placeholder {
    color: rgba(188,198,218,1);
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .caption,
  .display-1,
  .display-2,
  .display-3,
  .headline,
  .title {
    color: rgba(0,0,0,.65);
  }

  .caption {
    font-size: 12px;
    line-height: 20px;
  }

  .caption-error {
    display: block;
    font-size: 12px;
    line-height: 20px;
    color: ${theme.palette.error[0]};
  }

  .display-1 {
    font-size: 24px;
    line-height: 32px;
  }

  .display-2 {
    font-size: 34px;
    line-height: 40px;
  }

  .display-3 {
    font-size: 45px;
    line-height: 48px;
  }

  .headline {
    font-size: 20px;
    line-height: 30px;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
  }

  .ant-badge-status-default {
    background: ${theme.palette.base[5]};
  }

  .ant-drawer {
    &.dashboard-filter-sidebar {
      .ant-drawer-content-wrapper {
        top: 64px;
        border: 1px solid rgba(238,242,244,1);
        border-top: none;
        box-shadow: -5px 0px 5px -5px rgba(134,134,123,0.16);
        .ant-drawer-header {
          background: /*rgba(249,250,252,1)*/ rgba(240,242,245,1);
          border-bottom: 1px solid rgba(238,242,244,1);
        }
        .ant-drawer-content {
          background: /*rgba(249,250,252,1)*/ rgba(240,242,245,1);
        }
        .ant-drawer-body {
          padding-top: 8px;
          .ant-form-item {
            margin-bottom: 8px;
          }
          .ant-form-item-label {
            label {
              &:after {
                display: none;
              }
            }
          }
        }
        .ant-drawer-close {
          &:active,
          &:focus,
          &:hover {
            color: rgba(0,0,0,.45);
          }
          .ant-drawer-close-x {
            font-size: 14px;
            height: 55px;
            line-height: 55px;
            width: 55px;
          }
        }
      }
      .button-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        button {
          :first-child {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .ant-select-auto-complete {
    &.ant-select {
      .ant-input {
        &:focus,
        &:hover {
          border-color: ${theme.palette.primary[0]};
        }
      }

      .ant-input-affix-wrapper {
        .anticon-search {
          color: ${theme.palette.text[3]};
        }
      }
    }
  }

  .ant-pagination {

    .ant-pagination-item {

      :focus, :hover {
        border-color: rgba(0,111,186,1);
        a {
          color: rgba(0,111,186,1);
        }
      }

    }

    .ant-pagination-item-active {
      border-color: rgba(0,111,186,1);

      a {
        color: rgba(0,111,186,1);
      }

      &:hover,
      &:focus {
        border-color: rgba(0,111,186,1)

        a {
          &:hover,
          &:focus {
            color: rgba(0,111,186,1)
          }
        }
      }
    }
  }

  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: rgba(0,111,186,1);
    color: rgba(0,111,186,1);
  }

  .anticon{
      &.anticon-left{
        display:inline-flex !important;
        align-items:center !important;
      }
      &.anticon-right{
        display:inline-flex !important;
        align-items:center !important;
      }  
  }

  .ant-select-selection {
    &:active,
    &:focus,
    &:hover {
      border-color: rgba(0,111,186,1);
    }
  }

  .ant-select-dropdown-menu-item {
    &:active,
    &:focus,
    &:hover {
      background: rgba(101,122,133,0.05);
    }
  }

  .ant-dropdown-menu-item-selected {
    background: rgba(101,122,133,0.05);
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
  }

  .ant-select-dropdown-menu-item-active,
  .ant-select-dropdown-menu-item-selected {
    background: rgba(101,122,133,0.05);
  }

  .ant-pagination-options-quick-jumper input {
    &:active,
    &:focus,
    &:hover {
      border-color: rgba(0,111,186,1);
    }
  }

  .ant-radio-button-wrapper-checked {
    background: transparent;
    border-color: ${theme.palette.primary[0]};
    color: ${theme.palette.primary[0]};

    &:first-child {
      border-color: ${theme.palette.primary[0]};
    }
  }

  .ant-radio-button-wrapper {
    &:hover,
    &:focus {
      color: ${theme.palette.primary[0]};
    }
  }

  .ant-form-explain {
    color: ${theme.palette.error[0]};
  }

  &.uni-modal {
    .ant-modal-close-x {
      font-size: 14px;
      font-weight: 400;
    }

    &.uni-widget-modal {
      .ant-modal-body {
        background: ${theme.palette.base[4]};

        .widget-list-card {
          background: ${theme.palette.base[0]};
          border-radius: 2px;
          box-shadow: ${theme.palette.shadow[3]};
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-bottom: 16px;
          padding: 16px 24px;

          &:last-of-type {
            margin-bottom: 0;
          }

          .widget-list-name {
            display: flex;
            flex-basis: 100%;
            flex-direction: column;

            > div {
              align-items: flex-end;
              color: ${theme.palette.text[0]};
              display: flex;
              flex-direction: row;
              font-size: 14px;
              line-height: 22px;

              span {
                color: ${theme.palette.text[2]};
                font-size: 12px;
                line-height: 20px;
                margin-left: 8px;
              }
            }
          }

          .widget-list-details {
            display: flex;
            flex-basis: 100%;
            flex-direction: column;

            > div {
              align-items: flex-end;
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              .list-text {
                color: ${theme.palette.text[1]};
                font-size: 12px;
                line-height: 20px;
                margin-right: 16px;
                max-width: 75%;
              }
            }
          }

          &.disabled {
            background: ${theme.palette.base[4]};

            .widget-list-name {
              > div {
                color: ${theme.palette.text[2]};
              }
            }

            .widget-list-details {
              > div {
                .list-text {
                  color: ${theme.palette.text[2]};
                }
              }
            }

          }

        }
      }
    }
  }

  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      background-color: ${theme.palette.base[3]} !important;
    }
  }

  .ant-checkbox-input {
    &:focus {
      + {
        .ant-checkbox-inner {
          border-color: ${theme.palette.primary[0]};
        }
      }
    }
  }

  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: rgba(0,111,186,1);
      }
    }
  }

  .ant-checkbox-input {
    &:focus {
      + {
        .ant-checkbox-inner {
          border-color: rgba(0,111,186,1);
        }
      }
    }
  }

  .ant-checkbox-wrapper {
    &:hover {
      .ant-checkbox-inner {
        border-color: rgba(0,111,186,1);
      }
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: rgba(0,111,186,1);
      border-color: rgba(0,111,186,1);

      &:after {
        border: 1px solid rgba(255,255,255,1);
        border-top: 0;
        border-left: 0;
      }
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      &:after {
        background-color: #ECECEC !important;
      }
    }
  }

  .ant-input {
    font-family: ${theme.fonts.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ant-input-affix-wrapper {
    &:hover {
      .ant-input {
        &:not(.ant-input-disabled) {
          border-color: ${theme.palette.primary[0]};
        }
      }
    }
  }

  // &.ant-select {
  //   .ant-select-selection {
  //     &:hover {
  //       border-color: ${theme.palette.primary[0]};
  //     }
  //   }

  //   &.ant-select-focused {
  //     .ant-select-selection {
  //       &:focus,
  //       &:active {
  //         border-color: ${theme.palette.primary[0]};
  //       }
  //     }
  //   }

  //   &.ant-select-open {
  //     .ant-select-selection {
  //       border-color: ${theme.palette.primary[0]};
  //       box-shadow: 0 0 0 2px ${theme.palette.shadow[4]};
  //     }
  //   }

  //   &.ant-select-disabled {
  //     .ant-select-selection {
  //       &:hover,
  //       &:focus,
  //       &:active {
  //         border-color: ${theme.palette.primary[0]};
  //       }
  //     }

  //     span.ant-badge {
  //       opacity: .4;
  //     }
  //   }
  // }

  .ant-select-focused {
    .ant-select-selection {
      border-color: rgba(0,111,186,1);
    }
  }
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
  .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
    color: rgba(0,111,186,1);
  }

  // .ant-select-selection {
  //   &:focus {
  //     border-color: ${theme.palette.primary[0]};
  //     box-shadow: 0 0 0 2px ${theme.palette.shadow[4]};
  //   }
  //   &:active {
  //     border-color: ${theme.palette.primary[0]};
  //     box-shadow: 0 0 0 2px ${theme.palette.shadow[4]};
  //   }
  // }

  // .ant-select-dropdown-menu-item {
  //   &:hover {
  //     background-color: ${theme.palette.primary[10]};
  //   }
  // }

  // .ant-select-dropdown-menu-item-active {
  //   background-color: ${theme.palette.primary[10]};
  // }

  .ant-dropdown-menu-item {
    &:hover {
      background-color: ${theme.palette.primary[10]};
    }
    a {
      &:hover {
        color: ${theme.palette.text[1]};
      }
      &:focus {
        color: ${theme.palette.text[1]};
      }
    }

  }
  .ant-dropdown-menu-submenu-title {
    &:hover {
      background-color: ${theme.palette.primary[10]};
    }
    a {
      &:hover {
        color: ${theme.palette.text[1]};
      }
      &:focus {
        color: ${theme.palette.text[1]};
      }
    }

  }



  .ant-tag {
    font-family: ${theme.fonts.primary};
  }

  .has-error {
    .ant-input {
      border-color: ${theme.palette.error[0]};

      &:hover {
        border-color: ${theme.palette.error[0]};
      }
    }

    .ant-form-explain {
      border-color: ${theme.palette.error[0]};
    }

    .ant-form-split {
      border-color: ${theme.palette.error[0]};
    }
  }

  .uni-confirm-delete {
    i.anticon.anticon-close-circle {
      color: ${theme.palette.error[0]};
    }
  }

  .uni-confirm-modal {
    .ant-confirm-content {
      margin-left: 38px;
      margin-top: 12px;

      .uni-yes-confirm {
        margin: 24px 0 8px 0;
      }

      .ant-form-item {
        margin: 0;
      }
    }

    .ant-modal-footer {
      border-top: none;
      padding: 12px 24px 24px;
    }
  }

  @media only screen and (max-width: 767px) {
    .uni-body {
      height: 100%;
      overflow: scroll;
      position: fixed;
      width: 100%;
    }
  }
  .unitifi-select-container{
    &.selectBox{
      .unitifi-react-select__control{
        padding:0 10px;
        background: #F4F4F4;
        min-height: 38px;
        height: 38px;
        outline: none;
        box-shadow: none;
        border-color: #F4F4F4;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        &:hover{
          border-color: #F4F4F4;
        }
        &:focus{
          border-color: #006FBA;
        }
        .unitifi-react-select__value-container{
          font-weight: 400;
          font-size: 16px;
          line-height: normal;
          color: #4E4E4E;
          padding: 0;
          .unitifi-react-select__placeholder{
            font-weight: 400;
            font-size: 16px;
            line-height: normal;
            color: #4E4E4E;
            margin: 0;
            padding: 0;
          }
          .unitifi-react-select__input-container{
            font-weight: 400;
            font-size: 16px;
            line-height: normal;
            color: #4E4E4E;
            margin: 0;
            padding: 0;
          }
        }
        .unitifi-react-select__indicators{
          .unitifi-react-select__indicator-separator{
            display: none;
          }
          .unitifi-react-select__indicator{
            padding-right: 12px;
          }
        }
      }
    }

    &.phoneInputBox{
      .unitifi-react-select__control{
        .unitifi-react-select__indicators{
          .unitifi-react-select__indicator{
            svg{
              color:#006FBA;
            }
          }
        }
      }
    }

    .unitifi-react-select__control{
      padding: 10px;
      background: #F4F4F4;
      min-height: 55px;
      height: auto;
      outline: none;
      box-shadow: none;
      border-color: #F4F4F4;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      &:hover{
        border-color: #F4F4F4;
      }
      &:focus{
        border-color: #006FBA;
      }
      .unitifi-react-select__value-container{
        font-weight: 400;
        font-size: 14px;
        line-height: normal;
        color: #4E4E4E;
        padding: 0;
        .unitifi-react-select__single-value{
          margin-top: 2px;
          font-size: 14px;
        }
        .unitifi-react-select__placeholder{
          font-weight: 400;
          font-size: 16px;
          line-height: normal;
          color: #4E4E4E;
          margin: 0;
          padding: 0;
        }
        .unitifi-react-select__input-container{
          font-weight: 400;
          font-size: 14px;
          line-height: normal;
          color: #4E4E4E;
          margin: 0;
          padding: 0;
        }
      }
      .unitifi-react-select__indicators{
        .unitifi-react-select__indicator-separator{
          display: none;
        }
        .unitifi-react-select__indicator{
          padding-right: 12px;
        }
      }
    }
    .unitifi-react-select__menu{
       z-index:2;
      .unitifi-react-select__menu-list{
        padding: 0;
        .unitifi-react-select__option{
          font-size: 14px;
          font-weight: 600;
          &.unitifi-react-select__option--is-selected{
            background: white;
            color: black;
          }
          &.unitifi-react-select__option--is-focused{
            background: #026fba;
            color: white;
            span{
              color:white;
            }
          }
        }
      }
    }
  }
  .bs-popover-top{
    background: #FFFFFF;
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.25);
    border: none !important;
    .popover-header{
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      background: transparent;
      color: #6C6C6C;
      padding: 15px 12px 0 12px;
      border: none;
    }
    .popover-body{
      ul{
        padding-left: 22px;
        margin: 0;
        li{
          font-size: 14px;
          font-weight: 600;
          color: #6C6C6C;
          margin-bottom: 8px;
          span{
            color: #006FBA;
          }
        }
      }
    }
  }
  .unitifi-modal-wrapper{
    .unitifi-phone-input-box-wrapper{
        background: #f1f1f1;
        border-radius: 4px;
        .unitifi-select-container  {
          border-radius: 0;
          background: transparent;
          margin-right: 0px !important;
          min-width: 95px;
          width: auto !important;
          .unitifi-react-select__control {
            border-radius: 0;
            background: transparent !important;
            padding-right: 0 !important;
            width: unset !important;
            margin-right: 0 !important;
          }
          *{
            font-size: 14px !important;
          }
        }
        .input-phone__input{
          input{
            background: transparent;
            border: none !important;
            border-radius: 0 !important;
            box-shadow: none !important;
            outline: none !important;
            width: 100% !important;
            margin: 0;
            &:focus {
              border: none !important;
            }
          }
        }
      
    }
    &.contactForm{
      .modal-body{
        .public-form{
          .ant-form{
            .ant-form-item-control{
              .ant-form-item-children{
                .ant-input-group{
                  .unitifi-select-container{
                    .unitifi-react-select__control{
                      padding:0 10px;
                      min-height:45px !important;
                      height:45px !important;
                      width:95px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.notifyBox{
      .modal-dialog{
        .modal-content{
          .modal-header{
            padding:15px 15px 0 !important;
          }
          .modal-body{
            padding-top:5px !important;
          }
        }
      }
    }
    &.notify-modal{
      .modal-dialog{
        .modal-content{
          //width:629px;
          max-width:100%;
          background:${theme.palette.base[3]};
          .modal-header{
            padding:20px 20px;
            .btn-close{
              font-size: 10px;
              outline: none;
              box-shadow: none;
              color: ${theme.palette.base[8]};
            }
            .modal-title{
              font-family:${theme.fonts.primary};
              font-size:14px;
              font-weight:600;
              line-height:17px;
              color: ${theme.palette.base[8]};
            }
          }
          .modal-body{
            @media only screen and (max-width: 991px) {
              display:flex;
              overflow-y:hidden;
            }
            .modal-tab-wrapper{
              //padding:0 20px;
              .ant-tabs{
                width:100%;
                .ant-tabs-bar{
                  display:flex;
                  align-items:center;
                  justify-content:center;
                  background:#fff;
                  border-radius:4px;
                  padding: 0 20px;
                  @media only screen and (max-width: 480px) {
                    padding: 0 5px;
                  }
                  .ant-tabs-nav-container{
                    .ant-tabs-nav-wrap{
                      .ant-tabs-tab{
                        font-family:${theme.fonts.primary};
                        font-weight:600;
                        line-height:17px;
                        color: ${theme.palette.base[8]};
                        &.ant-tabs-tab-active{
                          background: #006FBA;
                          border-radius:70px;
                          color:#fff;
                          padding:5px 14px;
                          width:auto;
                        }
                      }
                      .ant-tabs-ink-bar{
                        display:none !important;
                      }
                    }
                  }
                }
                .ant-tabs-content{
                  .ant-tabs-tabpane{
                    background: #fff;
                    border-radius:4px;
                    padding:15px 30px;
                    height:600px;
                    overflow:auto;
                    .ant-list{
                      .ant-list-item-meta-title{
                        font-size:14px;
                        font-weight:400;
                        line-height:17px;
                        color: ${theme.palette.base[8]};
                        text-transform: capitalize;
                      }
                      .ant-list-item-meta-description{
                        font-size:12px;
                        font-weight:400;
                        line-height:15px;
                        color: ${theme.palette.primary[12]};
                        span{
                          color: ${theme.palette.base[8]};
                        }
                      }
                      .ant-list-items{
                        .ant-list-item{
                          .ant-btn{
                            &.btnCancel{
                              background:#EA3E3E;
                              border-color:transparent;
                            }
                            &.btnSuccess{
                              background:#33923C;
                              border-color:transparent;
                            }
                            &.ant-btn-circle{
                              min-width:15px;
                              height:15px;
                              display:flex;
                              align-items:center;
                              justify-content:center;
                            }
                            svg{
                              width:9px;
                              height:9px;
                              color:#fff;
                            }
                          }
                        }
                        &:last-child{
                          border-bottom: 1px solid #e8e8e8;
                        }
                      }
                    }
                  }
                }
              }
            }

            .client-modal-data{
              max-width:991px;
              width:100%;
              flex:0 0 991px;
              .ant-tabs{
                .ant-tabs-bar{
                  background:#fff;
                  border-radius:4px;
                  padding: 5px 20px;
                  max-width:558px;
                  width:100%;
                  .ant-tabs-nav-container{
                    .ant-tabs-nav-wrap{
                      .ant-tabs-tab{
                        font-family:${theme.fonts.primary};
                        font-weight:600;
                        line-height:17px;
                        color: ${theme.palette.base[8]};
                        &.ant-tabs-tab-active{
                          background: #006FBA;
                          border-radius:70px;
                          color:#fff;
                          padding:10px 14px;
                          width:auto;
                        }
                      }
                      .ant-tabs-ink-bar{
                        display:none !important;
                      }
                    }
                  }
                }  
                .ant-tabs-content{
                  .ant-tabs-tabpane{
                    // .tab-data-content{
                    //    background:#fff;
                    //    padding:15px;
                    //    border-radius:4px;
                    //    margin-bottom:12px;
                    //   .time-detail{
                    //     display:flex;
                    //     justify-content:flex-end;
                    //     color:#A2A2A3;
                    //   }
                    //   .view-data{
                    //       a{
                    //         display:flex;
                    //         justify-content:flex-end;
                    //         font-size:18px;
                    //         text-decoration:none;
                    //         color:#A2A2A3;
                    //       }
                    //     }
                    //   .client-title{
                    //     font-size:18px;
                    //     font-weight:600;
                    //     line-height:20px;
                    //     color:#181818;
                    //     text-transform:capitalize;
                    //     &.style-data{
                    //       font-weight:400;
                    //     }
                    //     &.retirement-data{
                    //       font-size: 16px;
                    //       font-weight:400;
                    //     }
                    //   }
                    //   .score-data{
                    //     font-size:24px;
                    //     font-weight:600;
                    //     line-height:30px;
                    //     color: #006FBA;
                    //   }
                    // }
                    .unitify-vehicle-table-data{
                      background:#fff;
                      padding:20px;
                      >div{
                        a{
                          .searchIcon{
                            width:13px;
                            height:13px;
                            position:absolute;
                            z-index:1;
                            right:55px;
                            top:90px;
                          }
                        }

                        a{
                          .clearIcon{
                            width:13px;
                            height:13px;
                            position:absolute;
                            z-index:1;
                            right:55px;
                            top:90px;
                          }
                        }

                        input{
                          position:relative;
                          margin-right:10px;
                          margin-bottom:10px;
                          border-radius:14px;
                          padding:0 10px;
                          background:#ECECEC;
                          border:1px solid #ECECEC;
                          min-width:582px;
                        }
                      }
                      .ant-table-wrapper{
                        table{
                          thead{
                            th{
                              background:none;
                              font-weight:600;
                              line-height:17px;
                              &.action-column-width{
                                width:200px;
                              }
                              &.view-column{
                                width:50px;
                              }  
                            }
                          }
                          tbody{
                            tr{
                              td{
                                &.action-column-width{
                                  width:200px;
                                }
                                &.view-column{
                                  width:50px;
                                  a{
                                    color:#181818;
                                    i {
                                      cursor: pointer;
                                      width: 18px;
                                      height: 14px;
                                      display:block;
                                    }
                                  }
                                }
                                &:last-child{
                                  p{
                                    margin-bottom:0;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.notify-modal-height{
      padding-left:0 !imporatnt;
      .modal-dialog{
        //max-width:520px;
        //width:100%;
        //margin:auto;
        @media(max-width:575px){
          padding:0 10px;
        }
        .modal-content{
            overflow:hidden;
           .modal-body{
            .modal-tab-wrapper{
              .ant-tabs{
                .ant-tabs-content{
                  .ant-tabs-tabpane{
                    max-height:450px !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.vehicle-data{
      .modal-dialog{
        max-width:980px;
      }
    }

    // &.join-company-modal
    // {
    //    .modal-dialog{
    //     .modal-content{
    //       height:120px;
    //       .modal-body{
    //         h4{
    //           font-size:20px;
    //           font-weight:500;
    //           color:#181818;
    //           line-height:18px;
    //           display:flex;
    //           align-items:center;
    //           justify-content:center;
    //           height:100%;
    //         }
    //       }
    //     }
    //    }
    // }

    &.modalAssessment{
      @media only screen and (max-width: 767px) {
        padding-left:0 !important;
      }
      .modal-dialog{
        .modal-content{
          height:auto;
          //overflow-y:scroll;
          .modal-header{
            .selectAssessment{
              background:#006FBA;
              border-radius:4px;
              text-align:center;
              min-width:128px;
              height:28px;
              line-height:27px;
              font-size:16px;
              font-weight:600;
              color:#fff;
              cursor:pointer;
            }
          }
          .modal-body{
            padding:30px;
            @media only screen and (max-width: 400px) {
              padding:10px;
            }
            .unitifi-invite-practice-modal-wrapper{
              margin-bottom:25px;
              .modalHeadingTitle{
                font-size:24px;
                color:#181818;
              }
              .ant-dropdown-open{
                .anticon{
                  transform:rotate(180deg);
                }
              }
              .reminderDropdown{
                  display:flex;
                  align-items:center;
                  background:#006FBA;
                  border-color:#006FBA;
                  height:35px;
                  color:#fff;
                  font-size:16px;
                  font-weight:600;
                  .anticon{
                    font-size:13px;
                  }
              }
              .clientSearchInput{
                padding:0 17px;
                width:100%;
                height:45px;
                background:#f4f4f4;
                border-radius:4px;
                border:none;
                box-shadow:none;
                ::placeholder {
                  color: #4e4e4e;
                  opacity: 0.6;
                }
              }
              .tagboxWrapper{
                // height:70px;
                // overflow-y:auto;
                // overflow-x:hidden;
                margin-bottom:10px;
                ul{
                  display:flex;
                  flex-wrap:wrap;
                  .tag{
                    border:1px solid #006FBA;
                    border-radius:50px;
                    padding:2px 10px;
                    display:flex;
                    align-items:center;
                    margin:5px;
                    &:first-child{
                      margin-left:0;
                    }
                    .tag-title{
                      display:block;
                      font-size:14px;
                      font-weight:600;
                      line-height:14px;
                      color:#006FBA;
                    }
                    .tag-close-icon{
                      cursor:pointer;
                      margin-bottom:4px;
                      margin-left:10px;
                    }
                  }
                }
              }
              .topBtnWrapper{
                  display: flex;
                  justify-content: flex-end;
                  padding-bottom: 17px;
                  .unitifi-login-btn{
                    min-width: 150px;
                    padding: 9px;
                    height: auto;
                    margin-left: 15px;
                    &.unitifi-cancel-btn{
                      background: transparent;
                      border-color:#026fba;
                      span{
                        color: #026fba;
                      }
                    }
                  }
                }   
            }
            .ant-table-wrapper{
              &.assessmentTblWrapper{
                &.clientCompare{
                  .ant-table{
                    .ant-table-content{
                      .ant-table-header{
                        table{
                          thead{
                            tr{
                              th{
                                &:first-child{
                                  .ant-table-header-column{
                                    .ant-table-column-title{
                                      .ant-table-selection{
                                        .ant-checkbox-wrapper{
                                          display:none;
                                        }
                                      }
                                    }
                                  }
                                }           
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }             
              
                .ant-table{
                  .ant-table-content{
                    .ant-table-header{
                      table{
                        thead{
                          tr{
                            th{
                              background:#f4f4f4;
                              font-size:16px;
                              font-weight:600;
                              color:rgba(24,24,24,0.9);
                              line-height:32px;
                              text-transform:uppercase;
                              &:first-child{
                                .ant-table-header-column{
                                  .ant-table-column-title{
                                    .ant-table-selection{
                                      .ant-checkbox-wrapper{
                                        .ant-checkbox{
                                          &.ant-checkbox-checked{
                                            .ant-checkbox-inner{
                                              width:17px;
                                              height:17px;
                                              background:#006FBA;
                                              border:1px solid #006FBA;
                                            }
                                          }
                                          .ant-checkbox-inner{
                                            width:17px;
                                            height:17px;
                                            background:#ECECEC;
                                            border:1px solid #ECECEC;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              &.tblNameTitle{
                                width:150px;
                              }
                              &.tblDayOld{
                                width:110px;
                              }
                            }
                          }
                        }
                      }
                    }
                    .ant-table-body{
                      //height:450px;
                      table{
                        tbody{
                          tr{
                            td{
                              background:#f4f4f4;
                              &:first-child{
                                .ant-checkbox-wrapper{
                                  .ant-checkbox{
                                    &.ant-checkbox-checked{
                                      .ant-checkbox-inner{
                                        width:17px;
                                        height:17px;
                                        background:#006FBA;
                                        border:1px solid #006FBA;
                                      }
                                    }
                                    .ant-checkbox-inner{
                                      width:17px;
                                      height:17px;
                                      background:#ECECEC;
                                      border:1px solid #ECECEC;
                                    }
                                  }
                                }
                              }
                              &.tblNameTitle{
                                font-size:16px;
                                font-weight:600;
                                color:rgba(0,111,186,0.9);
                                line-height:32px;
                                width:150px;
                              }
                              &.tblEmailTitle{
                                font-size:16px;
                                font-weight:600;
                                color:rgba(78,78,78,0.9);
                                line-height:32px;
                                width:250px;
                              }
                              &.tblDayOld{
                                font-size:16px;
                                font-weight:600;
                                color:rgba(150,150,150,0.9);
                                line-height:32px;
                                width:110px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .modal-footer-bnts-wrapper{
              .ant-btn{
                span{
                  font-size:16px;
                  font-weight:600;
                }
              }
            }
          }
          .modal-footer{
            border-top:0;
            padding:20px 40px;
            .modal-footer-bnts-wrapper{
              position:absolute;
              bottom:0;
            }
          }
        }
      }
    }

    &.request-modal-wrapper
    {
       .modal-dialog{
         .modal-content{
          padding:30px 60px;
          .modal-header{
            .modal-title{
              margin-left:38px;
              h4{
                font-size:22px;
                font-weight:400;
                color:#181818;
                line-height:32px;
                letter-spacing:1px;
                text-align:center;
              }
            }
            .btn-close{
              position:absolute;
              top:12px;
              right:12px;
            }
          }
          .modal-body{
            p{
              max-width:340px;
              width:100%;
              text-align:center;
              font-size:16px;
              line-height:30px;
              letter-spacing:1px;
              color:#006FBA;
              margin-bottom:25px;
            }
            .modal-btn-wrapper{
              display:flex;
              justify-content:center;
              .exploreBtn{
                max-width:196px;
                width:100%;
                background:rgba(0, 111, 186, 0.85);
                border-radius:5px;
                color:#fff;
                border:0;
                font-size:16px;
                line-height:23px;
                padding:14px;
              }
            }
          }
        }
       }
    }


    &.filter-modal{
      .modal-dialog{
        max-width:365px;
        width:100%;
        .modal-content{
          border-radius:0;
          height:auto;
          .modal-header{
            .btn-close{
              font-size: 10px;
              outline: none;
              box-shadow: none;
              color: ${theme.palette.base[8]};
            }
            .modal-title{
              font-size:14px;
              font-weight:600;
              line-height:17px;
            }
          }
          .modal-body{
            .ant-radio-group{
              span{
                font-weight:400;
                line-height:17px;
                color:#181818;
              }
            }
            .modal-btn-wrapper{
              display:flex;
              justify-content:space-between;
              margin-top:20px;
              .clearBtn{
                background:none;
                border:0;
                color:#A2A2A3;
                text-decoration:underline;
              }
              .filterBtn{
                background:rgba(0, 111, 186, 0.85);
                border-radius:70px;
                color:#fff;
                border:0;
                font-size:14px;
                padding:0 14px;
              }
            }
          }
        }
      }
    }

    &.company-modal{
      .modal-dialog{
        max-width:441px;
        width:100%;
        .modal-content{
          height:240px;
          .modal-header{
            .btn-close{
              font-size: 10px;
              outline: none;
              box-shadow: none;
              color: ${theme.palette.base[8]};
            }
          }
          .modal-body{
            display:flex;
            flex-direction:column;
            justify-content:center;
            .cmn-modal-text{
              font-size:20px;
              font-weight:400
              line-height:32px;
              letter-spacing:1px;
              color: ${theme.palette.base[8]};
              text-align:center;
              margin-bottom:34px;
            }
          }
        }
      }
    }

    &.client-modal{
      .modal-dialog{
        max-width:512px;
        width:100%;
        .modal-content{
          padding:24px;
          height:auto;
          .modal-header{
             align-items:flex-start;
             padding:0;
             .modal-title{
                margin-bottom:25px;
             }
            .btn-close{
              font-size: 10px;
              outline: none;
              box-shadow: none;
              color: ${theme.palette.base[8]};
            }
          }
          .modal-body{
            padding:0;
            .unitifi-upload-content-wrapper{
              .unitifi-upload-text{
                font-size:14px;
              }
            }
          }
        }
      }
    }

    &.calendar-modal{
      .modal-dialog{
        max-width:948px;
        .modal-content{
          .modal-header{
            .modal-title{
              font-size:18px;
              font-weight:600;
              line-height:20px;
              color: ${theme.palette.base[8]};
            }
          }
          .modal-body{
            .rdrDateRangePickerWrapper{
              width:100%;
              .rdrDefinedRangesWrapper{
                @media only screen and (max-width: 991px) {
                  width:150px;
                }
                .rdrStaticRange{
                  .rdrStaticRangeLabel{
                    font-size:18px;
                    font-weight:600;
                    @media only screen and (max-width: 991px) {
                      font-size:14px;
                      padding:10px 5px;
                    }
                    &:hover{
                      background:rgba(0,111,186,0.7);
                      color: #fff;
                      border-radius:10px;
                    }
                  }
                  &.rdrStaticRangeSelected{
                    background:#006FBA;
                    color:#fff !important;
                    font-size:18px;
                    font-weight:600;
                    border-radius:10px;
                  }
                }
                
              }
              .rdrCalendarWrapper{
                @media only screen and (max-width: 480px) {
                  overflow-y:hidden;
                }
                .rdrMonths{
                  @media only screen and (max-width: 767px) {
                    flex-direction:column;
                  }
                  .rdrWeekDays{
                    .rdrWeekDay{
                      font-weight:600;
                    }
                  }
                  .rdrDays{
                    .rdrDayNumber{
                      font-weight:600;
                    }
                  }
                }
                .rdrMonthAndYearWrapper{
                  .rdrMonthAndYearPickers{
                    .rdrMonthPicker{
                      select{
                        font-weight:600;
                      }
                    }
                    .rdrYearPicker{
                      select{
                        font-weight:600;
                      }
                    }
                  }
                }
              }
            }
          }
        }  
      }
    }

    &.filterData-modal{
      .modal-dialog{
        max-width:440px;
        width:100%;
        margin:auto;
        .modal-content{
          margin:20px;
          .modal-header{
            padding:20px 35px 0 35px;
            .modal-title{
              font-size:24px;
              font-weight:400;
              line-height:30px;
              //letter-spacing:-2%;
              color:#181818;
            }
          }
          .modal-body{
            padding:25px 35px;
            .ant-form{
              .ant-form-item-label{
                label{
                  font-size:16px;
                  font-weight:600;
                  line-height:20px;
                  color:#181818;
                }
              }
              .ant-form-item-control-wrapper{
                .ant-form-item-control{
                  .ant-form-item-children{

                    .modal-filter-multi-select{
                      .unitifi-react-select__control{
                            background:#F4F4F4;
                            border-color:#F4F4F4;
                            box-shadow:none;
                            //min-height:36px;
                           //height:auto;
                            .unitifi-react-select__value-container{
                              .unitifi-react-select__input-container{
                                .unitifi-react-select__input{
                                  min-height:30px;
                                }
                              }
                            }
                         }
                         .unitifi-react-select__indicators{
                              padding:0 10px;
                            .unitifi-react-select__indicator{
                              svg{
                                color:#006FBA;
                              }
                            }
                           .unitifi-react-select__indicator-separator{
                               display:none;
                           }
                         }
                     }


                    .unitifi-select-container{
                      .unitifi-react-select__control{
                        padding: 0 10px;
                        min-height:unset;
                        height:unset;
                        .unitifi-react-select__value-container{
                          height:36px;
                          .unitifi-react-select__single-value{
                            font-size:16px;
                            height:30px;
                          }
                          .unitifi-react-select__placeholder{
                            font-size:16px;
                            color:#969696;
                            height:30px;
                          }
                        }
                        .unitifi-react-select__indicators{
                          height:36px;
                          .unitifi-react-select__indicator{
                            svg{
                              color:#006FBA;
                            }
                          }
                        }
                      }
                      // &.modal-filter-multi-select{
                      //   .unitifi-react-select__control{
                      //     .unitifi-react-select__value-container{
                      //       min-height:36px;
                      //       height: auto;
                      //     }
                      //   }
                      // }
                    }
                  }
                }
              }
              .filteText{
                a{
                  font-size:12px;
                  font-weight:400;
                  line-height:15px;
                  color:#EA3E3E;
                  text-decoration:none;
                }
              }
            }
          }
        }
      }  
    }

    .modal-dialog{
      .modal-content{
        .modal-header{
          border: none;
          padding: 20px;
          padding-bottom: 0;
          .btn-close{
            font-size: 10px;
            outline: none;
            box-shadow: none;
          }
        }
        .modal-body{
          @media(max-width:420px){
            padding:8px;
          }

          .unitifi-invite-practice-modal-wrapper{
            margin-bottom: 50px;
            min-height: 70px;
            &.inviteModalBox{
              margin-bottom: 0;
              min-height:0;
            }
            &.section_hide{
              display:none;
            }
            &.section_show{
              display:block;
            }
            &.unitify-invite-client-modal{
              margin-bottom:20px;
              min-height:0;
              .tags-input{
                margin-bottom:0;
                input{
                  min-height:47px;
                  
                }
              }
              .unitifi-upload-content-wrapper{
                .unitifi-upload-text{
                  font-size:15px;
                  line-height:17px;
                  color:#181818;
                }
                .unitifi-upload-link{
                  a{
                    color:#006FBA !important;
                    margin-right:10px !important;
                  }
                }
              }
            }
            .topBtnWrapper{
              display: flex;
              justify-content: flex-end;
              padding-bottom: 17px;
              .unitifi-login-btn{
                min-width: 150px;
                padding: 9px;
                height: auto;
                margin-left: 15px;
                &.unitifi-cancel-btn{
                  background: transparent;
                  color: #026fba;
                }
              }
            }  
            .tags-input{
              display: inline-flex;
              margin-bottom: 15px;
              flex-wrap: wrap;
              width: 100%;
              input{
                outline: none;
                box-shadow: none;
                background: #F4F4F4;
                border-radius: 4px;
                border-color: #F4F4F4;
                color:#4E4E4E;
              }
              ul{
                display: flex;
                margin: 0;
                padding: 0;
                margin-bottom: 10px;
                flex-wrap: wrap;
                li{
                  padding: 5px 10px;
                  border-radius: 1000px;
                  background: #026fba;
                  display: flex;
                  align-items: center;
                  margin-bottom: 8px;
                  margin-right: 8px;
                  .tag-title{
                    font-size: 14px;
                    font-weight: 400;
                    color: white;
                    margin-right: 10px;
                    display: inline-flex;
                  }
                  .tag-close-icon{
                    font-size: 14px;
                    font-weight: 400;
                    color: white;
                    display: inline-flex;
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                }
              }
            }
          }

          .unitifi-modal-header-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 18px;
            .unitifi-logo{
              width: 180px;
              display: inline-flex;
              height: auto;
              overflow: hidden;
              margin-bottom: 12px;
              img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
              }
            }
            .unitifi-modal-title-text{
              font-size: 32px;
              font-weight: 600;
              text-align: center;
              color: ${theme.palette.base[8]};
              line-height: normal;
              &.subtitle-text{
                font-size: 27px;
              }
            }
            .subtitle-pramary-text{
              font-size: 21px;
              font-weight: 600
              color: ${theme.palette.primary[12]};
              margin-bottom: 0;
              &.subtitle-info{
                font-size:20px;
                font-weight:400;
                color: ${theme.palette.base[8]};
                text-align:center;
              }
            }
            // .public-form{
            //   .ant-form{
            //     .input-phone__wrapper{
            //       .ant-input-group{
            //         &.unitifi-number-inputbox-wrapper{
            //           display:flex !important;
            //           align-items:center;
            //         }
            //       }
            //     }
            //   }
            // }
          }
          p{
            font-size:14px;
            color:#181818;
            opacity:0.79;
            margin-bottom:1.25rem;
          }
          .public-form-wrapper{
            .public-form{
              .unitifi-login-header-bar{
                margin-bottom: 25px;
                h5{
                  color: #181818;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 30px;
                  letter-spacing: -0.02em;
                }
              }
              .unitifi-number-inputbox-wrapper{
                display: flex;
                align-items: center;
                .unitifi-select-container{
                  .unitifi-select-container{
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    z-index: 2;
                  }
                  input{
                    padding-left: 95px;
                  }
                  .unitifi-react-select__control{
                    min-height: 45px;
                    height: 45px;
                    width: 105px;
                    border-radius: 4px 0px 0px 4px;
                    background: #F4F4F4;
                    border: none !important;
                    .unitifi-react-select__value-container{
                      font-weight: 400;
                      font-size: 16px;
                      line-height: normal;
                      padding: 0;
                      height: 45px;
                      margin-top: -11px;
                    }
                    .unitifi-react-select__indicators{
                      height: 25px;
                    }
                  }
                }
                }
                .input-phone__input{
                  flex: 1;
                  display: flex;
                  //margin-right: -7px;
                  //margin-left: 7px;
                  input{
                    width: 100%;
                    //margin: 0 7px;
                    &.phoneInputbox{
                      background:#f4f4f4;
                      font-size: 14px;
                      font-weight: 500;
                      color: #333333;
                    }
                  }
                }
              }
            }
            }
          }
          .ant-form{
            .ant-form-item{
              margin-bottom: 12px;
            }
          }
          .public-form{
            max-width: 700px;
            margin: auto;
          }
          .slider-bar-wrapper{
            padding: 0 21px 22px;
            .ant-input-number{
              margin: auto !important;
              text-align: center;
              display: flex;
              justify-content: center;
              width: 140px;
              background: #f8f8f8;
              height: 40px;
              border: none;
              .ant-input-number-handler-wrap{
                display: none;
              }
              .ant-input-number-input-wrap{
                input{
                  outline: none;
                  box-shadow: none;
                  font-size: 14px;
                  font-weight: 500;
                  height: 100%;
                  padding: 12px;
                  text-align: center;
                }
              }
            }
            .ant-slider{
              .ant-slider-handle{
                width: 21px;
                height: 21px;
                margin-top: -9px;
                border-color: ${theme.palette.primary[12]} !important;
              }
              .ant-slider-track{
                background-color: ${theme.palette.primary[12]} !important;
              }
            
              .ant-slider-mark{
                margin-top: 10px;
                span{
                  color: ${theme.palette.primary[12]} !important;
                  font-size: 14px;
                  font-weight: 600;
                }
              }
            }
            .ant-slider-step{
              .ant-slider-dot{
                display: none !important;
              }
            }
          }

          .modal-footer-bnts-wrapper{
            display: flex;
            justify-content: flex-end;
            padding-bottom: 17px;
            .unitifi-login-btn{
              min-width: 150px;
              padding: 9px;
              height: auto;
              margin-left: 15px;
              &.unitifi-cancel-btn{
                background: transparent;
                color: #026fba;
              }
            }

            .unitifi-invite-btn{
              min-width: 112px;
              padding: 7px;
              height:auto;
              margin-left: 15px;
              span{
                font-size:16px;
              }
              &.unitifi-cancel-btn{
                background: transparent;
                color: #026fba;
              }
            }

            .ant-btn{
              &.proceed-checkout-btn{
                min-width: 300px !important;
                font-weight: 500;
                margin-left: 0px;
                margin-top: 15px;
                height:40px;
              }
            }
            &.btn-center-wrapper{
              justify-content: center;
              .unitifi-login-btn{
                margin-left: 0px;
                border-radius:8px;
              }
            }
          }



          form{
            .ant-row{
              &.zipcode{
                  label{
                    max-width:130px;
                    width:100%;
                  }
              }
               &.expiry{
                  label{
                    min-height:46px;
                    height:auto;
                  }
              }    
              &.cvv{
                  label{
                    min-height:46px;
                    height:auto;
                  }         
            }
            label{
              font-size: 15px;
              font-weight: 400;
              max-width:156px;
            }
            input{
              min-height: 45px;
              box-shadow: none;
              outline: none;
              font-size: 14px;
              font-weight: 500;
            }
            .unitifi-pw-hide-show{
              position: relative;
              i{
                position: absolute;
                top: 0;
                bottom: 0;
                right: 12px;
                width: 21px;
                height: 21px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;
                flex-direction: column;
                svg{
                  cursor: pointer;
                  width: 100%;
                  height: 100%;
                  display: block;
                  &.show-pw-icon{
                    display: none;
                  }
                  &.hide-pw-icon{
                    display: none;
                  }
                }
              }
              &.hide-pw{
                i{
                  .hide-pw-icon{
                    display: block;
                  }
                }
              }
              &.show-pw{
                i{
                  .show-pw-icon{
                    display: block;
                  }
                }
              }
            }
            .phone-number-wrapper{
              width: 100%;
              .ant-form-item{
                width: 100%;
              }
              .mobile-number-input-wrapper{
                display: flex;
                position: relative;
                .unitifi-select-container{
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  z-index: 2;
                }
                input{
                  padding-left: 95px;
                }
                .unitifi-react-select__control{
                  min-height: 45px;
                  height: 45px;
                  width: 90px;
                  border-radius: 4px 0px 0px 4px;
                  background: transparent !important;
                  border: none !important;
                  .unitifi-react-select__value-container{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: normal;
                    padding: 0;
                    height: 45px;
                    margin-top: -11px;
                  }
                  .unitifi-react-select__indicators{
                    height: 25px;
                  }
                }
              }
            }
          }
          .unitifi-subscription-modal-wrapper{
            text-align: center;
            margin-bottom: 40px;
            min-height: 120px;
            &.cancelSubscribeModal{
              min-height:unset;
            }
            .activated-text{
              color: #006FBA !important;
            }
            .mark-icon-div{
              width: 60px;
              height: 60px;
              margin: auto;
              margin-bottom: 15px;
              img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
              }
            }
            h5{
              font-weight: 400;
              font-size: 22px;
              line-height: normal;
              text-align: center;
              letter-spacing: 1px;
              color: #181818;
              margin-bottom: 8px;
              &.salesforce-connect{
                font-size:18px;
              }
            }
            p{
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              text-align: center;
              letter-spacing: 1px;
              color: #006FBA;
            }
          }
       
          .thank-you-for-modal-wrapper{
            min-height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .unitifi-modal-title-text{
              max-width: 460px;
              width: 100%;
            }
            .goto-link-home{
              min-width: 150px;
              padding: 9px 21px;
              height: auto;
              margin-left: 0;
              display: inline-block;
              background: #026fba;
              color: white;
              text-decoration: none;
              border-radius: 5px;
              transition: all 0.3s ease-in-out;
              &:hover{
                opacity: 0.8;
              }
            }
          }
          .unitifi-behavior-recognized-modal{
            display: flex;
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;
            .unitifi-behavior-recognized-detail{
              flex: 1;
              margin-right: 20px;
              h5{
                color: rgba(0,0,0,.85);
                font-size: 20px;
                font-weight: 600;
              }
              ul{
                li{
                  color: rgba(0,0,0,.85);
                  font-size: 16px;
                  font-weight: 400;
                }
              }
            }
            .unitifi-behavior-recognized-icon{
              width: 100px;
              height: 120px;
              display: flex;
              justify-content: center;
              align-items: center;
              img{
                
              }
            }
          }
        }
      }
    }

    .section_hide{
      display:none;
    }

    .section_show{
      display:block;
    }


    .inviteMember-section{
      &.section_hide{
        display:none;
      }
      &.section_show{
        display:block;
      }
      h4{
        font-size:24px;
        font-weight:400;
        line-height:30px;
        color:#181818;
        margin-bottom:29px;
      }
      p{
        font-size:18px;
        font-weight:500;
        line-height:22px;
        color:rgba(24,24,24,0.79);
        margin-bottom:17px;
        span{
          color:#006FBA;
        }
      }
      .inviteInfo{
        display:block;
        font-size:14px;
        font-weight:400;
        line-height:17px;
        color:#181818;
        margin-bottom:20px;
      }
    }
    &.videoContentWrapper{
      .modal-dialog{
        max-width:1250px;
        width:100%;
        @media only screen and (max-width: 575px) {
          margin:0;
        }
        .modal-content{
          padding:25px 50px 55px;
          @media only screen and (max-width: 991px) {
            padding:25px 40px 45px;
          }
          @media only screen and (max-width: 767px) {
            padding:25px 30px 40px;
          }
          @media only screen and (max-width: 575px) {
            padding:20px 20px 30px;
          }
          .modal-header{
            padding:0;
            .modal-title{
              font-size:25px;
              font-weight:600;
              line-height:30px;
              color:#000;
              @media only screen and (max-width: 575px) {
                font-size:20px;
              }
            }
            .btn-close{
              font-size:12px;
              opacity:1;
            }
          }
          .modal-body{
             padding:0;
             margin-top:40px;
             @media only screen and (max-width: 575px) {
              margin-top:15px;
            }
            .videoDataBlock{
              display:flex;
              flex-wrap:nowrap;
              justify-content:flex-start;
              @media only screen and (max-width: 991px) {
                flex-wrap:wrap;
              }
              .videoSection{
                max-width:825px;
                width:100%;
                height:auto;
                @media only screen and (max-width: 991px) {
                  max-width:100%;
                  margin-bottom:20px;
                }
                img{
                  width:100%;
                  height:100%;
                  object-fit:cover;
                }
              }
            }
            .videoDetail{
              margin-left:40px;
              @media only screen and (max-width: 991px) {
                margin-left:0;
              }
              .videoTitle{
                font-size:17px;
                font-weight:600;
                line-height:30px;
              }
              .videoDescription{
                max-width:310px;
                width:100%;
                font-size:17px;
                font-weight:400;
                word-break:break-word;
                @media only screen and (max-width: 991px) {
                  max-width:100%;
                }
              }
            }
          }
        }
      }    
    }
    .remove-focus-border{
      .input-phone__input{
        input{
          &:hover{
            border-color: #f1f1f1 !important;
          }
          &:focus{
            border-color: #f1f1f1 !important;
          }
        }
      }
    }
    &.unitifi-qrcode-default-modal-wrapper{
      .modal-dialog{
        max-width: 695px;
        width: 100%;
        .modal-content{
          .modal-header{
            align-items: baseline;
          }
          .modal-body{
          padding: 20px;
          form{
            .ant-form-item-label{
              .ant-form-item-required{
                &::before{
                  position: absolute;
                  right: -12px;
                  top: 3px;
                  font-size: 16px;			
                }
              }
            }
          }
          .input-color-box-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            input{
             background: #F4F4F4;
              border-color: #F4F4F4;
              width: calc(100% - 125px);
            }
              .input-color-code-display-wrapper{
                position: relative;
                width: calc(100% - 125px);
                input{
                  width: 100%;
                  padding-left: 18px;
                }
                p{
                  font-size: 14px;
                  font-weight: 500; 
                  line-height: 20px;
                  color: #374151;
                  position: absolute;
                  left: 12px;
                  margin: auto 0; 
                  top: 0;
                  display: flex;
                  align-items: center;
                  height: 100%;
                }
              }
              .color-picker-input{
                flex: 1;
                position: relative;
                margin-left: 12px;
                .colorinput{
                  width: 100%;
                  background: #F4F4F4;
                  border-color: #F4F4F4;
                  min-height: 45px;
                  box-shadow: none;
                  outline: none;
                  font-size: 14px;
                  font-weight: 500; 
                  padding: 10px;
                  border-radius: 4px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  .colorbox{
                    width: 100%;
                    height: 100%;
                    background: black;
                    display: block;
                    min-height: 25px;
                    border-radius: 4px;
                  }
                }
                .w-color-swatch{
                  position: absolute !important;
                  right: 0;
                  top: 50px;
                  z-index: 2;
                  background: #FFFFFF !important;
                  box-shadow: 0px 0px 22px 10px rgba(0, 27, 102, 0.11) !important;
                  border-radius: 8px !important;
                  padding: 8px !important;
                  border: none !important;
                  overflow: hidden;
                  svg{
                    margin-top: 0 !important;
                  }
                  .w-color-alpha{
                    margin-right: 8px;
                  }
                  .w-color-editable-input{
                    input{
                      background: #FFFFFF;
                      padding: 4px 6px;
                      box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
                      border-radius: 4px;
                      min-height: auto !important;
                      height: auto !important;
                      border: 1px solid #E5E7EB !important;
                      box-shadow: none !important;
                      outline: none !important;
                      font-size: 14px !important;
                      font-weight: 600 !important;
                      line-height: 20px;
                      color: #374151 !important;
                      min-height: 33px !important;
                      margin-bottom: 8px !important;
                    }
                    span{
                      max-width: 100% !important;
                      width: 100% !important;
                      background: #FFFFFF;
                      padding: 4px 6px;
                      box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
                      border-radius: 4px;
                      min-height: auto !important;
                      height: auto !important;
                      border: 1px solid #E5E7EB !important;
                      box-shadow: none !important;
                      outline: none !important;
                      font-size: 14px !important;
                      font-weight: 600 !important;
                      line-height: 20px;
                      color: #374151 !important;
                      min-height: 33px !important;
                      display: flex !important;
                      justify-content: center !important;
                      align-items: center !important;
                    }
                  }
                }
                .chrome-picker {
                  position: absolute;
                  right: 0;
                  top: 50px;
                  z-index: 2;
                  background: #FFFFFF !important;
                  box-shadow: 0px 0px 22px 10px rgba(0, 27, 102, 0.11) !important;
                  border-radius: 8px !important;
                  padding: 8px;
                  border: none;
                  overflow: hidden;
                  .flexbox-fix{
                    display: flex;
                    width: calc(100% + 30px);
                    margin-left: -15px;
                    &:last-child{
                      margin-bottom: -12px;
                    }
                    input{
                      background: #FFFFFF;
                      padding: 4px 6px;
                      box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
                      border-radius: 4px;
                      min-height: auto !important;
                      height: auto !important;
                      border: 1px solid #E5E7EB !important;
                      box-shadow: none !important;
                      outline: none !important;
                      font-size: 14px !important;
                      font-weight: 600 !important;
                      line-height: 20px;
                      color: #374151 !important;
                      min-height: 33px !important;
                    }
                    label{
                      max-width: 100% !important;
                      background: #FFFFFF;
                      padding: 4px 6px;
                      box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
                      border-radius: 4px;
                      min-height: auto !important;
                      height: auto !important;
                      border: 1px solid #E5E7EB !important;
                      box-shadow: none !important;
                      outline: none !important;
                      font-size: 14px !important;
                      font-weight: 600 !important;
                      line-height: 20px;
                      color: #374151 !important;
                      min-height: 33px !important;
                      display: flex !important;
                      justify-content: center !important;
                      align-items: center !important;
                    }  
                  }
                }
              }
              svg{
                margin-top: -18px;
              }
            }
            .upload-logo-wrapper{
                display: flex;
                align-items: center;
              .logo-icon{
                  width: 45px;
                  height: 45px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  overflow: hidden;
                  margin-top: -40px;
                  margin-left: 11px;
                  img{
                    width: 100%;
                    height: 100%;
                  }
              }
            }
            .qr-codebox{
              margin: 0 auto 15px;
              overflow: hidden;
              display:flex;
              justify-content: center;
            }
            .edit-download-footer-div{
              display: flex;
              justify-content: center;
              align-items: center;
              button{
                min-height: 38px;
                padding: 8px;
                min-width: 100px;
                background: #056fba;
                border-color: #056fba;
                font-size: 14px;
                color: white;
                font-weight: 600;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                margin: 0 7px;
                i{
                 width: 18px;
                  height: 18px;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 10px;
                }
                &.download-btn{
                background: #32323E;
                border-color: #32323E;
                }
              }
            }
          }
            .upload-logo-wrapper{
              .logo-upload-wrapper{
                .logo-wrapper{
                  .img-upload-box-wrapper{
                    .img-preview-wrapper{
                      .preview{
                        height: 80px;
                      }
                    }
                  }
                }
              }
            }
        }
      }
    }
              .thank-you-for-modal-wrapper{
            min-height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .unitifi-modal-title-text{
              max-width: 460px;
              width: 100%;
            }
            .goto-link-home{
              min-width: 150px;
              padding: 9px 21px;
              height: auto;
              margin-left: 0;
              display: inline-block;
              background: #026fba;
              color: white;
              text-decoration: none;
              border-radius: 5px;
              transition: all 0.3s ease-in-out;
              &:hover{
                opacity: 0.8;
              }
            }
          }
  }
  .StripeElement{
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    color: ${palette("base", 9)};
    padding: 15px;
    min-height: auto;
    height: auto;
    background: ${palette("base", 10)};
    outline: none;
    box-shadow: none;
    border: 1px solid ${palette("base", 10)};
    appearance:none;
    border-radius: 5px;
    background: #f1f1f1;
    .InputElement{
      font-size: 12px;
      font-weight: 400;
    }
    &.StripeElement--focus{
      color: ${palette("base", 9)};
      border-color: ${palette("primary", 12)};
      background: ${palette("base", 10)};
    }
    &:hover{
      color: ${palette("base", 9)};
      border-color: ${palette("primary", 12)};
      background: ${palette("base", 10)};
    }
  }

  .profile-wrapper{
    a{
      text-decoration:none;
      h6{
        font-size:16px;
        font-weight:600;
        text-decoration:underline;
        color:#006FBA;
        margin-top:10px;
        margin-bottom:0;
        text-transform:capitalize;
      }
      p{
         font-size:14px;
         font-weight:400;
         color:#181818; 
      }
    }
  }


  .dashboard-content-wrapper{
    background: rgba(255,255,255,1);
    border-bottom: 1px solid rgba(238,242,244,1);
    padding: 16px 24px 16px 24px;
    -webkit-transition: all .2s;
    transition: all .2s;
    .assessment-info{
      font-weight:600;
      font-size:16px;
      color:#181818;
    }
  }
  .remove-icon{
    label{
      &:after {
        content: unset !important;
      }
    }
  }
  .ant-select-dropdown{
    .ant-select-dropdown-menu{
      .ant-select-dropdown-menu-item{
        color: #181818;
        font-weight: normal;
        font-size: 14px;
      }
    }
    &.default-page-option{
      background:#F1F1F1;
      width:314px !important;
      .ant-select-dropdown-menu{
        .ant-select-dropdown-menu-item{
          color: #969696;
          font-weight: 600;
          font-size: 16px;
          line-height:20px;
          padding:12px;
        }
      }
    }
  }

  .ant-modal-wrap{
    .ant-modal{
      &.ant-modal-confirm{
        .ant-modal-content{
          width:461px;
          .ant-modal-body{
            .ant-modal-confirm-body{
              .anticon{
                display:none;
              }
              .ant-modal-confirm-title{
                max-width:310px;
                width:100%;
                font-size:22px;
                text-align:center;
                line-height:32px;
                letter-spacing:1px;
                margin:auto;
                color:#181818;
              }
              .ant-modal-confirm-content{
                max-width:310px;
                width;100%;
                font-size:14px;
                line-height:24px;
                letter-spacing:1px;
                color:#006fba;
                margin:auto;
                text-align:center;
              }
            }
            .ant-modal-confirm-btns{
              text-align:center;
              float:unset;
            }
          }
        }
      }
    }
  }
  .dashobard-chart, .unitify-vehicle-chart-wrapper {
  path.rv-xy-plot__series{
    stroke:#fff !important;
    stroke-width: 2.2px !important;
  }
}
.popover{
  .popover-header{
    background-color:transparent !important;
    border-bottom:unset !important;
    text-align:center;
    color:${theme.palette.base[9]};
    font-size:18px;
    font-weight:700;
  }
  .popover-body{
    padding:5px 20px;
    ul{
      list-style:none;
      padding-left:1rem;
      li::before {
        content: "\2022";
        color:${theme.palette.base[9]};
        font-weight: bold;
        display: inline-block; 
        width: 1em;
        margin-left: -1em;
      }
      li{
        color:${theme.palette.base[9]};
        font-weight:700;
        font-size:14px;
        span{
          font-weight:700;
          color:${theme.palette.primary[12]};
        }
      }
    }
  }
}

.orgType{
  > div {
      .ant-form-item-control-wrapper{
        .ant-form-item-control{
          .ant-form-item-children{
            display:block;
            width:100%;
            .ant-form-item-children-icon{
              display:inline-flex !important;
              left: auto !important;
              right:0 !important;
            }
          }
        }
      } 
  }

  .ant-notification{
    position:relative;
    z-index:9999 !important;
  }

  .success-msg{
    position:relative;
    z-index: 9999 !important;
  }

.ant-dropdown{
  &.dropdown-sticky{
    position:fixed;
    right:0;
    max-width:232px;
    width:100%;
  }
}

.ant-dropdown{
  .ant-dropdown-menu{
    &.menuOption{
      width:131px;
    }
  }
}
`;

export default globalstyle;
