import React from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Link, Redirect } from "react-router-dom";
import decode from "jwt-decode";
import { Button, Form, Input } from "antd";
import IntlMessages from "../../../helpers/intlMessages";
import { FormWrapper } from "../../../components/form";
import PublicContent from "../../../layouts/public";
import { AccountContext } from "../../../context/AccountContext";
import eyeIcon from "../../../assets/img/eye-icon.svg";
const FormItem = Form.Item;

class LoginForm extends React.Component {
  static contextType = AccountContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isShowPass: false,
    };

    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    /* blank both tokens in local storage to prevent spoofing */
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");

    if (token) {
      try {
        /* catch for no user practices - send to login */
        const {
          user: { practices, paid, id, customerStripeId },
        } = decode(token);
        const { exp } = decode(refreshToken);

        if (Date.now() / 1000 < exp) return;
      } catch (e) {}
    }
    const userContext = this.context;
    userContext.setAccount(null);
    userContext.setProfile(null);
    userContext.setLogoUpdate(null);
    userContext.setUserPermission([]);

    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("current_company");
    localStorage.removeItem("current_practice");
    localStorage.removeItem("current_practice_id");
    localStorage.removeItem("current_company_id");

    // localStorage.removeItem("current_company_id", null);
    // localStorage.setItem("current_company", null);
    // localStorage.setItem("current_practice_id", null);
    // localStorage.setItem("current_practice", null);
    // localStorage.setItem("token", null);
    // localStorage.setItem("refreshToken", null);
    // localStorage.setItem("currentPractice", null);
  }

  handleSubmit = (event) => {
    const userContext = this.context;

    event.preventDefault();

    const { form, history, mutate, location } = this.props;
    let goto = null;

    if (location.search) {
      let splitGo = location.search.split("?goto=");
      if (splitGo.length > 1) goto = splitGo[1];
    }

    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });

        const { email, password } = values;
        const response = await mutate({
          variables: { email: email.trim() },
        });
        //console.log(bcrypt.hash(password,12));
        const { ok, token, refreshToken, errors, userPermissions, defaultLandingPage, practiceId } = response.data.loginUser;
        /**
         ** if the user exists store a token & refreshToken in the user's localStorage
         ** if user does not exist send errors to form based on type
         ** if user belongs to more than one practice or is a global admin,
         ** send to practice list else to practice dashboard
         */
        if (ok) {
          history.push(`/login-2fa?${location.search}&email=${encodeURIComponent(email)}`);
          return;
          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", refreshToken);
          const { user } = decode(token);
          const { isGlobalAdmin, isPortalAdmin, practices, portals, role } = user;
          const perm = userPermissions.map((per) => per.slug);

          userContext.setUserPermission(perm);
          // userContext.setAccount(user);
          if (user) {
            if (portals && portals.length > 0) {
              localStorage.setItem("current_company_id", portals[0].id);
              localStorage.setItem("current_company", portals[0].slug);
              if (practices && practices.length > 0) {
                localStorage.setItem("current_practice_id", practices[0].id);
                localStorage.setItem("current_practice", practices[0].practiceId);
              }
              if (!user.subscriptions && user.accountType == "1") history.push(`/collect-payment`);
              else {
                if (goto) history.push(goto);
                else if (defaultLandingPage == "overview") history.push(`/`);
                else if (defaultLandingPage == "client") history.push(`/${practiceId}/clients`);
                else history.push(defaultLandingPage);
              }
            }
          }
          // if (user.role[0]) {
          //   if (isGlobalAdmin) {
          //     history.push("/");
          //   } else if ([4, 5].includes(user.role[0].id)) {
          //     localStorage.setItem("current_company_id", portals[0].id);
          //     localStorage.setItem("current_company", portals[0].slug);
          //     history.push(`/`);
          //   } else {
          //     // assume that there's practices
          //     if (practices.length > 1) {
          //       history.push("/where");
          //     } else {
          //       localStorage.setItem("current_company_id", portals[0].id);
          //       localStorage.setItem("current_company", portals[0].slug);
          //       localStorage.setItem("current_practice_id", practices[0].id);
          //       localStorage.setItem("current_practice", practices[0].practiceId);
          //       history.push(`/${practices[0].practiceId}`);
          //     }
          //   }
          // } else {
          //   history.push("/login");
          // }

          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });

          /* blank both tokens in local storage to prevent spoofing */
          const userContext = this.context;
          userContext.setAccount(null);
          userContext.setProfile(null);
          userContext.setLogoUpdate(null);
          userContext.setUserPermission([]);

          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("currentPractice");

          errors.forEach(({ path }) => {
            if (path === "email") {
              form.setFields({
                email: {
                  value: email,
                  errors: [new Error("This email has not yet signed up. Please navigate to Create Account to complete signup")],
                },
              });
            }

            if (path === "userPending") {
              form.setFields({
                email: {
                  value: email,
                  errors: [new Error("Your account has not been confirmed.")],
                },
              });
            }

            if (path === "userInactive") {
              form.setFields({
                email: {
                  value: email,
                  errors: [new Error("Your account is not active.")],
                },
              });
            }

            if (path === "password") {
              form.setFields({
                password: {
                  value: password,
                  errors: [new Error("The password you've entered is invalid.")],
                },
              });
            }
          });
        }
      }
    });
  };

  render() {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    if (token) {
      try {
        /* catch for no user practices - send to login */
        const {
          user: { practices, paid, id, customerStripeId },
        } = decode(token);
        const { exp } = decode(refreshToken);

        if (Date.now() / 1000 < exp) {
          {
            /* DASHBOARD */
          }
          return <Redirect to={{ pathname: "/" }} />;
        }
      } catch (err) {}
    }

    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;

    const passHandler = () => {
      this.setState({ ...this.state, isShowPass: !this.state.isShowPass });
    };

    return (
      <PublicContent className="stretch-content unitifi-login-wrapper justify-content-center">
        <div className="public-form-wrapper">
          <FormWrapper className="public-form">
            {/*<div className="unitifi-login-header-bar">
              <p className="login-detail-text">Please enter your login credentials below.</p>
            </div>*/}
            {/* remove-icon */}
            <Form layout="vertical" onSubmit={this.handleSubmit}>
              <FormItem label="Email Address" className="">
                {getFieldDecorator("email", {
                  rules: [
                    {
                      message: "Email is required",
                      required: true,
                    },
                  ],
                })(<Input autoCorrect="off" autoCapitalize="none" />)}
              </FormItem>
              <FormItem className="uni-password-label" labelCol={{ span: 12 }}>
                {/* {getFieldDecorator("password", {
                  rules: [
                    {
                      message: "Password is required",
                      required: true,
                    },
                  ],
                })(<Input type={this.state.isShowPass ? "text" : "password"} />)} */}

                {/* <img src={eyeIcon} className="eyeIcon" />  */}

                {/* <div className="pw-eye-icons" onClick={() => passHandler()}>
                  <i className={this.state.isShowPass ? "pw-view-icon" : "pw-off-view-icon"}></i>
                </div> */}
              </FormItem>
              {/* <div className="uni-forgot__wrapper">
                <Link to="/password/forgot" className="link-text">
                  <IntlMessages id="login.forgotPassword" />
                </Link>
              </div> */}
              <div className="unitifi-footer-wrapper">
                {/* <Button htmlType="button" type="primary" className="unitifi-login-btn-border">
                  <IntlMessages id="Cancel" />
                </Button> */}
                <Button htmlType="submit" loading={loading} type="primary" className="unitifi-login-btn w-100">
                  <IntlMessages id="login.login" />
                </Button>
              </div>
            </Form>
            <div className="unitifi-copy-link-text justify-content-end">
              <p>New to Unitifi?</p>
              <Link to="/signup_account_type" className="link-text">
                Create Account
              </Link>
            </div>
          </FormWrapper>
        </div>
      </PublicContent>
    );
  }
}

LoginForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
};

const loginUserMutation = gql`
  mutation($email: String!) {
    loginUser(email: $email) {
      ok
      token
      defaultLandingPage
      practiceId
      userPermissions {
        id
        slug
      }
      refreshToken
      errors {
        path
      }
    }
  }
`;
export default graphql(loginUserMutation)(Form.create()(LoginForm));
