import React from "react";
import { Redirect } from "react-router-dom";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import moment from "moment";
import gql from "graphql-tag";
import { BackTop, Button, Collapse, Dropdown, Icon, Menu, notification, Spin, Col, Row, Typography, PageHeader } from "antd";
import axios from "axios";
import { saveAs } from "file-saver";
import { apiUrlExport, assessmentUrl } from "../../../config";
import { Titlebar } from "../../../components";
import PassionLogicMapMultiple from "../../../components/passionLogicMap/PassionLogicMapMultiple";
import SelfReportedMapMultiple from "../../../components/selfReportedMap/selfReportedMapMultiple";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import ClientViewWrapper from "./index.style";
import CustomButton from "../../../components/commons/CustomButton";
import { Modal } from "react-bootstrap";
import { AccountContext } from "../../../context/AccountContext";
import TitleBar from "../../../components/titlebar/titlebar";
import { personalityTypeImg } from "../../../helpers/formatters";
import emailIcon from "../../../assets/img/icon-envelope.svg";
import phoneIcon from "../../../assets/img/icon-telephone.svg";

import emailIcon1 from "../../../assets/img/envelope-icon-yellow.svg";
import phoneIcon1 from "../../../assets/img/telephone-icon-yellow.svg";
import { formatNumber } from "../../../utils/formatter";

const { Panel } = Collapse;
const { Title } = Typography;

const customPanelStyle = {
  border: 0,
  borderRadius: 4,
  overflow: "hidden",
};

const customHeaderStyle = {
  color: "rgba(0,0,0,.65)",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "20px",
};

const GenerateReportType = (type) => {
  if (type === "snapshot") {
    return "Snapshot";
  }

  if (type === "401k") {
    return "Retirement";
  }

  if (type == "ucita") {
    return "Advisor-UCIT";
  }
  return "UCIT";
};

class ClientCompareView extends React.Component {
  static contextType = AccountContext;
  constructor(props) {
    super(props);

    this.state = {
      copySuccess: false,
      loading: false,
      pdfLoading: false,
      behaviorModal: false,
      behaviorModalData: null,
      behaviorAttributeData: null,
      behaviorType: "shared",
    };

    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.downloadAssessment = this.downloadAssessment.bind(this);
    this.sendClientAssessment = this.sendClientAssessment.bind(this);
  }

  copyToClipboard = (e, assUrl) => {
    navigator.clipboard.writeText(assUrl);
    this.setState({ copySuccess: true });
  };

  downloadAssessment(type) {
    this.setState({ pdfLoading: true });

    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");

    const {
      me,
      data: { viewClientCompare = null },
    } = this.props;
    const { clients, extra } = viewClientCompare;

    // console.log("download url: ", `${apiUrlExport}/${id}&${currentPractice}&${type}&${token}&${refreshToken}&${assessmentId}`);

    axios
      .get(`${process.env.REACT_APP_API_URL}/export-compare/pdf/${clients[0].id}&${me.practiceid}&${type}&token&refreshToken&${clients[1].id}`, {
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf", "x-token": token, "x-refresh-token": refreshToken },
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });

        this.setState({ pdfLoading: false });

        saveAs(blob, `Client_Comparison_${clients[0].firstName}-${clients[0].lastName}_${clients[1].firstName}-${clients[1].lastName}`);
      });
  }

  sendClientAssessment(assUrl) {
    this.setState({ loading: true });

    const { id, advisorId, practiceId } = this.props.data.viewClient;

    const variables = {
      assessmentUrl: assUrl,
      advisorId,
      clientId: id,
      practiceId,
    };

    this.props
      .sendClientAssessment({ variables })
      .then(({ data }) => {
        if (data.sendClientAssessment.ok && !data.sendClientAssessment.errors) {
          this.handleSuccess(`The assesment has been successfully sent to ${this.props.data.viewClient.email}.`);
          return;
        }

        this.handleError(data.sendClientAssessment.errors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSuccess(description) {
    this.setState({ loading: false });

    notification.success({
      message: "Assessment Sent Successfully",
      //description,
    });

    this.props.data.refetch();
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: "Error",
        description: err.message,
      });
    });
  }

  behaviorModalHandler(data) {
    this.setState({ behaviorModal: true, behaviorModalData: data });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  behaaviorClientHandler(type, index) {
    const {
      data: { viewClientCompare = null },
    } = this.props;
    const { clients, extra } = viewClientCompare;
    this.setState({ behaviorType: type, behaviorAttributeData: null }, () => {
      this.setState({ behaviorAttributeData: type == "shared" ? { insights: { tendencies: extra } } : clients[index] });
    });
  }

  render() {
    const {
      me,
      data: { loading, error, viewClientCompare = null },
    } = this.props;

    if (loading) {
      return (
        <div className="loading-wrapper">
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }

    if (error) {
      return (
        <div className="loading-wrapper">
          <p>Whoopsie - clients didn&apos;t load.</p>
        </div>
      );
    }

    if (!viewClientCompare) {
      return <Redirect to="/" />;
    }

    const { clients, extra } = viewClientCompare;
    if (clients.length != 2) return <Redirect to="/" />;

    if (!this.state.behaviorAttributeData && extra.length > 0) this.setState({ behaviorAttributeData: { insights: { tendencies: extra } } });

    const { assessmentToken, dateSent, email, firstName, insights, allInsights, assessmentId, lastName, practiceName, advisorname, phonenumber, phone_code } = clients[0];
    const userContext = this.context;
    const { account } = userContext;

    if (!insights) {
      const newClient = this.props.location.state && this.props.location.state.newClient;
    }

    return (
      <div className="unirifi-costomer-layout-wrapper">
        <BackTop />
        <div className="unitifi-header customHeader">
          <Title>UCIT</Title>
          <p>Unitifi Consumer Insight Tool</p>
        </div>
        <div className="unitifi-subheader-bar-wrapper ">
          <h6 className="pageTitle">Client Comparison</h6>
        </div>

        <div className="unitifi-consumer-header-bar compareviewBox">
          <div className="client-header-detail-box">
            <div className="client-header-left-content">
              <div className="client-detail-info">
                <h6 className="clientTitle">
                  {firstName} {lastName}
                </h6>
                <h6 class="cmn-title-text">
                  <a href={`mailto:${email}`}>
                    <img src={emailIcon} className="me-2" />
                    {email}
                  </a>
                </h6>
                <span className="cmn-title-text phoneData">
                  <a href={`${phonenumber ? `tel:${phonenumber}` : "#"}`}>
                    <img src={phoneIcon} className="me-2" />
                    {phonenumber ? formatNumber(phone_code, phonenumber) : "N/A"}
                  </a>
                </span>
              </div>

              <div className="seperator"></div>

              <div className="client-detail-info">
                <h6 className="clientTitle secondClient">
                  {clients[1].firstName} {clients[1].lastName}
                </h6>
                <h6 class="cmn-title-text">
                  <a href={`mailto:${clients[1].email}`} style={{ color: "#cb9120" }}>
                    <img src={emailIcon1} className="me-2" />
                    {clients[1].email}
                  </a>
                </h6>
                <span className="cmn-title-text phoneData">
                  <a href={`${clients[1].phonenumber ? `tel:${clients[1].phonenumber}` : "#"}`} style={{ color: "#cb9120" }}>
                    <img src={phoneIcon1} className="me-2" />
                    {clients[1].phonenumber ? formatNumber(clients[1].phone_code, clients[1].phonenumber) : "N/A"}
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="downloadBtn-wrapper">
            <div className="button-bar-wrapper has-dropdown download">
              <Button type="primary" icon="download" loading={this.state.pdfLoading} size="large" onClick={(e) => this.downloadAssessment("ucita")}>
                Download
              </Button>
            </div>
          </div>
        </div>

        <ClientViewWrapper>
          <div className="main-chart-wrapper">
            {/* left bar / 3 divs */}
            <div className="left-col">
              <div className="item">
                <div className="heading">Behavior Defined</div>
                <div className="behavior-defined-box-wrapper">
                  <div className="behavior-defined-text">
                    <div className="behavior-text">
                      <h4>
                        <div>
                          <span>{insights.score.riskBehaviorScore}</span>
                        </div>
                        Science Reported
                      </h4>
                    </div>
                    <div className="behavior-text">
                      <h4>
                        <div>
                          <span>{insights.score.selfReportedScore}</span>
                        </div>
                        Self-Reported
                      </h4>
                    </div>
                  </div>
                  <div className="behavior-defined-text behavior-yellow-text">
                    <div className="behavior-text">
                      <h4>
                        <div>
                          <span>{clients[1].insights.score.riskBehaviorScore}</span>
                        </div>
                        Science Reported
                      </h4>
                    </div>
                    <div className="behavior-text">
                      <h4>
                        <div>
                          <span>{clients[1].insights.score.selfReportedScore}</span>
                        </div>
                        Self-Reported
                      </h4>
                    </div>
                  </div>
                </div>
                {/* <div className="details-wrapper d-flex align-items-center justify-content-around">
                  <div className="score-item-wrapper">
                    <div className="score-item leftScore">
                      <div className="risk-behavior-score">{insights.score.riskBehaviorScore}</div>
                      <div className="scoreLabel">Science Reported</div>
                    </div>
                    <div className="item-divider"></div>
                    <div className="score-item leftScore">
                      <div className="selfReportedScore">{insights.score.selfReportedScore}</div>
                      <div className="scoreLabel">Self-Reported</div>
                    </div>
                  </div>
                  <div className="seperator"></div>
                  <div className="score-item-wrapper">
                    <div className="score-item rightScore">
                      <div className="risk-behavior-score compareScore">{clients[1].insights.score.riskBehaviorScore}</div>
                      <div className="scoreLabel">Science Reported</div>
                    </div>
                    <div className="item-divider"></div>
                    <div className="score-item rightScore">
                      <div className="selfReportedScore compareReport">{clients[1].insights.score.selfReportedScore}</div>
                      <div className="scoreLabel">Self-Reported</div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="item">
                <div className="heading">Financial Personality</div>
                <div className="d-flex align-items-center justify-content-around">
                  <div className="details-wrapper" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                    {/* <div className="label">{insights.personality.behavior}</div> */}
                    <div className="calloutLbl">
                      {insights.personality.type}
                      <i className="ms-1">
                        <img src={personalityTypeImg[insights.personality.type]} />
                      </i>
                    </div>
                  </div>
                  <div className="details-wrapper" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                    {/* <div className="label">{clients[1].insights.personality.behavior}</div> */}
                    <div className="calloutLbl compareLbl">
                      {clients[1].insights.personality.type}
                      <i className="ms-1">
                        <img src={personalityTypeImg[clients[1].insights.personality.type]} />
                      </i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="heading">Decision Making Style</div>
                <div className="details-wrapper chartWrapper">
                  <PassionLogicMapMultiple data={[insights, clients[1].insights]} firstName={firstName} firstNameClient={clients[1].firstName} />
                </div>
              </div>
            </div>

            {/* chart */}
            <div className="right-col">
              <div className="chart-legend">
                <div style={{ alignItems: "center", display: "flex", marginRight: "24px" }}>
                  <div style={{ height: "10px", width: "10px", borderRadius: "100%", background: "rgba(0,111,186,1)", marginRight: "6px", border: "solid 1px rgba(0,111,186,1)" }}></div>
                  <div>Science Reported</div>
                </div>
                <div style={{ alignItems: "center", display: "flex" }}>
                  <div style={{ height: "10px", width: "10px", borderRadius: "100%", background: "rgba(255,255,255,1)", marginRight: "6px", border: "solid 1px rgba(0,111,186,1)" }}></div>
                  <div>Self-Reported</div>
                </div>
              </div>

              <div className="chart-wrapper">
                <SelfReportedMapMultiple data={[insights, clients[1].insights]} />
              </div>
            </div>
          </div>

          <div className="main-wrapper">
            <div className="wrapper">
              <div className="heading">Communication Center</div>
              <div className="item-wrapper-flex">
                <div className="item-flex justify-content-center">
                  <div className="item-flex-heading clientTitle">
                    {firstName} {lastName}
                  </div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Expectation</div>
                  <div>{insights.profile.communicationExpectation}</div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Age</div>
                  <div>{new Date().getFullYear() - insights.retirement.birthYear}</div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Retirement Year</div>
                  <div>{insights.retirement.retirementYear}</div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Retirement Vehicle</div>
                  <div>{insights.profile.currentRetirementVehicle}</div>
                </div>
              </div>
              <div className="item-wrapper-flex">
                <div className="item-flex justify-content-center">
                  <div className="item-flex-heading secondClient">
                    {clients[1].firstName} {clients[1].lastName}
                  </div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Expectation</div>
                  <div>{clients[1].insights.profile.communicationExpectation}</div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Age</div>
                  <div>{new Date().getFullYear() - clients[1].insights.retirement.birthYear}</div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Retirement Year</div>
                  <div>{clients[1].insights.retirement.retirementYear}</div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Retirement Vehicle</div>
                  <div>{clients[1].insights.profile.currentRetirementVehicle}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="main-wrapper">
            <div className="wrapper">
              <div className="heading">Financial Personality Defined:</div>

              <div className="d-flex align-items-start justify-content-start flex-wrap flex-md-nowrap">
                <div className="item-wrapper firstCol">
                  <h6 className="clientTitle">
                    {firstName} {lastName} - {insights.personality.type}
                  </h6>
                  <div className="item has-below">
                    <div className="item-heading">Behaviors of this Investor</div>
                    <div>
                      <ul>
                        {insights.personality.attributes.investorBehaviors.map((i) => (
                          <li key={i}>{i}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="item-heading">Tips for the Financial Professional</div>
                    <div>
                      <ul>
                        {insights.personality.attributes.advisorTips.map((i) => (
                          <li key={i}>{i}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                {/* <div className="itemDivider"></div> */}

                <div className="item-wrapper secondCol">
                  <h6 className="clientTitle secondClient">
                    {clients[1].firstName} {clients[1].lastName} - {clients[1].insights.personality.type}
                  </h6>
                  <div className="item has-below">
                    <div className="item-heading">Behaviors of this Investor</div>
                    <div>
                      <ul>
                        {clients[1].insights.personality.attributes.investorBehaviors.map((i) => (
                          <li key={i}>{i}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="item">
                    <div className="item-heading">Tips for the Financial Professional</div>
                    <div>
                      <ul>
                        {clients[1].insights.personality.attributes.advisorTips.map((i) => (
                          <li key={i}>{i}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="main-wrapper">
            <div>
              <div className="heading">Behavior Attributes Recognized</div>

              <div className="item-wrapper">
                <div className="attributeBtnWrapper">
                  <span className={`attributeData ${this.state.behaviorType == "shared" ? "active" : ""}`} onClick={() => this.behaaviorClientHandler("shared", 2)}>
                    Shared
                  </span>
                  <span className={`attributeData ${this.state.behaviorType == "client1" ? "active" : ""}`} onClick={() => this.behaaviorClientHandler("client1", 0)}>
                    {firstName} {lastName}
                  </span>
                  <span className={`attributeData ${this.state.behaviorType == "client2" ? "active" : ""}`} onClick={() => this.behaaviorClientHandler("client2", 1)}>
                    {clients[1].firstName} {clients[1].lastName}
                  </span>
                </div>
                <div className="attribute-text">
                  {this.state.behaviorType == "shared"
                    ? "The following tiles identify shared behavior attributes recognized for clients selected."
                    : `The following tiles identify behavior attributes recognized for ${this.state.behaviorAttributeData && this.state.behaviorAttributeData.firstName}.`}
                  <div className="circle"></div> indicates {this.state.behaviorType == "shared" ? "" : "a"} strongly identified attribute, while <div className="circle min"></div> indicates an identified attribute.
                </div>
                <div className="unitifi-behavior-attributes-section">
                  <Row>
                    <Col lg={{ span: 16 }} md={{ span: 24 }}>
                      <Row gutter={27}>
                        {this.state.behaviorAttributeData &&
                          this.state.behaviorAttributeData.insights.tendencies &&
                          this.state.behaviorAttributeData.insights.tendencies.length > 0 &&
                          this.state.behaviorAttributeData.insights.tendencies.map((tendency) => (
                            <Col lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 24 }} key={tendency.id}>
                              <div className={`unitifi-behavior-attributes-box ${tendency.type == "Strongly Identified" ? "" : "blue-box"}`} onClick={() => this.behaviorModalHandler(tendency)}>
                                <div className="icons-and-details">
                                  <i>
                                    {" "}
                                    <img src={tendency.white_icon} />{" "}
                                  </i>
                                  <p>{tendency.name}</p>
                                </div>
                              </div>
                            </Col>
                          ))}
                        <Modal show={this.state.behaviorModal} onHide={() => this.setState({ behaviorModal: false, behaviorModalData: null })} centered className="unitifi-modal-wrapper" size="lg">
                          {this.state.behaviorModalData && (
                            <>
                              <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-sm">{this.state.behaviorModalData.name}</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="unitifi-behavior-recognized-modal d-flex justify-content-between">
                                  <div className="unitifi-behavior-recognized-detail">
                                    {this.state.behaviorModalData.characteristics.length > 0 && (
                                      <>
                                        <h5>Characteristics</h5>
                                        <ul>
                                          {this.state.behaviorModalData.characteristics.map((c) => (
                                            <li key={c}>{c}</li>
                                          ))}
                                        </ul>
                                      </>
                                    )}
                                    {this.state.behaviorModalData.results.length > 0 && (
                                      <>
                                        <h5 className="mt-4">Result</h5>
                                        <ul>
                                          {this.state.behaviorModalData.results.map((res) => (
                                            <li key={res}>{res}</li>
                                          ))}
                                        </ul>
                                      </>
                                    )}
                                    {this.state.behaviorModalData.recommendations.length > 0 && (
                                      <>
                                        <h5 className="mt-4">Tips for the Financial Professional</h5>
                                        <ul>
                                          {this.state.behaviorModalData.recommendations.map((res) => (
                                            <li key={res}>{res}</li>
                                          ))}
                                        </ul>
                                      </>
                                    )}
                                  </div>
                                  <div>
                                    <div className="unitifi-behavior-recognized-icon">
                                      <img src={this.state.behaviorModalData.blue_icon} />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                            </>
                          )}
                        </Modal>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </ClientViewWrapper>
      </div>
    );
  }
}

const viewClientQuery = gql`
  query($id: Int!, $practiceId: Int, $assessmentId: Int, $portalId: Int, $roleCode: String, $accountType: Int, $clientId: Int) {
    viewClientCompare(id: $id, practiceId: $practiceId, assessmentId: $assessmentId, portalId: $portalId, roleCode: $roleCode, accountType: $accountType, clientId: $clientId) {
      clients
      extra
    }
  }
`;

export default compose(
  graphql(viewClientQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        id: Number(props.match.params.id),
        practiceId: props.me.practiceid,
        assessmentId: Number(props.match.params.assessmentId),
        accountType: props.me.accountType,
        roleCode: props.me.rolecode,
        portalId: props.currentPortalId,
        clientId: Number(props.match.params.clientId),
      },
    }),
  })
)(ClientCompareView);
