import React, { useContext, useEffect, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Badge, Button, Icon, Input, notification, Spin } from "antd";
import { ButtonBar, Titlebar } from "../../../components";
import GenerateBadge from "../../../helpers/generateBadge";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import UserViewWrapper from "./index.style";
import moment from "moment";
import { AccountContext } from "../../../context/AccountContext";
import TitleBar from "../../../components/titlebar/titlebar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Qrcode from "./qrcode";
import { encrypt } from "../../../utils/crypt";
import { formatNumber } from "../../../utils/formatter";

const getUserQuery = gql`
  query($id: Int!) {
    getUser(id: $id) {
      id
      address
      city
      email
      firstName
      lastLogin
      lastName
      phoneNumber
      phone_code
      practiceId
      practicename
      role
      state
      status
      zipCode
      assessmentNotification
      practiceslug
      portalslug
      qrcode
      practices
      isPracticeAdmin
      accountType
    }
  }
`;

const UserView = (props) => {
  const { me, currentPractice, location } = props;
  const [companyEncode, setCompanyEncode] = useState(null);
  const [qrModal, setQrModal] = useState(false);
  const { loading, error, data } = useQuery(getUserQuery, {
    fetchPolicy: "network-only",
    variables: {
      id: Number(props.match.params.id),
    },
  });

  let getUser = data && data.getUser;
  const userContext = useContext(AccountContext);
  const { account } = userContext;
  // console.log("getUser", getUser);
  useEffect(() => {
    if (!getUser) return;
    let company = encrypt(`${getUser.portalslug}`) + "/" + encrypt(getUser.practiceslug) + "/" + encrypt(`${getUser.firstName}-${getUser.lastName}_${getUser.id}`);
    setCompanyEncode(company);
  }, [getUser]);

  const qrCodeClose = () => {
    setQrModal(false);
  };

  const copyHandler = () => {
    notification.success({
      message: "Copied to clipboard",
    });
  };

  if (loading) {
    return (
      <div className="loading-wrapper">
        <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="loading-wrapper">
        <p>Whoopsie - user didn&apos;t load.</p>
      </div>
    );
  }

  const menuKeys = ["advisors", "clients", "practices", "users"];
  const path = location.pathname.split("/");
  const topLevelMenu = menuKeys.includes(currentPractice);
  const pathRoute = topLevelMenu ? path[1] : path[2];
  const pathname = topLevelMenu ? `/${pathRoute}/edit/${getUser.id}` : `/${currentPractice}/${pathRoute}/edit/${getUser.id}`;
  const redirect = topLevelMenu ? `/${pathRoute}/view/${getUser.id}` : `/${currentPractice}/${pathRoute}/view/${getUser.id}`;

  return (
    <UserViewWrapper>
      <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper">
          <div className="qr-header-wrapper">
            <h4 className="edit-iconbox">
              <TitleBar
                title={`${getUser && getUser.firstName} ${getUser && getUser.lastName}`}
                subTitle={account && me && (me.rolecode === "CMPYO" || me.rolecode === "PRCTO" ? `${account.portalname} | ${getUser.practicename}` : `${account.portalname}`)}
              />
              {me && getUser && (me.permissions.includes("editadviser") || me.id === getUser.id) && <Button className="btnDelete" icon="edit" onClick={() => props.history.push({ pathname, state: { redirect } })} />}
            </h4>
            <div className="copylink-block-wrapper">
              <div className="copylink-inputbox">
                <Input placeholder="Company Name" type="text" readOnly value={`${process.env.REACT_APP_URL}assessment/${companyEncode}`} />
                <CopyToClipboard text={`${process.env.REACT_APP_URL}assessment/${companyEncode}`} onCopy={() => console.log("cpu")}>
                  <Button className="" icon="copy" onClick={() => copyHandler()} />
                </CopyToClipboard>
              </div>
              <Button className="viewqr-btn" onClick={() => setQrModal(true)}>
                View QR
              </Button>
            </div>
          </div>
          {/* {me && (me.permissions.includes("editadviser") || me.id === getUser.id) ? (
            <div className="button-bar-wrapper">
              <ButtonBar
                icon="edit"
                link={{
                  pathname,
                  state: { redirect },
                }}
                text={`Edit ${pathRoute.replace(/s\s*$/, "")}`}
              />
            </div>
          ) : null} */}
        </div>
        <LayoutContentWrapper>
          <LayoutContent>
            {pathRoute === "users" ? (
              <div className="row-wrapper">
                <div className="row-label">Role</div>
                <div>{getUser.role}</div>
              </div>
            ) : null}
            <div className="row-wrapper">
              <div className="row-label">Email</div>
              <div>{getUser.email ? getUser.email : null}</div>
            </div>
            <div className="row-wrapper">
              <div className="row-label">Phone Number</div>
              <div>{getUser.phoneNumber ? formatNumber(getUser.phone_code, getUser.phoneNumber) : ""}</div>
            </div>
            <div className="row-wrapper">
              <div className="row-label">Address</div>
              <div>
                <div>{getUser.address ? getUser.address : null}</div>
                <div>{getUser.city && getUser.state && getUser.zipCode ? `${getUser.city}, ${getUser.state} ${getUser.zipCode}` : null}</div>
              </div>
            </div>
            <div className="row-wrapper">
              <div className="row-label">Status</div>
              <div>
                <Badge status={GenerateBadge(getUser.status)} text={getUser.status} />
              </div>
            </div>
            <div className="row-wrapper">
              <div className="row-label">Receive Assessment Notification</div>
              <div>
                <Badge status={GenerateBadge(getUser.assessmentNotification ? "ACTIVE" : "")} text={getUser.assessmentNotification ? "YES" : "NO"} />
              </div>
            </div>
            <div className="row-wrapper">
              <div className="row-label">Last Login</div>
              <div>{getUser.lastLogin ? moment(getUser.lastLogin).format("MMM D YYYY h:mm a") : null}</div>
            </div>
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
      {companyEncode && <Qrcode getAdvisor={getUser} {...props} qrCodeClose={qrCodeClose} setQrModal={setQrModal} qrModal={qrModal} userEncoded={companyEncode} account={account} />}
    </UserViewWrapper>
  );
};

export default UserView;
