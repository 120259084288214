import React from "react";
import { Redirect } from "react-router-dom";
import { Col, Drawer, Icon, Layout, Row } from "antd";
import { debounce } from "lodash";
import { practiceConfig } from "../../config";
import { HeaderBar, Sidebar } from "../../components";
import ScrollToTop from "./helpers/scrollToTop";
import Router from "./router";
import BuildUserProps from "../../helpers/buildUserProps";
import RouteConverter from "../../helpers/routeConverter";
import ContentWrapper from "./index.style";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import { AccountContext } from "../../context/AccountContext";
import decode from "jwt-decode";
import _ from "lodash";
import SecurityChecker from "../../helpers/securityChecker";
import moment from "moment";
import loudSpeaker from "../../assets/img/megaphone-icon.svg";
import closeIcon from "../../assets/img/banner-close-icon.svg";

const { Content, Footer } = Layout;

class App extends React.Component {
  static contextType = AccountContext;

  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      width: window.innerWidth,
      isRemoveBanner: false,
    };

    this.menuToggle = this.menuToggle.bind(this);
    this.resize = this.resize.bind(this);
    this.resizeToggle = this.resizeToggle.bind(this);
  }

  componentDidMount() {
    this.resizeToggle();
    window.addEventListener("resize", debounce(this.resizeToggle, 200));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeToggle);
  }

  componentDidUpdate(prevProps) {
    const userContext = this.context;
    if (!_.isEmpty(this.props.data.me) && !userContext.account) {
      let account = _.get(this.props.data, "me", []);
      userContext.setAccount(account);
      const removeBanner = localStorage.getItem("removeBanner");
      if (removeBanner) {
        if (account.isBannerShow == "1") this.props.updateBanner({ variables: { id: "update" } }).then((data) => {});
      }
    }

    if (this.props !== prevProps) {
      let token = localStorage.getItem("token");
      if (token) {
        let currentUser = decode(localStorage.getItem("token")).user;
        if (currentUser) {
          userContext.setProfile(currentUser);
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location && this.props.location.pathname == "/news-center" && this.props.location.pathname != nextProps.location.pathname) {
      const { innerWidth } = window;
      this.setState({
        collapsed: true,
        width: innerWidth,
      });
    }
  }

  menuToggle() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  resize(innerWidth) {
    if (innerWidth < 9001) {
      return true;
    }

    return false;
  }

  resizeToggle() {
    const { innerWidth } = window;

    this.setState({
      collapsed: this.resize(innerWidth),
      width: innerWidth,
    });
  }

  bannerRemoveHandler() {
    localStorage.setItem("removeBanner", "1");
    this.setState({ isRemoveBanner: true });
    this.props.updateBanner({ variables: { id: "update" } }).then((data) => {});
  }

  render() {
    /* prop the pathname for setting Titlebar component state */
    const { currentPractice, defaultTitle, openKeys, selectedKeys } = RouteConverter(this.props.location.pathname);
    /* generate global user props */
    const {
      user,
      location,
      // userSubscription: { getUserSubscription },
      data: { me, loading, error },
    } = this.props;

    // if (getUserSubscription) {
    //   if (getUserSubscription.status == "incomplete" && location.pathname !== "/settings/subscription") {
    //     return <Redirect from="/" to={`/settings/subscription`} />;
    //   }
    // }
    // const { urlValidation, returnUrl, returnLogin } = SecurityChecker({ user, location });
    if (loading == false && !me) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("current_company");
      localStorage.removeItem("current_practice");
      localStorage.removeItem("current_practice_id");
      localStorage.removeItem("current_company_id");
      let redirectUrl = `/login`;

      if (location.pathname == "/wealthbox-authorization") redirectUrl = `/login?goto=wealthbox-authorization`;
      if (location.pathname == "/salesforce-authorization") redirectUrl = `/login?goto=salesforce-authorization`;

      return <Redirect from="/" to={redirectUrl} />;
    }
    if (me && (me.permissions.length == 0 || me.status == "Pause")) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("current_company");
      localStorage.removeItem("current_practice");
      localStorage.removeItem("current_practice_id");
      localStorage.removeItem("current_company_id");

      return <Redirect from="/" to={`/login`} />;
    }
    // if (!urlValidation) {
    //   return <Redirect from="/" to={`${returnUrl}`} />;
    // }
    const showPracticeList = [];
    // const { isPracticeMember, showPracticeList, userRole } = BuildUserProps(user, currentPractice, openKeys);
    /* handle redirect if trying to access practice user is not a member of */
    // if (!isPracticeMember && !(user.isGlobalAdmin || user.isPortalAdmin)) {
    //   return <Redirect from="/" to='/login' />;
    // }
    /* send requests for pdf/print view of events/activities to full screen */
    if (defaultTitle.includes("titlebar.clientsPrint")) {
      return (
        <Router
          currentPractice={me ? me.currentpracticeid : null}
          gblUserId={user.id}
          gblUserIsGlobalAdmin={user.isGlobalAdmin}
          gblUserIsPracticeAdmin={user.isPracticeAdmin}
          gblUserIsPortalAdmin={user.isPortalAdmin}
          gblUserRole={(user.role && user.role[0].id) || 0}
          userPracticeList={user.practices}
          userPortals={user.portals}
          location={location}
        />
      );
    }

    if (me && location && (location.pathname == "/news-center" || location.pathname == "/wealthbox-authorization" || location.pathname == "/salesforce-authorization")) {
      return (
        <Router
          {...this.props}
          firstLogin={
            me
              ? moment(me.firstLogin)
                  .startOf("day")
                  .format("YYYY-MM-DD")
              : ""
          }
          firstName={me ? me.firstName : ""}
          lastName={me ? me.lastName : ""}
          currentPractice={me ? me.currentpracticeid : null}
          gblUserId={user.id}
          gblUserIsGlobalAdmin={user.isGlobalAdmin}
          gblUserIsPracticeAdmin={user.isPracticeAdmin}
          gblUserIsPortalAdmin={user.isPortalAdmin}
          gblUserRole={(user.role && user.role[0].id) || 0}
          title={defaultTitle}
          sidebarCollapsed={this.state.collapsed}
          userPracticeList={user.practices}
          userPortals={user.portals}
          currentPortalId={me ? me.portalid && me.portalid.length > 0 && me.portalid[0] : null}
          location={location}
          user={user}
          me={me}
        />
      );
    }

    return (
      <ContentWrapper>
        {me && (
          <>
            <HeaderBar collapsed={this.state.collapsed} currentPractice={me.currentpracticeid} currentPortalId={me ? me.portalid && me.portalid.length > 0 && me.portalid[0] : null} gblUserId={user.id} {...this.props} />
            {/* show trigger outside drawer when menu sidebar is collapsed */}
            {this.state.width < 9001 && this.state.collapsed ? (
              <div className="menu-trigger-wrapper collapsed">
                <Icon className="trigger collapsed" onClick={this.menuToggle} type="menu-unfold" />
              </div>
            ) : null}
            <Layout>
              {this.state.width > 9000 ? (
                <Sidebar
                  {...this.props}
                  collapsed={this.state.collapsed}
                  currentPractice={me ? me.currentpracticeid : null}
                  currentPortalId={me ? me.portalid && me.portalid.length > 0 && me.portalid[0] : null}
                  gblUserId={user.id}
                  gblUserIsGlobalAdmin={user.isGlobalAdmin}
                  gblUserIsPracticeAdmin={user.isPracticeAdmin}
                  gblUserIsPortalAdmin={user.isPortalAdmin}
                  gblUserRole={(user.role && user.role[0].id) || 0}
                  menuToggle={this.menuToggle}
                  openKeys={openKeys}
                  selectedKeys={selectedKeys}
                  showPracticeList={showPracticeList}
                  location={location}
                  screenWidth={this.state.width}
                />
              ) : (
                <Drawer bodyStyle={{ background: "rgb(255,255,255)", padding: 0 }} closable={false} onClose={this.menuToggle} placement="left" style={{ padding: 0 }} visible={!this.state.collapsed} width={256}>
                  {/* show trigger inside drawer when menu sidebar is expanded */}
                  {!this.state.collapsed ? (
                    <div className="menu-trigger-wrapper expanded">
                      <Icon className="trigger expanded" onClick={this.menuToggle} type="close" />
                    </div>
                  ) : null}
                  <Sidebar
                    {...this.props}
                    collapsed={this.state.collapsed}
                    currentPractice={me ? me.currentpracticeid : null}
                    currentPortalId={me ? me.portalid && me.portalid.length > 0 && me.portalid[0] : null}
                    gblUserId={user.id}
                    gblUserIsGlobalAdmin={user.isGlobalAdmin}
                    gblUserIsPracticeAdmin={user.isPracticeAdmin}
                    gblUserIsPortalAdmin={user.isPortalAdmin}
                    gblUserRole={(user.role && user.role[0].id) || 0}
                    location={location}
                    menuToggle={this.menuToggle}
                    isMobile
                    openKeys={openKeys}
                    selectedKeys={selectedKeys}
                    showPracticeList={showPracticeList}
                    screenWidth={this.state.width}
                  />
                </Drawer>
              )}
              <Layout className={this.state.collapsed ? "layout collapsed" : "layout"}>
                <Content>
                  <div className="content-wrapper">
                    <ScrollToTop>
                      {/* {me.isBannerShow == "1" && this.state.isRemoveBanner == false && !localStorage.getItem("removeBanner") && (
                        <Row gutter={24} className="m-2">
                          <Col lg={24}>
                            <div className={`${location.pathname == "/" ? "dashboard-notification-banner" : ""} unitifi-notification-banner-data`}>
                              <span className="notification-icon-wrapper">
                                <img alt="loud-speaker" src={loudSpeaker} />
                              </span>
                              <div className="notification-data-wrapper">
                                <span class="unitifi-notification-content">
                                  Starting on <b>June 12th 2024, Wednesday</b>, we're upgrading our login system to <b>2FA (Two-Factor Authentication)</b> for added protection. Instead of using your password, a OTP (One-Time Passcode) will be sent to
                                  your registered email for login. Any questions? Reach out to <a href="mailto:support@unitifi.com">support@unitifi.com</a>.
                                </span>
                                <span onClick={() => this.bannerRemoveHandler()}>
                                  <a href="#">
                                    <img alt="loud-speaker" src={closeIcon} />
                                  </a>
                                </span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )} */}
                      <Router
                        {...this.props}
                        firstLogin={
                          me
                            ? moment(me.firstLogin)
                                .startOf("day")
                                .format("YYYY-MM-DD")
                            : ""
                        }
                        firstName={me ? me.firstName : ""}
                        lastName={me ? me.lastName : ""}
                        currentPractice={me ? me.currentpracticeid : null}
                        gblUserId={user.id}
                        gblUserIsGlobalAdmin={user.isGlobalAdmin}
                        gblUserIsPracticeAdmin={user.isPracticeAdmin}
                        gblUserIsPortalAdmin={user.isPortalAdmin}
                        gblUserRole={(user.role && user.role[0].id) || 0}
                        title={defaultTitle}
                        sidebarCollapsed={this.state.collapsed}
                        userPracticeList={user.practices}
                        userPortals={user.portals}
                        currentPortalId={me ? me.portalid && me.portalid.length > 0 && me.portalid[0] : null}
                        location={location}
                        user={user}
                        me={me}
                      />
                    </ScrollToTop>
                  </div>
                </Content>
                <Footer>{practiceConfig.footerText}</Footer>
              </Layout>
            </Layout>
          </>
        )}
      </ContentWrapper>
    );
  }
}

const currentUserQuery = gql`
  query {
    me {
      id
      firstName
      lastName
      permissions
      portalid
      roleId
      rolecode
      firstLogin
      currentpracticeid
      practiceid
      isBannerShow
      isPracticeAdmin
      isPortalAdmin
      isAdvisorAdmin
      accountType
      portalname
      practicename
      status
      portalslug
      notifications {
        clientDetails {
          id
          dateCollected
          name
          practiceId
          transferredClient
        }
        count
      }
    }
  }
`;

const updateBannerMutation = gql`
  mutation bannerUpdate($id: String) {
    bannerUpdate(id: $id) {
      ok
      message
    }
  }
`;

// const getUserSubscription = gql`
//   query {
//     getUserSubscription {
//       id
//       status
//       start_date
//       current_period_end
//       current_period_start
//     }
//   }
// `;

//export default App;

export default compose(
  graphql(currentUserQuery, {
    options: {
      fetchPolicy: "network-only",
    },
  }),
  graphql(updateBannerMutation, { name: "updateBanner" })
  // graphql(getUserSubscription, {
  //   name: "userSubscription",
  // })
)(App);
