import React, { useContext, useEffect, useState } from "react";
import gql from "graphql-tag";
import { Steps, Icon, Row, Col, Layout, Card, Form, Input, Progress, Divider } from "antd";
import { useLazyQuery, useQuery } from "react-apollo";
import _, { stubFalse } from "lodash";
import ContentWrapper from "./index.style";
import HeaderBar from "./HeaderBar";
import SignupForm from "./steps/signupform";
import FullSignupForm from "./steps/fullsignupform";
import Assessment from "./steps/assessment";
import Done from "./steps/done";
import logo from "../../../assets/img/unitifi.svg";
import { AccountContext } from "../../../context/AccountContext";
import { decrypt } from "../../../utils/crypt";

const { Content, Footer } = Layout;

export default Form.create()(AssessmentLink);

function AssessmentLink(props) {
  const { practiceIds, userIds, companyName } = props.match.params;
  const { account, setAccount, setProfile, setUserPermission, setLogoUpdate } = useContext(AccountContext);
  const [userInviteDomain, setuserInviteDomain] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [practiceId, setPracticeId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [state, setState] = useState({
    email: "",
  });
  const [pages, setPages] = useState([
    {
      title: "Registration",
      icon: <Icon type="user" />,
      status: false,
    },
    {
      title: "Assessment",
      icon: <Icon type="solution" />,
      status: false,
    },
    {
      title: "Done",
      icon: <Icon type="smile-o" />,
      status: false,
    },
  ]);
  const [activePage, setActivePage] = useState("signup");
  const { Step } = Steps;

  const [getLogoForClientSignupHandler, { loading, data, error }] = useLazyQuery(
    gql`
      query($advisorId: Int, $practiceId: String) {
        getLogoForClientSignup(advisorId: $advisorId, practiceId: $practiceId) {
          companylogo
          advisorId
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (companyName && !practiceIds) setUserId(data.getLogoForClientSignup.advisorId);
        if (companyName && practiceIds && !userIds) setUserId(data.getLogoForClientSignup.advisorId);

        setState({
          ...state,
          client: {
            ...data.getLogoForClientSignup,
          },
        });
      },
    }
  );

  useEffect(() => {
    setAccount(null);
    setProfile(null);
    setUserPermission([]);
    setLogoUpdate(null);
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  }, []);

  useEffect(() => {
    if (!practiceId) return;
    if (!userId) return;
    getLogoForClientSignupHandler({ variables: { practiceId: practiceId, advisorId: parseInt(userId) } });
  }, [userId, practiceId]);

  useEffect(() => {
    if (userIds) {
      if (!isNaN(userIds)) {
        setUserId(userIds);
      } else {
        let userRes = decrypt(userIds);
        userRes = userRes.split("_");
        if (userRes.length == 1) {
          userRes = userRes[0].split("-");
        }
        userRes = userRes[userRes.length - 1];
        setUserId(userRes);
      }
    }
    if (practiceIds) {
      let practiceRes = decrypt(practiceIds);
      if (practiceRes == "") setPracticeId(practiceIds);
      else setPracticeId(practiceRes);
    }
    if (companyName) {
      let cmpyRes = decrypt(companyName);
      setCompanyId(cmpyRes);
    }
    if (companyName && !practiceIds) {
      let practiceRes = decrypt(companyName);
      practiceRes = practiceRes.split("_");
      let userId = practiceRes[practiceRes.length - 1];
      practiceRes = practiceRes[0];
      setPracticeId(practiceRes);
      setUserId(userId);
    }
    if (companyName && practiceIds && !userIds) {
      let practiceRes = decrypt(practiceIds);
      practiceRes = practiceRes.split("_");
      let userId = practiceRes[practiceRes.length - 1];
      practiceRes = practiceRes[0];
      setPracticeId(practiceRes);
      setUserId(userId);
    }
  }, [practiceIds, userIds, companyName]);

  return (
    <>
      {activePage === "signup" && (
        <ContentWrapper>
          <div className={"center"}>
            <Card style={{ marginTop: 100 }}>
              <SignupForm setActivePage={setActivePage} setState={setState} state={state} {...props} practiceId={practiceId} loading={loading} setClientData={setClientData} />
            </Card>
          </div>
        </ContentWrapper>
      )}

      {activePage !== "signup" && activePage !== "assessment" && (
        <ContentWrapper>
          <div className={"center-md"}>
            <Card bordered={false} style={{ marginTop: 100 }}>
              <Col span={24}>
                <div className="d-flex align-items-center justify-content-center">
                  {state && state.client && state.client.companylogo ? <img src={state.client.companylogo} style={{ width: "100px", height: "100px", objectFit: "contain" }} /> : <img src={logo} />}
                  {/* <img src={logo} /> */}
                </div>
                <Divider />
              </Col>
              {activePage === "fullSignup" && <FullSignupForm practiceId={practiceId} userId={userId} pages={pages} setPages={setPages} setActivePage={setActivePage} state={state} setState={setState} clientData={clientData} />}
              {activePage === "done" && <Done practiceId={practiceId} userId={userId} pages={pages} setPages={setPages} setActivePage={setActivePage} userInviteDomain={userInviteDomain} state={state} setState={setState} />}
            </Card>
          </div>
        </ContentWrapper>
      )}

      {activePage !== "signup" && activePage === "assessment" && (
        <ContentWrapper>
          <div className={"center-lg"}>
            <Card style={{ marginTop: 20 }}>
              <Col span={24}>
                <div className={"logo-signup"}>
                  {state && state.client && state.client.companylogo ? <img src={state.client.companylogo} style={{ width: "100px", height: "100px", objectFit: "contain" }} /> : <img src={logo} style={{ height: "46px" }} />}
                </div>
              </Col>
              <Assessment practiceId={practiceId} userId={userId} pages={pages} setuserInviteDomain={setuserInviteDomain} setPages={setPages} setActivePage={setActivePage} state={state} setState={setState} clientData={clientData} />
            </Card>
          </div>
        </ContentWrapper>
      )}
    </>
  );
}
