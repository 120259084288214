import React from "react";
import { parsePhoneNumberFromString, AsYouType, isValidPhoneNumber } from "libphonenumber-js";
import { Input } from "antd";

export const formatNumber = (code, number) => {
  const parsedNumber = parsePhoneNumberFromString(`${code} ${number}`);
  if (parsedNumber) {
    const countryCode = parsedNumber.country;
    const formatter = new AsYouType(countryCode).input(`${number}`);
    return `${code} ${formatter}`;
  }
  return "";
};

export const extractPhone = (phone) => phone.replace(/\D/g, "");

export const PhoneInput = ({ countryCode, value, onChange }) => {
  const handleChange = (event) => {
    const input = event.target.value;
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      const digitsOnly = input.replace(/\D/g, "");
      const isValid = isValidPhoneNumber(digitsOnly, countryCode);
      if (!isValid && input.length < 6) onChange(digitsOnly);
      else onChange(new AsYouType(countryCode).input(digitsOnly));
    } else {
      const formatter = new AsYouType(countryCode);
      const formatted = formatter.input(input);
      onChange(formatted);
    }
  };

  return <Input type="text" value={value} onChange={handleChange} placeholder="" className="phoneInputbox" size={"large"} />;
};

export const countriesSort = (countries, inputValue = "") => {
  if (inputValue) {
    const sortedOptions = [...countries].sort((a, b) => {
      const aStartsWithInput = a.id.startsWith(inputValue);
      const bStartsWithInput = b.id.startsWith(inputValue);

      if (aStartsWithInput && bStartsWithInput) {
        if (a.id.length !== b.id.length) {
          return a.id.length - b.id.length;
        }
        return a.id.localeCompare(b.id);
      }

      if (aStartsWithInput) return -1;
      if (bStartsWithInput) return 1;

      return a.id.localeCompare(b.id);
    });
    return sortedOptions;
  }
  countries.sort((a, b) => a.id - b.id);
  return countries;
};
