import { Form, Input, Select, AutoComplete, Icon, Result, Typography, message, notification, Modal as ModalD } from "antd";
import gql from "graphql-tag";
import { Button, Row, Col } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs, OverlayTrigger, Popover, Modal } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Titlebar } from "../../components";
import CustomInput from "../../components/customInput";
import { EditButtons, FormWrapper } from "../../components/form";
import { graphql, useApolloClient, useLazyQuery, useMutation, useQuery } from "react-apollo";
import LayoutContentWrapper from "../../layouts/app/layoutWrapper.style";
import LayoutContent from "../../layouts/app/layoutContent";
import StateList from "../../helpers/statesDataList";
import { Divider } from "rc-menu";
import TextArea from "antd/lib/input/TextArea";
import _, { flowRight as compose } from "lodash";
import UserViewWrapper from "../users/view/index.style";
import Info from "../../assets/img/info-blue.svg";
import UpdatePassword from "../../components/updatePassword";
import ChangeEmail from "../../components/changeEmail";
import ChangePhoneNumber from "../../components/changePhoneNumber";
import StripeCard from "../../components/StripeCard";
import markIcon from "../../assets/img/right-mark-icon.svg";
import SearchOrganization from "../../components/searchOrganization";
import moment from "moment";
import { AccountContext } from "../../context/AccountContext";
import { convertCurrency } from "../../helpers/formatters";
import { formatNumber } from "../../utils/formatter";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const FormItem = Form.Item;
const InputGroup = Input.Group;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;
const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />;
const { Title, Paragraph } = Typography;
const { confirm } = ModalD;

const AccountForm = (props) => {
  const {
    form,
    currentPortalId,
    currentPractice,
    organizationJoinRequestByUser,
    deleteJoinRequestByUser,
    user: { currentLogin, loading, refetch },
    update,
  } = props;

  const { getFieldProps, getFieldDecorator } = form;
  const [isPasswordShow, setPasswordShow] = useState(false);
  const [isChangeEmail, setChangeEmailShow] = useState(false);
  const [isChangePhone, setChangePhoneShow] = useState(false);
  const [isCard, setCardShow] = useState(false);
  const [isSalesforce, setsalesforce] = useState(false);
  const [companyModal, setCompanyModal] = useState({ leave: false, join: false, organization: false, variables: null });
  const [loadingRequest, setLoading] = useState(false);
  const [defaultPage, setDefaultPage] = useState(null);

  const requestJoinHandler = async () => {
    setLoading(true);
    let result = await organizationJoinRequestByUser({ variables: companyModal.variables });
    if (result && result.data && result.data.organizationJoinRequestByUser && result.data.organizationJoinRequestByUser.ok) {
      notification.success({ message: result.data.organizationJoinRequestByUser.message });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      notification.error({ message: result.data.organizationJoinRequestByUser.error });
    }
    setLoading(false);
    setCompanyModal({ leave: false, join: false, organization: false, variables: null });
  };

  const companyEditHandler = () => {
    if (!currentPortalId && !currentPractice && !currentLogin.isRequestChangeCompany) setCompanyModal({ ...companyModal, organization: true });
    else setCompanyModal({ ...companyModal, leave: true });
  };

  const companyDeleteHandler = () => {
    confirm({
      title: "Do you want to delete request?",
      //content: "Can't Undo",
      okText: "Yes",
      okType: "primary",
      //cancelText: "Cancel",
      icon: "close-circle",

      onOk() {
        return new Promise(async (resolve, reject) => {
          let data = await deleteHandlerSubmit();
          resolve();
        }).catch(() => console.log("error"));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteHandlerSubmit = async () => {
    let result = await deleteJoinRequestByUser();
    if (result && result.data && result.data.deleteJoinRequestByUser && result.data.deleteJoinRequestByUser.ok) {
      notification.success({ message: result.data.deleteJoinRequestByUser.message });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      notification.error({ message: result.data.deleteJoinRequestByUser.message });
    }
  };

  const homePageHandler = async (event) => {
    setDefaultPage(event);
    let result = await update({ variables: { defaultLandingPage: event } });
    if (result && result.data && result.data.editAccount.ok) {
    } else notification.error({ message: "Default home page not changed" });
  };

  return (
    <FormWrapper className="unitifi-my-account-layout-wrapper">
      <UserViewWrapper>
        <div className="row-wrapper">
          <div className="row-label">Company / Practice Affiliation</div>
          <div className="text-grey">{currentLogin && currentLogin.companyDetail ? currentLogin.companyDetail.name : ""}</div>
          {currentLogin && currentLogin.accountType == 2 && (
            <div className="edit-btns-box">{currentLogin.isRequestChangeCompany ? <Button type={"link"} icon={"delete"} onClick={() => companyDeleteHandler()} /> : <Button type={"link"} icon={"edit"} onClick={() => companyEditHandler()} />}</div>
          )}
        </div>
        {currentLogin && currentLogin.customerStripeId && (
          <div className="row-wrapper">
            <div className="row-label">Update Billing</div>
            <div className="row-text-value">**** **** **** {currentLogin.cardDetail ? currentLogin.cardDetail.last4_digit : ""}</div>
            <div className="edit-btns-box">
              <Button
                type={"link"}
                icon={"edit"}
                onClick={() => {
                  setCardShow(true);
                }}
              />
            </div>
          </div>
        )}
        {/* <div className="row-wrapper">
          <div className="row-label">Change Password</div>
          <div className="text-grey">****</div>
          <div className="edit-btns-box">
            <Button
              type={"link"}
              icon={"edit"}
              onClick={() => {
                setPasswordShow(true);
              }}
            />
          </div>
        </div> */}
        <div className="row-wrapper">
          <div className="row-label">Update Email</div>
          <div className="row-text-value">{currentLogin && currentLogin.email ? currentLogin.email : null}</div>
          <div className="edit-btns-box">
            <Button
              type={"link"}
              icon={"edit"}
              onClick={() => {
                setChangeEmailShow(true);
              }}
            />
          </div>
        </div>
        <div className="row-wrapper">
          <div className="row-label">Update Phone Number</div>
          <div className="row-text-value">{currentLogin && currentLogin.phoneNumber && currentLogin.phone_code ? formatNumber(currentLogin.phone_code, currentLogin.phoneNumber) : ""}</div>
          <div className="edit-btns-box">
            <Button
              type={"link"}
              icon={"edit"}
              onClick={() => {
                setChangePhoneShow(true);
              }}
            />
          </div>
        </div>
        <div className="row-wrapper">
          <div className="row-label">Default Home Page</div>
          <div className="page-select-main-wrapper">
            <Select placeholder="Default Home Page" style={{ width: 314 }} bordered={false} onChange={homePageHandler} value={defaultPage ? defaultPage : currentLogin && currentLogin.defaultLandingPage} dropdownClassName="default-page-option">
              <Option value="news-center" key={3}>
                Overview
              </Option>
              <Option value="overview" key={2}>
                Dashboard
              </Option>
              <Option value="advisors" key={1}>
                Advisors
              </Option>
              <Option value="client" key={4}>
                Clients
              </Option>
              <Option value="settings/account" key={5}>
                Profile
              </Option>
            </Select>
          </div>
        </div>

        <div className="row-wrapper">
          <div className="row-label">Light/Dark Mode</div>
          <div className="text-grey">Coming soon!</div>
        </div>

        {/* <div className="row-wrapper">
          <div className="row-label">Light/Dark Mode</div>
          <div>{currentLogin.firstName ? currentLogin.firstName : null}</div>
        </div> */}
      </UserViewWrapper>
      <Modal show={isPasswordShow} onHide={() => setPasswordShow(false)} centered className="unitifi-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdatePassword setPasswordShow={setPasswordShow} />
        </Modal.Body>
      </Modal>

      <Modal show={isChangeEmail} onHide={() => setChangeEmailShow(false)} centered className="unitifi-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangeEmail setChangeEmailShow={setChangeEmailShow} currentLogin={currentLogin} />
        </Modal.Body>
      </Modal>

      <Modal show={isChangePhone} onHide={() => setChangePhoneShow(false)} centered className="unitifi-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangePhoneNumber setChangePhoneShow={setChangePhoneShow} currentLogin={currentLogin} refetch={refetch} />
        </Modal.Body>
      </Modal>

      <Modal show={isCard} onHide={() => setCardShow(false)} centered className="unitifi-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Elements stripe={stripePromise}>
            <StripeCard setCardShow={setCardShow} currentLogin={currentLogin} refetch={refetch} />
          </Elements>
        </Modal.Body>
      </Modal>

      <Modal show={companyModal.leave} onHide={() => setCompanyModal({ leave: false, organization: false, join: false, variables: null })} centered className="unitifi-modal-wrapper company-modal">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentLogin && currentLogin.isRequestChangeCompany ? (
            <h3>You have already requested to join company/practice </h3>
          ) : (
            <>
              {currentLogin && currentLogin.companyDetail && <h4 class="cmn-modal-text">Are you sure you want to leave {currentLogin.companyDetail.name} Organization?</h4>}
              <div className="modal-footer-bnts-wrapper btn-center-wrapper">
                <Button type="primary" size="large" htmlType="submit" className="unitifi-login-btn me-2" onClick={() => setCompanyModal({ ...companyModal, leave: false, organization: true, join: false })}>
                  Confirm
                </Button>
                <Button type="" size="large" htmlType="button" className="unitifi-login-btn" onClick={() => setCompanyModal({ leave: false, organization: false, join: false, variables: null })}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={companyModal.organization} onHide={() => setCompanyModal({ leave: false, organization: false, join: false, variables: null })} centered className="unitifi-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchOrganization setCompanyModal={setCompanyModal} companyModal={companyModal} />
        </Modal.Body>
      </Modal>

      <Modal show={companyModal.join} onHide={() => setCompanyModal({ leave: false, organization: false, join: false, variables: null })} centered className="unitifi-modal-wrapper company-modal">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {companyModal.variables && <h4 class="cmn-modal-text">Request to join {companyModal.variables.portalName}?</h4>}
          <div className="modal-footer-bnts-wrapper btn-center-wrapper">
            <Button type="primary" size="large" htmlType="submit" className="unitifi-login-btn me-2" onClick={() => requestJoinHandler()} loading={loadingRequest} disabled={loadingRequest}>
              Confirm
            </Button>
            <Button type="" size="large" htmlType="button" className="unitifi-login-btn" onClick={() => setCompanyModal({ leave: false, organization: false, join: false, variables: null })}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </FormWrapper>
  );
};

const AccountPage = (props) => {
  const {
    form,
    history,
    user: { currentLogin, loading },
    me,
    cancelSubscription,
  } = props;

  // const client = useApolloClient();
  // console.log("client", client);
  const { account, setAccount, setProfile, setUserPermission, setLogoUpdate } = useContext(AccountContext);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Team Discounts</Popover.Header>
      <Popover.Body>
        <ul>
          <li>
            <span>25%</span> off for <span>11-50</span> team members
          </li>
          <li>
            <span>30%</span> off for <span>51-100</span> team members
          </li>
          <li>
            <span>40%</span> off for <span>101-250</span> team members
          </li>
          <li>
            <span>60%</span> off for <span>250+</span> team members
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );

  const [isSubModal, setSubscriptionModal] = useState(false);
  const [isSalesforceModal, setSalesforceModal] = useState(false);
  const [subscriptions, setSubscription] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalMsg, setModalMsg] = useState(false);

  const { error: subError, data: subData, refetch } = useQuery(
    gql`
      query {
        subscription: currentSubscription {
          id
          name
          stripe_id
          stripe_status
          stripe_price
          subscriptionEndDate
          endsAt
          discountType
          discountAmount
          discountTotalAmount
          totalPayAmount
          promocode
          subscriptionInterval
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  const [salesforceConnectApi, { loading: salesforceLoading, data: salesforceData, error: salesforceError }] = useLazyQuery(
    gql`
      query {
        salesforceConnect {
          ok
          url
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data && data.salesforceConnect.ok) window.location = data.salesforceConnect.url;
      },
    }
  );

  useEffect(() => {
    if (!subData) return;
    if (modalMsg) return;
    let sub = subData.subscription;
    setSubscription(sub);
  }, [subData, modalMsg]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [me]);

  // useEffect(async () => {
  //   getSubscription();
  // }, []);

  const cancelSubscriptionHandler = async () => {
    setModalLoading(true);

    let type = subscriptions && subscriptions.endsAt == null ? "cancel" : "active";
    let variables = { type, subscriptionId: subscriptions.stripe_id };
    let result = null;
    result = await cancelSubscription({
      variables: variables,
    });
    // let result = await client.mutate({
    //   mutation: gql`
    //     mutation($subscriptionId: String, $type: String) {
    //       cancelSubscription(subscriptionId: $subscriptionId, type: $type) {
    //         ok
    //         message
    //       }
    //     }
    //   `,
    //   variables: variables,
    // });

    if (result && result.data.cancelSubscription.ok) {
      refetch();
      setModalMsg(true);
    } else {
      notification.error({ message: result.data.cancelSubscription.message });
      setSubscriptionModal(false);
    }
    setModalLoading(false);
  };

  const CancelModalText = () => {
    if (subscriptions && subscriptions.endsAt == null) {
      if (modalMsg) {
        return (
          <>
            <div className="unitifi-subscription-modal-wrapper">
              <div className="mark-icon-div">
                <img src={markIcon} />
              </div>
              <h5>Subscription Canceled</h5>
              <p>You can re-activate your subscription in “My Accounts”</p>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="unitifi-subscription-modal-wrapper cancelSubscribeModal">
            <h5>Are you sure you would like to cancel your subscription at the end of billing cycle?</h5>
          </div>
          <div className="modal-footer-bnts-wrapper btn-center-wrapper">
            <Button type="primary" className="ant-btn unitifi-login-btn ant-btn-primary ant-btn-lg" disabled={modalLoading} loading={modalLoading} onClick={() => cancelSubscriptionHandler()}>
              Confirm
            </Button>
          </div>
        </>
      );
    }
    if (subscriptions && subscriptions.endsAt != null) {
      if (modalMsg) {
        return (
          <>
            <div className="unitifi-subscription-modal-wrapper">
              <div className="mark-icon-div">
                <img src={markIcon} />
              </div>
              <h5>Subscription Re-Activated</h5>
              <p>You can cancel your subscription at any time in “My Account”.</p>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="unitifi-subscription-modal-wrapper">
            <h5>Are you sure you would like to re-activate your subscription?</h5>
            <p className="activated-text">Once activated, you will be billed on the "insert day" of each month. This action can be canceled at any time.</p>
          </div>
          <div className="modal-footer-bnts-wrapper btn-center-wrapper">
            <Button type="primary" className="ant-btn unitifi-login-btn ant-btn-primary ant-btn-lg" disabled={modalLoading} loading={modalLoading} onClick={() => cancelSubscriptionHandler()}>
              Confirm
            </Button>
          </div>
        </>
      );
    }
  };

  const cancelModalHandler = () => {
    setSubscriptionModal(false);
    setModalMsg(false);
    setSubscription(subData.subscription);
  };

  const deleteHandler = () => {
    confirm({
      title: "Are you sure you want to delete this account?",
      content: "Once deleted you will automatically be signed out of this account and redirected to the login screen.",
      okText: "Confirm",
      okType: "primary",
      //cancelText: "Cancel",
      icon: "fa fa-close",

      onOk() {
        return new Promise(async (resolve, reject) => {
          if (me && me.practiceid == null) await accountDeleteHandler();
          else await deleteHandlerSubmit();

          resolve();
        }).catch(() => console.log("error"));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const [deleteUser, { data: contactData, loading: loadingSubmit, error: errorSubmit }] = useMutation(gql`
    mutation deleteUser($id: Int!, $practiceId: Int!) {
      deleteUser(id: $id, practiceId: $practiceId) {
        ok
        message
        errors {
          message
          path
        }
      }
    }
  `);

  const [accountDelete, {}] = useMutation(gql`
    mutation accountDelete($id: Int!) {
      accountDelete(id: $id) {
        ok
        message
      }
    }
  `);

  const deleteHandlerSubmit = async () => {
    let result = await deleteUser({ variables: { id: currentLogin.id, practiceId: me.practiceid } });

    if (result && result.data && result.data.deleteUser && result.data.deleteUser.ok) {
      notification.success({
        message: result.data.deleteUser.message,
      });
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("current_company");
      localStorage.removeItem("current_practice");
      localStorage.removeItem("current_practice_id");
      localStorage.removeItem("current_company_id");
      setAccount(null);
      setProfile(null);
      setUserPermission([]);
      setLogoUpdate(null);

      history.push("/login");
    } else {
      notification.error({
        message: result.data.deleteUser.message,
      });
    }
    return true;
  };

  const accountDeleteHandler = async () => {
    let result = await accountDelete({ variables: { id: currentLogin.id } });

    if (result && result.data && result.data.accountDelete && result.data.accountDelete.ok) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("current_company");
      localStorage.removeItem("current_practice");
      localStorage.removeItem("current_practice_id");
      localStorage.removeItem("current_company_id");
      setAccount(null);
      setProfile(null);
      setUserPermission([]);
      setLogoUpdate(null);
      history.push("/login");
    } else {
      notification.error({
        message: "User not deleted",
      });
    }
    return true;
  };

  const connectWealthboxHandler = () => {
    let url = `https://app.crmworkspace.com/oauth/authorize?client_id=${process.env.REACT_APP_WEALTHBOX_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_URL}wealthbox-authorization&response_type=code&scope=login+data`;
    window.location = url;
  };

  const connectSalesforceHandler = async () => {
    salesforceConnectApi();
  };

  return (
    <div className="unirifi-costomer-layout-wrapper">
      <div className="unitifi-subheader-bar-wrapper">
        <Titlebar displayText="My Account" />
      </div>
      <div className="unitifi-customer-content-wrapper">
        {currentLogin && currentLogin.accountType == 1 && (
          <Row className="unitifi-customer-info-box-wrapper mb-3 mb-lg-0 d-flex align-items-center flex-column flex-md-row">
            <Col className="gutter-row" md={6}>
              <div className="unitifi-customer-info-block">
                <h6>Member Since</h6>
                <p className="mb-0">{currentLogin && moment(currentLogin.createdAt).format("MM/DD/YYYY")}</p>
              </div>
            </Col>
            <Col className="gutter-row" md={6}>
              <div className="unitifi-customer-info-block">
                <h6>
                  Active Members{" "}
                  <OverlayTrigger trigger="hover" rootClose placement="bottom" overlay={popover}>
                    <i>
                      <img src={Info} />
                    </i>
                  </OverlayTrigger>
                </h6>
                <p className="mb-0">
                  {currentLogin.totalUserAdded ? currentLogin.totalUserAdded : 0}/{currentLogin.totalUserAdded > currentLogin.totalUser ? currentLogin.totalUserAdded : currentLogin.totalUser}
                </p>
              </div>
            </Col>
            <Col className="gutter-row" md={6}>
              <div className="unitifi-customer-info-block">
                <h6>Plan</h6>
                {/* <p className="mb-0">{subscriptions && `${subscriptions.subscriptionInterval == "month" ? "Monthly" : "Annually"}`}</p> */}

                <p className="mb-0">{subscriptions && `$${convertCurrency(subscriptions.totalPayAmount)}/${subscriptions.subscriptionInterval == "month" ? "Month" : "Annual"} `}</p>
                {subscriptions && subscriptions.discountType && (
                  <p className="mb-0 text-sm">{subscriptions.discountType ? `(${subscriptions.discountType == "percentage" ? `${subscriptions.discountAmount}%` : `$${convertCurrency(subscriptions.discountAmount)}`} discount applied)` : ""}</p>
                )}
              </div>
            </Col>
            <Col className="gutter-row" md={6}>
              <div className="unitifi-customer-info-block">
                <h6>Next Payment</h6>
                <p className="mb-0">{subscriptions && subscriptions.subscriptionEndDate ? moment(subscriptions.subscriptionEndDate).format("MM/DD/YYYY") : ""}</p>
              </div>
            </Col>
          </Row>
        )}

        {currentLogin && currentLogin.accountType == 2 && (
          <Row className="unitifi-customer-info-box-wrapper">
            <Col className="gutter-row unitifi-customer-info-col" md={8}>
              <div className="unitifi-customer-info-block">
                <h6>Member Since</h6>
                <p className="mb-0">{currentLogin && moment(currentLogin.createdAt).format("MM/DD/YYYY")}</p>
              </div>
            </Col>
            <Col className="gutter-row unitifi-customer-info-col" md={7}>
              <div className="unitifi-customer-info-block">
                <h6>Clients</h6>
                <p className="mb-0">{currentLogin && currentLogin.totalClient}</p>
              </div>
            </Col>
            <Col className="gutter-row unitifi-customer-info-col" md={9}>
              <div className="unitifi-customer-info-block">
                <h6>Assessment Completion Rate</h6>
                <p className="mb-0">{currentLogin && currentLogin.assessmentRatio ? `${currentLogin.assessmentRatio}` : "0%"}</p>
              </div>
            </Col>
          </Row>
        )}

        <div className="d-flex flex-column ms-2">
          {me && (me.rolecode == "CMPYO" || me.rolecode == "PRCTO" || me.rolecode == "ADVSO") && (
            <Button className="btnManageUser" onClick={() => history.push("/settings/manage-user")}>
              Manage Users
            </Button>
          )}
          {currentLogin && me && currentLogin.accountType == 2 && (
            <Button className="btnDelete mt-3" onClick={() => deleteHandler()}>
              Delete Account
            </Button>
          )}
          {subData && subData.subscription && (
            <Button type="primary" className="mt-3" onClick={() => setSubscriptionModal(true)}>
              {subData.subscription.endsAt == null ? "Cancel Subscription" : "Activate Subscription"}
            </Button>
          )}
          {currentLogin && me && (
            <Button className={`btnDelete ${currentLogin.isConnectWealthbox ? "btnBorder" : ""} mt-3`} onClick={() => (currentLogin && currentLogin.isConnectWealthbox ? "" : connectWealthboxHandler())}>
              {currentLogin && currentLogin.isConnectWealthbox ? "Connected" : "Connect"} Wealthbox
            </Button>
          )}
          {currentLogin && me && (
            <Button className={`btnDelete ${currentLogin.isConnectSalesforce ? "btnBorder" : ""} mt-3`} onClick={() => (currentLogin && currentLogin.isConnectSalesforce ? "" : setSalesforceModal(true))}>
              {currentLogin && currentLogin.isConnectSalesforce ? "Connected" : "Connect"} Salesforce
            </Button>
          )}
        </div>
      </div>
      <Modal show={isSubModal} onHide={() => cancelModalHandler()} centered className="unitifi-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
        </Modal.Header>
        <Modal.Body>{CancelModalText()}</Modal.Body>
      </Modal>

      <Modal show={isSalesforceModal} onHide={() => setSalesforceModal(false)} centered className="unitifi-modal-wrapper">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="unitifi-subscription-modal-wrapper">
            <h5 className="salesforce-connect">
              To set up the Unitifi Client package in your Salesforce account, please{" "}
              <a href={process.env.REACT_APP_SALESFORCE_PACKAGE_URL} target="_blank">
                click here
              </a>{" "}
              to initiate the installation process.
            </h5>
            <p>If you have already installed it, there's no need to take this action. Just click on Continue</p>
            {/* <p>Once activated you will be billed on the “insert day” of each month. This action can be cancel at any time.</p> */}
          </div>
          <div className="modal-footer-bnts-wrapper btn-center-wrapper">
            <Button type="primary" className="ant-btn unitifi-login-btn ant-btn-primary ant-btn-lg" loading={salesforceLoading} disabled={salesforceLoading} onClick={() => connectSalesforceHandler()}>
              Continue
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <LayoutContentWrapper>
        <LayoutContent>
          {loading && <Result icon={antIcon} title="Loading ..." />}
          {!loading && <AccountForm {...props} />}
        </LayoutContent>
      </LayoutContentWrapper>
    </div>
  );
  // return
};

const userQuery = gql`
  query {
    currentLogin {
      id
      accountConfirmToken
      accountConfirmTokenExpires
      isRequestChangeCompany
      address
      city
      email
      firstName
      isGlobalAdmin
      isPracticeAdmin
      isPortalAdmin
      lastLogin
      lastName
      phoneNumber
      phone_code
      practice
      practiceId
      role
      roleId
      roleCode
      state
      status
      zipCode
      assessmentNotification
      paid
      customerStripeId
      createdAt
      accountType
      activeUser
      totalUser
      totalUserAdded
      totalClient
      assessmentRatio
      defaultLandingPage
      isConnectWealthbox
      isConnectSalesforce
      companyDetail {
        id
        slug
        name
      }
      cardDetail {
        id
        card_resource_id
        last4_digit
        card_brand
      }
    }
  }
`;
const updateAccount = gql`
  mutation($address: String, $city: String, $email: String, $firstName: String, $lastName: String, $phoneNumber: String, $state: String, $zipCode: String, $subscriptions: String, $defaultLandingPage: String) {
    editAccount(address: $address, city: $city, email: $email, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, state: $state, zipCode: $zipCode, subscriptions: $subscriptions, defaultLandingPage: $defaultLandingPage) {
      ok
    }
  }
`;
const cancelSubscription = gql`
  mutation($subscriptionId: String, $type: String) {
    cancelSubscription(subscriptionId: $subscriptionId, type: $type) {
      ok
      message
    }
  }
`;

const organizationJoinRequestByUser = gql`
  mutation($id: Int, $utype: String) {
    organizationJoinRequestByUser(id: $id, utype: $utype) {
      ok
      error
      message
    }
  }
`;

const deleteJoinRequestByUser = gql`
  mutation($id: Int) {
    deleteJoinRequestByUser(id: $id) {
      ok
      error
      message
    }
  }
`;

export default compose(
  graphql(userQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
    }),
    name: "user",
  }),
  graphql(updateAccount, {
    name: "update",
  }),
  graphql(cancelSubscription, {
    name: "cancelSubscription",
  }),
  graphql(organizationJoinRequestByUser, {
    name: "organizationJoinRequestByUser",
  }),
  graphql(deleteJoinRequestByUser, {
    name: "deleteJoinRequestByUser",
  })
)(Form.create()(AccountPage));
