import styled from "styled-components";
import { palette } from "styled-theme";

const PublicContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  // text-align: center;
  width: 100%;
  &.stretch-content {
    height: 100vh;
    overflow: scroll;
    background: ${palette("base", 0)};
  }
  .ant-spin-spinning {
    width: 100%;
  }
  .loading-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    .ant-spin-dot {
      color: rgba(0,111,186,1);
    }
  }
  .public-footer {
    background: /*rgba(249,250,252,1)*/ rgba(240,242,245,1);
    color: ${palette("text", 2)};
    padding: 0 24px 24px;
    width: 100%;
  }
  .public-logo {
    margin-bottom: 35px;
    text-align: center;
    img {
      height: 38px;
      /* width: 160px; */
    }
  }
  .public-error-message {
    background: #fff1f0;
    border: 1px solid #ffa39e;
    border-radius: 4px;
    padding: 24px;
    .message {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }
  .public-main {
    width: 336px;
    .public-form-wrapper {
      background: rgba(255,255,255,1);
      border: 1px solid rgba(238,242,244,1);
      border-radius: 4px;
      padding: 24px;
      .public-form {
        padding: 0;
      }
      .ant-btn {
        width: 100%;
      }
      .has-error, .has-success {
        .ant-form-explain {
          text-align: left;
        }
      }
      .ant-form-vertical {
        .2fa-number-input-box{
          display:flex;
        }
        .ant-form-item {
          &:last-child {
            margin-bottom: 0;
            padding-bottom: 0px;
          }
          &:not(.show-required) {
            .ant-form-item-label {
              .ant-form-item-required {
                &::after {
                  display: none;
                }
                span{
                  color:#f5222d;
                }
              }
            }
          }
        }
        .UnitifiCustomerNumberInputbox{
          .unitifi-react-select__value-container{
            font-size: 16px;
            .unitifi-react-select__placeholder{
              font-size: 16px;
            }
            .unitifi-react-select__input-container{
              font-size: 16px;
            }
          }
          .input-phone__input{
            input{
              font-size: 16px !important;
            }
          }
        }
      }
      .uni-back {
        display: flex;
        justify-content: flex-start;
        padding-top: 32px;
        i.uni-back-icon {
          font-size: 12px;
          margin-right: 8px;
        }
      }
      .uni-forgot__wrapper {
        display: flex;
        justify-content: flex-start;
        padding-top: 32px;
      }
      .uni-password-label {
        .ant-form-item-label {
          width: 50%;
        }
      }
      .public-message {
        margin-bottom: 32px;
        text-align: left;
      }
      .uni-input-password__input-confirm {
        flex: 0 0 100%;
      }
      .input-password-feedback-wrapper {
        flex: 0 0 100%;
      }
    }
  }
  .public-wrapper {
    align-items: center;
    background: #ffffff;
    display: flex;
    // height: 100%;
    justify-content: center;
    padding: 80px 18px;
    width: 100%;
    .public-form-wrapper{
      .pw-eye-icons{
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        right: 25px;
                        display: flex;
                        align-items: center;
                        z-index: 1;
                        i {
                          cursor: pointer;
                          width: 18px;
                          height: 14px;
                          &.pw-off-view-icon {
                            background: url("/img/eye-off.svg") no-repeat;
                            background-position: center center;
                            //background-size: contain;
                          }
                          &.pw-view-icon {
                            background: url("/img/eye-icon.svg") no-repeat;
                            background-position: center center;
                            background-size: contain;
                          }
                        }
                      }
    }
  }
  &.unitifi-login-wrapper{
    .unitifi-notification-banner-wrapper{
      width:100%;
      border-radius:4px;
      background:#006fba;
      margin-top:20px;
      display:flex;
      align-items:flex-start;
      @media(max-width:991px){
        flex-direction:column;
      }
      .notification-icon-wrapper{
          background:#003f83;
          border-radius:4px 0 0 4px;
          display:flex;
          align-items:center;
          justify-content:center;
          max-width:48px;
          width:100%;
          padding:27px 15px;
          @media(max-width:1199px){
            padding:39px 15px;
          }
          @media(max-width:991px){
            border-radius:4px;
            padding:15px;
          }  
      }
      .notification-data-wrapper{
        padding:12px;
        display:flex;
        align-items:flex-start;
        justify-content:space-between;
        .unitifi-notification-content{
          font-size:16px;
          color:#fff;
          max-width:1190px;
          width:100%;
          margin-right:20px;
          a{
            color:#fff;
            &:hover{
              text-decoration:underline;
            }
          }
        }
      }
    }
    .public-wrapper{
      .public-main{
        width: 550px;
        max-width: 100%;
        .public-logo{
          img{
            height: 65px;
            object-fit: contain;
            object-position: center;
            &.unitifi-login-logo-wrapper{
              margin-right:45px;
            }
          }
        }
        .public-form-wrapper{
          padding: 0px;
          border: none;
          background: transparent;
          border-radius: 0;
          .public-form{
            .unitifi-login-header-bar{
              margin-bottom: 25px;
              .unitifi-back-btn-link{
                display: inline-flex;
                margin-bottom: 25px;
                align-items: center;
                text-decoration: none;
                i{
                  width: 21px;
                  height: 21px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 10px;    
                  svg{
                    width: 15px;
                    height: 15px;
                    color: ${palette("base", 8)};
                  }          
                }
                span{
                  font-weight: 600;
                  font-size: 16px;
                  line-height: normal;
                  color: ${palette("base", 8)};
                }
              }
              h5{
                font-weight: 600;
                font-size: 22px;
                line-height: normal;
                color: ${palette("base", 8)};
                margin-bottom: 40px;
                text-align: center;
                &.unitifi-2fa-heading{
                  margin-bottom:18px;
                }
              }

              .unitifi-code-notification-text{
                display:flex;
                align-items:center;
                justify-content:center;
                font-size:18px;
                margin-bottom:40px;
                @media(max-width:575px){
                  font-size:15px;
                }
              }

              .login-detail-text{
                text-align: start;
                font-weight: 400;
                font-size: 18px;
                line-height: normal;
                color: ${palette("base", 8)};
              }
            }
            form{
              .ant-row{
                padding: 0;
                .ant-form-item{
                  margin-bottom: 18px !important;
                }
                .ant-form-item-label{
                  padding: 0;
                  height: auto;
                  margin: 0;
                  width: 100%;
                  label{
                    font-weight: 400;
                    font-size: 18px;
                    line-height: normal;
                    color: ${palette("base", 8)};
                    margin-bottom: 10px;
                    display: inline-block;
                    &:after {
                      color: ${palette("error", 0)};
                      content: "*";
                      display: inline-block;
                      font-family: SimSun;
                      font-size: 14px;
                      line-height: 1;
                      margin-left: 4px;
                      top: 2px;
                      vertical-align: super;
                    }
                  }
                }
                .ant-form-item-control-wrapper{
                  .ant-form-item-control{
                    .ant-form-item-children{
                      input{
                        font-weight: 400;
                        font-size: 18px;
                        line-height: normal;
                        color: ${palette("base", 9)};
                        padding: 15px;
                        min-height: auto;
                        height: auto;
                        background: ${palette("base", 10)};
                        outline: none;
                        box-shadow: none;
                        border-color: ${palette("base", 10)};
                        appearance:none;
                        &:-internal-autofill-selected {
                          appearance: menulist-button;
                          background-image: none !important;
                          background-color: ${palette("base", 10)} !important;
                          color: fieldtext !important;
                        }
                        &:focus{
                          color: ${palette("base", 9)};
                          border-color: ${palette("primary", 12)};
                          background: ${palette("base", 10)};
                        }
                        &::-webkit-input-placeholder { /* Edge */
                          color: ${palette("base", 9)};
                          background: ${palette("base", 10)};
                        }
                        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                          color: ${palette("base", 9)};
                          background: ${palette("base", 10)};
                        }
                        &::placeholder {
                          color: ${palette("base", 9)};
                          background: ${palette("base", 10)};
                        }
                        &:-webkit-autofill{
                          -webkit-box-shadow: 0 0 0 1000px  ${palette("base", 10)} inset !important;
                          -webkit-text-fill-color:  ${palette("base", 9)} !important;
                        }
                      }
                      .pw-eye-icons{
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        right: 25px;
                        display: flex;
                        align-items: center;
                        z-index: 1;
                        i {
                          cursor: pointer;
                          width: 24px;
                          height: 19px;
                          &.pw-off-view-icon {
                            background: url("/img/eye-off.svg") no-repeat;
                            background-position: center center;
                            //background-size: contain;
                          }
                          &.pw-view-icon {
                            background: url("/img/eye-icon.svg") no-repeat;
                            background-position: center center;
                            background-size: contain;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .uni-forgot__wrapper{
                justify-content: end;
                padding-top: 0;
                margin-bottom: 40px;
                .link-text{
                  font-weight: 400;
                  font-size: 16px;
                  line-height: normal;
                  color: ${palette("base", 11)};
                  text-decoration: underline;
                }
              }
              .unitifi-footer-wrapper{
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                button{
                  padding: 12px;
                  height: auto;
                  font-size: 16px;
                  font-weight: 600;
                  width: 48%;
                  &.unitifi-login-btn-border{
                    background: ${palette("base", 0)};
                    color: ${palette("primary", 12)};
                  }
                }
                &.unitifi-resetpw-btn-wrapper{
                  button{
                    width: 100%;
                  }
                }
              }
            }
            .unitifi-copy-link-text{
              padding: 0px;
              display: flex;
              margin-top: 30px;
              font-weight: 500;
              font-size: 16px;
              line-height: normal;
              color: ${palette("base", 8)};
              span{
                display: inline-block;
                font-weight: 500;
                font-size: 16px;
                line-height: normal;
                color: ${palette("base", 8)};
                &.ant-typography{
                  text-decoration: underline;
                  font-weight: 600;
                }
              }
              .link-text{
                font-weight: 600;
                font-size: 16px;
                line-height: normal;
                color: ${palette("base", 8)};
                text-decoration: underline;
                display: inline-block;
                margin-left: 8px;
              }
              .ant-form-item-children-icon{
                right: -20px;
                top:10px;
              }
            }
          }
        }
      }
    }
  }
  &.unitifi-signup-wrapper{
    .public-wrapper{
      .public-main{
        width: 900px;
        .public-form-wrapper{
          width: 100%;
          .public-form{
            max-width: 100%;
            form{
              .code-number-input-box{
                display:flex;
                align-items:center;
                justify-content:space-between;
                max-width:510px;
                width:100%;
                margin:auto;
                .input-box-wrapper{
                  position:relative;
                  &::after{
                    position:absolute;
                    content:'-';
                    top:38px;
                    left:-30px;
                    bottom:0;
                    width:5px;
                    height:2px;
                    font-size:30px;
                    color:#b0b0b0;
                    margin:0 10px;
                    @media(max-width:575px){
                      left:-40px;
                    }
                    @media(max-width:499px){
                      left:-36px;
                    }
                    @media(max-width:480px){
                      left:-42px;
                      margin:0 15px;
                    }
                    @media(max-width:430px){
                      left:-40px;
                    }
                    @media(max-width:400px){
                      left:-38px;
                    }
                  }
                  &:first-child{
                    &::after{
                      content:unset;
                    }
                  }
                  input{
                    &.unitifi-code-input-box{
                      &.error-code-text{
                        color:red;
                      }
                    }
                    font-size:50px;
                    max-width:105px;
                    width:100%;
                    height:120px;
                    border-radius:4px;
                    text-align:center;
                    @media(max-width:575px){
                      max-width:90px;
                    }
                    @media(max-width:530px){
                      max-width:80px;
                    }
                    @media(max-width:480px){
                      max-width:65px;
                    }
                    @media(max-width:400px){
                      max-width:60px;
                    }
                    @media(max-width:380px){
                      max-width:55px;
                    }
                  }
                }
              }
              .code-footer-text{
                max-width:530px;
                width:100%
                margin:auto;
                display:flex;
                justify-content:flex-end;
                margin-top:30px;
                margin-bottom:40px;
                p{
                  font-size:16px;
                  color:#181818;
                  @media(max-width:450px){
                    font-size:13px;
                  }
                  &.code-success-text-wrapper{
                    margin:auto;
                  }
                  span{
                    color:#006fba;
                    font-weight:600;
                    text-decoration:underline;
                    cursor:pointer;
                  }
                }
              }

              .unitifi-footer-wrapper{
                &.code-footer-btn-wrapper{
                  max-width:530px;
                  width:100%
                  margin:auto;
                }
              }

              .unitifi-footer{
                margin: 0 auto;
                max-width: 680px;
                width: 100%;
                .unitifi-footer-wrapper{
                  margin-top: 25px;
                }
              }
              .unitifi-number-inputbox-wrapper{
                display: flex;
                align-items: center;
                .unitifi-select-container{
                  .unitifi-select-container{
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    z-index: 2;
                  }
                  input{
                    padding-left: 95px;
                  }
                  .unitifi-react-select__control{
                    min-height: 55px;
                    height: 55px;
                    width: 105px;
                    border-radius: 4px 0px 0px 4px;
                    background: #F4F4F4;
                    border: none !important;
                    .unitifi-react-select__value-container{
                      font-weight: 400;
                      font-size: 16px;
                      line-height: normal;
                      padding: 0;
                      height: 55px;
                      margin-top: -11px;
                    }
                    .unitifi-react-select__indicators{
                      height: 35px;
                    }
                  }
                }
                }
                .number-inputbox{
                  flex: 1;
                  display: flex;
                  margin-right: 2px;
                  margin-left: 14px;
                  &.phoneInputData{
                    display:unset;
                  }
                  input{
                    .ant-input{
                      &.2fa-input-box{
                        max-width:105px;
                        width:100%;
                        height:120px;
                        border-radius:4px;
                      }
                    }
                      ::placeholder {
                          color: rgba(78,78,78,0.7) !important;
                      }
                      color: rgba(78,78,78,0.7) !important;
                      &::before{
                        position:absolute;
                        content:'-';
                        top:0;
                        left:0;
                        bottom:0;
                      }
                  }
                }
                .2fa-number-input-box{
                  display:flex;
                }
                .input-phone__input{
                  flex: 1;
                  display: flex;
                  margin-right: -7px;
                  margin-left: 7px;
                  input{
                    width: 30.32%;
                    margin: 0 7px;
                  }
                }
              }
              .ant-row{
                .ant-col{
                  .ant-row{
                    &.input-phone__wrapper{
                      .ant-form-item-label{
                        label{
                          &::after{
                            content:unset;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .unitifi-number-inputbox-wrapper{
                .unitifi-react-select__value-container{
                  font-size: 18px;
                .unitifi-react-select__single-value{
                  font-size: 18px;
                }
                .unitifi-react-select__placeholder{
                  font-size: 18px;
                }
                .unitifi-react-select__input-container{
                  font-size: 18px;
                }
              }
              }
            }
            .unitifi-copy-link-text{
              margin: 0 auto;
              max-width: 680px;
              width: 100%;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
  .search-organization-layout-wrapper{
    padding: 50px 18px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .public-form{
      max-width: 700px;
      width: 100%;
      .search-organization-header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 21px;
        .unitifi-logo{
          width: 180px;
          display: inline-flex;
          height: auto;
          overflow: hidden;
          margin-bottom: 12px;
          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
        h5{
          font-size: 24px;
          font-weight: 600;
          text-align: center;
          color: ${palette("base", 8)};
          line-height: normal;
        }
      }
      .ant-form-item-label{
        line-height: normal;
        label{
          margin-bottom: 12px;
        }
      }
      .organization-error-remove-wrapper{
        font-size: 14px;
        font-weight: 600;
        width: calc(100% - 40px);
        position: relative;
        color: #181818;
        span{
          color: ${palette("primary", 12)};
          width: 30px;
          height: 30px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 18px;
          font-weight: 600;
          position: absolute;
          right: -40px;
        }
      }
      .organization-wrapper{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 130px;
        margin-bottom:40px;
        a{
          font-size: 16px;
          font-weight: 400;
          color: ${palette("primary", 12)};
          display: inline-block;
          text-decoration: underline !important;
        }
        .unitifi-login-btn{
          min-width: 204px;
          width: auto;
          margin-left: 15px;
        }
      }
      .organization-info-text{
        max-width:652px;
        width:100%;
        font-size:14px;
        line-height:29px;
        color:#657A85;
      }
    }
  }
  .2fa-number-input-box{
    display:flex;
  }
`;

export default PublicContentWrapper;
