import React, { useEffect, useState } from "react";
import { CustomPicker } from "react-color";
import Chrome from "@uiw/react-color-chrome";
import { hsvaToHex, hexToHsva } from "@uiw/color-convert";

const CustomColorPicker = ({ hex, hsl, hsv, onChange }) => {
  const [hsva, setHsva] = useState(hexToHsva(hex == "#" ? "000000" : hex));
  const hex1 = hsvaToHex(hsva);

  useEffect(() => {
    onChange({ hex: hex1 });
  }, [hex1]);

  return (
    <Chrome
      color={hsva}
      showAlpha={false}
      onChange={(color) => {
        setHsva(color.hsva);
      }}
    />
  );
};

export default CustomColorPicker;
